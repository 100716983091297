import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../Button';

const CloseTabsButton = ({ onClick }) => {
    const { closeTabsButton, angleUpIcon } = require('./index.scss');

    return (
        <Button
            className={closeTabsButton}
            onClick={onClick}
            qaTag="templateAdmin-closeTabsAccordionButton"
        >
            Close Tabs
            <i aria-hidden="true" className={`fa fa-angle-up fa-2x ${angleUpIcon}`} />
        </Button>
    );
};

CloseTabsButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default CloseTabsButton;
