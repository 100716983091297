import React from 'react';

import ApiClient from '../api/apiClientRequestWrapper';

const client = new ApiClient();

export const useSearchService = (searchThroughAPI = false, governmentId = null) => {
    const [categoriesLoading, setCategoriesLoading] = React.useState(0);
    const [vendorsLoading, setVendorsLoading] = React.useState(0);

    return {
        categories: {
            loading: categoriesLoading > 0,
            search: async (data) => {
                setCategoriesLoading((prev) => prev + 1);

                try {
                    const response = await client.post('/search/categories', { data });

                    return response;
                } finally {
                    setCategoriesLoading((prev) => prev - 1);
                }
            },
        },
        vendors: {
            loading: vendorsLoading > 0,
            search: async (data) => {
                setVendorsLoading((prev) => prev + 1);

                try {
                    const url =
                        searchThroughAPI && governmentId
                            ? `/government/${governmentId}/vendor-search/search/os`
                            : '/search/vendors';
                    const response = await client.post(url, { data });

                    return response;
                } finally {
                    setVendorsLoading((prev) => prev - 1);
                }
            },
        },
    };
};
