import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Chip, Tabs, Tab } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { qaTagPageName } from '../../constants';
import { getAllRequisitionLogs } from '../../selectors';
import { hasRequisitionOnlySubscription } from '../../../../GovApp/selectors';
import { getReqRelations } from '../../../../../selectors/govApp/reqRelations';
import { actionTypes } from '../../../../../../../shared_config/requisitionLogs';

const Counter = ({ count }) => <Chip color="primary" label={count} size="extraSmall" />;

Counter.propTypes = {
    count: PropTypes.number.isRequired,
};

export const RequisitionsApprovalHistoryNav = ({ navIndex, setNavIndex }) => {
    const hasRequisitionOnly = useSelector(hasRequisitionOnlySubscription);

    const commentCount = useSelector(getAllRequisitionLogs).filter(
        ({ actionType }) => actionType === actionTypes.COMMENT
    ).length;
    const relatedCount = useSelector(getReqRelations).length;

    return (
        <Tabs
            onChange={(e, value) => setNavIndex(value)}
            sx={{
                borderBottom: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                '.MuiTab-root:first-child': {
                    marginLeft: 0,
                },
            }}
            value={navIndex}
        >
            <Tab label="Workflow" qaTag={`${qaTagPageName}-workflowNav`} value={0} />
            <Tab
                icon={<Counter count={commentCount} />}
                iconPosition="end"
                label="Comments"
                qaTag={`${qaTagPageName}-commentsNav`}
                value={1}
            />
            {!hasRequisitionOnly && (
                <Tab
                    icon={<Counter count={relatedCount} />}
                    iconPosition="end"
                    label="Related"
                    qaTag={`${qaTagPageName}-relatedNav`}
                    value={2}
                />
            )}
        </Tabs>
    );
};

RequisitionsApprovalHistoryNav.propTypes = {
    navIndex: PropTypes.number.isRequired,
    setNavIndex: PropTypes.func.isRequired,
};
