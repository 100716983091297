import classnames from 'classnames';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { reset } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';

import { createTagForm, form } from './constants';
import { CreateTagForm } from './CreateTagForm';
import { getNavItems, getSelectedTagTypes } from './selectors';
import { TagRowForm } from './TagRowForm';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { createTag, deleteTag, swapTags, updateTag } from '../../../actions/tags';
import { Main, Nav, NavItem, SectionTitle } from '../../../components';

export const Tags = () => {
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    const createError = useSelector((state) => state.tags.get('createTagError')) || undefined;
    const creating = useSelector((state) => state.tags.get('creatingTag')) || false;
    const deleteError = useSelector((state) => state.tags.get('deleteTagError')) || undefined;
    const deleting = useSelector((state) => state.tags.get('deletingTag')) || false;
    const updateError = useSelector((state) => state.tags.get('updateTagError')) || undefined;
    const updating = useSelector((state) => state.tags.get('updatingTag')) || false;
    const navItems = useSelector(getNavItems);

    const selectedNavItem =
        navItems.find((item) => item.type === searchParams.get('type')) || navItems[0];
    const selectedTags = useSelector((state) => getSelectedTagTypes(state, { selectedNavItem }));

    const styles = require('./index.scss');

    const onCreateTag = (tagData) => {
        const { type } = selectedNavItem;

        const onSuccess = () => dispatch(reset(createTagForm));

        dispatch(createTag({ type, ...tagData }, onSuccess));
    };

    return (
        <Main>
            <SectionTitle
                info="Tags for categorizing and organizing your data"
                title="Tags Management"
            />
            {deleteError ? <span className="text-danger">{deleteError}</span> : null}
            {updateError ? <span className="text-danger">{updateError}</span> : null}
            <div className="row">
                <div className="col-md-4 col-lg-3 col-lg-offset-1">
                    <h3 className={styles.tagTypeTitle}>Tag Types</h3>
                    <div className={styles.tagTypeNav}>
                        <Nav bsStyle="pills" stacked>
                            {navItems.map((item) => {
                                const { name, type } = item;
                                return (
                                    <NavItem
                                        className={classnames({
                                            active: type === selectedNavItem.type,
                                        })}
                                        key={type}
                                        qaTag={`admin-${name}`}
                                        to={{
                                            pathname,
                                            search: createSearchParams({ type }).toString(),
                                        }}
                                    >
                                        {name}
                                    </NavItem>
                                );
                            })}
                        </Nav>
                    </div>
                </div>
                <div className="col-md-8 col-lg-7">
                    <h4 className={styles.tagTypeTitle}>{selectedNavItem.name}</h4>
                    <CreateTagForm
                        createError={createError}
                        creating={creating}
                        form={createTagForm}
                        hasDefault={selectedNavItem.hasDefault}
                        onSubmit={onCreateTag}
                    />
                    <ListGroup>
                        {selectedTags.map((tag, index) => {
                            return (
                                <TagRowForm
                                    deleteTag={(id) => dispatch(deleteTag(id))}
                                    disabled={creating || deleting || updating}
                                    form={`${form}:${tag.id}`}
                                    hasDefault={selectedNavItem.hasDefault}
                                    index={index}
                                    initialValues={tag}
                                    isFirstItem={index === 0}
                                    isLastItem={index === selectedTags.length - 1}
                                    key={tag.id}
                                    showConfirmationSimpleModal={(onConfirm, params) =>
                                        dispatch(showConfirmationSimpleModal(onConfirm, params))
                                    }
                                    swapTags={(tagAId, tagBId) =>
                                        dispatch(swapTags(tagAId, tagBId))
                                    }
                                    tag={tag}
                                    tags={selectedTags}
                                    updateTag={(id, data) => dispatch(updateTag(id, data))}
                                />
                            );
                        })}
                    </ListGroup>
                </div>
            </div>
        </Main>
    );
};
