import { collapseCriteriaMap } from '.';
import { sectionTypeNames } from '../../../shared_config/sections';

const { TEXT_AREA } = sectionTypeNames;

export const serializeCriteriaAndSectionDescriptions = (data) => {
    const { criteria: criteriaData, sectionDescriptions: sectionDescriptionsData } = data;

    let criteria;
    let sectionDescriptions;

    if (criteriaData) {
        criteria = collapseCriteriaMap(criteriaData).filter(
            (criterion) =>
                criterion.section_type === TEXT_AREA ||
                !!criterion.title ||
                !!criterion.description ||
                !!criterion.manualNumber
        );
    }

    if (sectionDescriptionsData) {
        sectionDescriptions = collapseCriteriaMap(sectionDescriptionsData).filter(
            (sectionDesc) => !!sectionDesc && !!sectionDesc.description
        );
    }

    return {
        criteria,
        sectionDescriptions,
    };
};
