import { useDispatch, useSelector } from 'react-redux';
import { CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { showProjectCreateModal } from '../../../../actions/project/create/projectCreate';
import { usePathUrl } from './utils';
import { getUserJS } from '../../../../containers/selectors';

export const useUtilityTrayOptions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(getUserJS);

    const hasNewInboxNotification = useSelector((state) =>
        state.inboxNotifications.get('hasNewInboxNotification')
    );

    const {
        government_id: governmentId,
        government: { hasContracting, hasDocBuilder, hasIntake, hasRequisition },
        userPermissions: {
            isRequisitionGroupAdmin,
            isRetentionAdmin,
            isSystemAdmin,
            isTemplateAdmin,
        },
        avatarUrl,
    } = user;

    const { getPathUrl } = usePathUrl(governmentId);

    const createNewActionOptions = [
        {
            label: 'Create',
            handleCreate: () => {
                dispatch(showProjectCreateModal());
            },
        },
    ];

    const customUtilityOptions = {
        utilityName: 'Calendar',
        muiIcon: CalendarMonthIcon,
        iconOnClickOverride: () => {
            navigate(getPathUrl('calendar'));
        },
    };

    const notificationsOptions = {
        showIconBadge: hasNewInboxNotification,
        iconOnClickOverride: () => {
            navigate(getPathUrl('notifications-inbox'));
        },
    };

    const helpOptions = {};

    // build setting menu
    const generalMenuSection = {
        label: 'General',
        menuItems: [],
    };
    const requestMenuSection = {
        label: 'Requests',
        menuItems: [],
    };
    const solicitationsAndContractsMenuSection = {
        label: 'Solicitations & Contracts',
        menuItems: [],
    };

    if (isSystemAdmin) {
        generalMenuSection.menuItems.push({
            label: 'Application Settings',
            onClick: () => {
                navigate(getPathUrl('admin'));
            },
        });
    }

    if (isSystemAdmin && hasRequisition) {
        requestMenuSection.menuItems.push({
            label: 'Workflow/Sequence',
            onClick: () => {
                navigate(getPathUrl('requisitions-admin'));
            },
        });
    }

    if (isRequisitionGroupAdmin && hasRequisition) {
        requestMenuSection.menuItems.push({
            label: 'Review Groups',
            onClick: () => {
                navigate(getPathUrl('requisition-groups'));
            },
        });
    }

    if (isTemplateAdmin && hasDocBuilder) {
        solicitationsAndContractsMenuSection.menuItems.push({
            label: 'Templates',
            onClick: () => {
                navigate(getPathUrl('templates-admin'));
            },
        });
    }

    if (isTemplateAdmin && hasContracting) {
        solicitationsAndContractsMenuSection.menuItems.push({
            label: 'Contract Checklists',
            onClick: () => {
                navigate(getPathUrl('checklists-admin'));
            },
        });
    }

    if (isRetentionAdmin && (hasDocBuilder || hasIntake || hasContracting)) {
        solicitationsAndContractsMenuSection.menuItems.push({
            label: 'Records Retention',
            onClick: () => {
                navigate(getPathUrl('retention-admin'));
            },
        });
    }

    const settingsOptionsMenuSections = [
        generalMenuSection,
        requestMenuSection,
        solicitationsAndContractsMenuSection,
    ];

    // filter out empty menu sections
    const settingsOptions = {
        menuSections: settingsOptionsMenuSections.filter((section) => {
            return section.menuItems.length > 0;
        }),
    };

    // user profile
    const profileSettingsOptions = {
        placeholderInitials: 'OG',
        profileImgUrl: avatarUrl,
        updateProfileOnClick: () => {
            navigate(getPathUrl('account'));
        },
        handleSignOut: () => navigate('/logout'),
    };

    return {
        createNewActionOptions,
        profileSettingsOptions,
        customUtilityOptions,
        helpOptions,
        notificationsOptions,
        settingsOptions,
    };
};
