import { v4 as UUIDv4 } from 'uuid';

import { defaultPriceTableValues } from './constants';
import { parseXLSXCompatibleFileToJSON, prepareAndValidateRowEntry } from '../../../../helpers';
import {
    priceTableFieldNames,
    CUSTOM_1,
    CUSTOM_2,
    CUSTOM_3,
    CUSTOM_4,
    CUSTOM_5,
    DESCRIPTION,
    IS_HEADER_ROW,
    LINE_ITEM,
    QUANTITY,
    TAXABLE,
    UNIT_PRICE,
    UNIT_TO_MEASURE,
} from '../../../../../../shared_config/priceTables';

const {
    HAS_CUSTOM_1,
    HAS_CUSTOM_2,
    HAS_CUSTOM_3,
    HAS_CUSTOM_4,
    HAS_CUSTOM_5,
    HAS_QUANTITY,
    HAS_SALES_TAX_ROW,
    HAS_TOTAL_ROW,
    HEADER_CUSTOM_1,
    HEADER_CUSTOM_2,
    HEADER_CUSTOM_3,
    HEADER_CUSTOM_4,
    HEADER_CUSTOM_5,
    SPECIFY_QUANTITY,
    SPECIFY_UNIT_PRICE,
    TITLE,
} = priceTableFieldNames;

// Case-insensitive lookup for all valid table headers
// Maps column headers to their corresponding pricing table and priceItem properties
const columnHeaderToPricingTableProperties = {
    'line item': {
        itemProperty: LINE_ITEM,
        tableConfigProperty: false,
        allowBlankValues: true,
        maxLength: 64,
        convertToString: true,
    },
    description: {
        itemProperty: DESCRIPTION,
        tableConfigProperty: undefined,
        allowBlankValues: true,
    },
    'unit of measure': {
        itemProperty: UNIT_TO_MEASURE,
        tableConfigProperty: undefined,
        allowBlankValues: true,
        maxLength: 64,
    },
    quantity: {
        itemProperty: QUANTITY,
        tableConfigProperty: HAS_QUANTITY,
        allowBlankValues: true,
        enforceType: 'number',
    },
    taxable: {
        itemProperty: TAXABLE,
        tableConfigProperty: HAS_SALES_TAX_ROW,
        allowBlankValues: true,
        enforceType: 'boolean',
    },
    'unit cost': {
        itemProperty: UNIT_PRICE,
        tableConfigProperty: SPECIFY_UNIT_PRICE,
        allowBlankValues: true,
        enforceType: 'number',
    },
    custom1: {
        itemProperty: CUSTOM_1,
        headerNameField: HEADER_CUSTOM_1,
        headerNameToAddOnExistence: 'Custom1',
        tableConfigProperty: HAS_CUSTOM_1,
        allowBlankValues: true,
        maxLength: 510,
    },
    custom2: {
        itemProperty: CUSTOM_2,
        headerNameField: HEADER_CUSTOM_2,
        headerNameToAddOnExistence: 'Custom2',
        tableConfigProperty: HAS_CUSTOM_2,
        allowBlankValues: true,
        maxLength: 510,
    },
    custom3: {
        itemProperty: CUSTOM_3,
        headerNameField: HEADER_CUSTOM_3,
        headerNameToAddOnExistence: 'Custom3',
        tableConfigProperty: HAS_CUSTOM_3,
        allowBlankValues: true,
        maxLength: 510,
    },
    custom4: {
        itemProperty: CUSTOM_4,
        headerNameField: HEADER_CUSTOM_4,
        headerNameToAddOnExistence: 'Custom4',
        tableConfigProperty: HAS_CUSTOM_4,
        allowBlankValues: true,
        maxLength: 510,
    },
    custom5: {
        itemProperty: CUSTOM_5,
        headerNameField: HEADER_CUSTOM_5,
        headerNameToAddOnExistence: 'Custom5',
        tableConfigProperty: HAS_CUSTOM_5,
        allowBlankValues: true,
        maxLength: 510,
    },
    'section header': {
        itemProperty: IS_HEADER_ROW,
        tableConfigProperty: false,
        allowBlankValues: true,
        enforceType: 'boolean',
    },
};

/**
 * Builds and validates a price table from Excel import data
 * @param {object} priceTableData Data to use to build a price table from Excel import
 * @param {object} options Options to use in building the price table
 * @param {number} [options.salesTax] Sales tax valued used by the government
 * @returns {object} Price table to create
 */
function buildPriceTable(priceTableData, options) {
    const { arrayOfRows, title } = priceTableData;
    const { salesTax } = options;

    // Keeps track of which of the relevant column headers 1) exist and 2) have blank values
    const headerFileInfoLookup = {};
    Object.keys(columnHeaderToPricingTableProperties).forEach((headerKey) => {
        headerFileInfoLookup[headerKey] = {
            existsInFile: false,
            hasBlankRowsInFile: false,
        };
    });

    // Iterate over all non-header rows and create priceItem objects
    const priceItems = arrayOfRows.map((row, index) => {
        Object.entries(row).forEach(([rawHeaderKey, value]) => {
            // We want header checks to be case-insensitive, but XLSX parses the headers
            // as-written, so we need to overwrite to lower case here
            const headerKey = rawHeaderKey.toLowerCase();

            // Taxable column should be treated as empty regardless of what's entered when
            // government does not have sales tax enabled.
            if (headerKey === TAXABLE && !salesTax) {
                // Mutate row data to remove any values from taxable column
                // The default xlsx parser behavior is to not include any key/values for rows that
                // are empty, so we emulate that here.
                delete row[rawHeaderKey];
                return;
            }

            // Update whether the headerKey is found and whether any blank values are found
            if (headerKey in headerFileInfoLookup) {
                // NOTE: It appears missing values are omitted from row object as opposed to
                // designated as empty strings. `hasBlankRowsInFile` isn't relied on currently.
                // Will need to update this logic if that changes in the future.
                if (value === '') {
                    headerFileInfoLookup[headerKey].hasBlankRowsInFile = true;
                } else {
                    // A header key only counts as "existing" if there is at least one
                    // non-blank row value under it.
                    headerFileInfoLookup[headerKey].existsInFile = true;
                }
            }
        });

        const priceItemRowData = prepareAndValidateRowEntry(
            row,
            columnHeaderToPricingTableProperties
        );

        const priceItem = {
            newPriceItemUuid: UUIDv4(),
            orderById: index + 1,
            ...priceItemRowData,
        };

        return priceItem;
    });
    const priceTableConfig = {
        ...defaultPriceTableValues,
        [HAS_QUANTITY]: false,
        [TITLE]: title,
    };
    Object.keys(columnHeaderToPricingTableProperties).forEach((headerKey) => {
        const headerPricingTableProperties = columnHeaderToPricingTableProperties[headerKey];
        const headerInfoInFile = headerFileInfoLookup[headerKey];
        const headerIsRequired = headerPricingTableProperties.tableConfigProperty === undefined;
        if (headerIsRequired && !headerInfoInFile.existsInFile) {
            throw new Error(
                `Required header '${headerKey}' was not found in ` +
                    `${title ? `Table Name: "${title}"` : 'the file you uploaded'}.`
            );
        }
        if (
            headerInfoInFile.existsInFile &&
            !headerPricingTableProperties.allowBlankValues &&
            headerInfoInFile.hasBlankRowsInFile
        ) {
            throw new Error(
                `Header '${headerKey}' cannot have empty values` +
                    ' and has an empty value in one or more rows of ' +
                    `${title ? `Table Name: "${title}"` : 'the file'}.`
            );
        }
        if (headerInfoInFile.existsInFile) {
            priceTableConfig[headerPricingTableProperties.tableConfigProperty] = true;
            if (headerPricingTableProperties.headerNameToAddOnExistence) {
                priceTableConfig[headerPricingTableProperties.headerNameField] =
                    headerPricingTableProperties.headerNameToAddOnExistence;
            }
        }
    });

    priceTableConfig[HAS_TOTAL_ROW] =
        !!priceTableConfig[HAS_QUANTITY] || !!priceTableConfig[HAS_SALES_TAX_ROW];
    priceTableConfig[SPECIFY_QUANTITY] = !!priceTableConfig[HAS_QUANTITY];

    return {
        priceItems,
        priceTableConfig,
    };
}

/**
 * Extract pricing table rows from a pricing table spreadsheet file.
 * Returns the two values necessary for initializing a price table:
 *   - priceItems (an array of objects)
 *   - priceTableConfig  (a config object)
 * Assumes the CSV file (or the first sheet of the spreadsheet file) will have a header row with
 * one or more pricing table headers.
 * @param {object} file File to import and parse for pricing table data
 * @param {object} options Options to use in building the price table
 * @returns {object[]} Array of price tables to create
 */
export const parsePricingTableFile = (file, options) => {
    return parseXLSXCompatibleFileToJSON(file).then((arrayOfRows) => {
        if (arrayOfRows.length === 0) {
            throw new Error('No data was found in the uploaded file.');
        }

        const TABLE_NAME = 'table name';
        const UNTITLED = UUIDv4();
        const priceTableNames = [];

        const priceTablesMap = arrayOfRows.reduce((priceTablesObject, row) => {
            const tableNameKey = Object.keys(row).find((key) => key.toLowerCase() === TABLE_NAME);
            const tableName = tableNameKey ? row[tableNameKey] : UNTITLED; // TABLE_NAME column may be blank
            if (priceTablesObject[tableName]) {
                priceTablesObject[tableName].push(row);
            } else {
                priceTablesObject[tableName] = [row];
                priceTableNames.push(tableName); // Needed to preserve order of arrays
            }
            return priceTablesObject;
        }, {});

        if (priceTableNames.length > 5) {
            throw new Error(
                'Maximum number of price tables exceeded! Can only create 5 tables at once. ' +
                    `Attempting to create ${priceTableNames.length} tables. ` +
                    'Please adjust the "Table Name" column.'
            );
        }

        return priceTableNames.map((priceTableName) => {
            return buildPriceTable(
                {
                    arrayOfRows: priceTablesMap[priceTableName],
                    title: priceTableName === UNTITLED ? undefined : priceTableName,
                },
                options
            );
        });
    });
};
