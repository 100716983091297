import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { buildVendorSearchQuery } from '../../../../../../shared_config/vendors/expanded-supplier-network';
import { useSearchService } from '../../../../hooks';
import { getProjectJS } from '../../selectors';
import { FLAGS, useFlags } from '../../../../lib/launchdarkly';

export const useVendorSearch = ({ categories, setError, setResults, setTotal, notifiedUsers }) => {
    const project = useSelector(getProjectJS);
    const enableApiVendorSearch = useFlags(FLAGS.ENABLE_VENDOR_SEARCH_THROUGH_API);
    const {
        vendors: { search: vendorSearch, loading },
    } = useSearchService(enableApiVendorSearch, project.government_id);

    const search = useCallback(
        async (locations) => {
            const params = buildVendorSearchQuery({ categories, locations });

            // We want to have at least 1 location selected
            if (isEmpty(params.query.or)) {
                return { count: 0, results: [] };
            }

            // the limit is 200, we pass a higher limit to show a warning if more results are returned
            const res = await vendorSearch({ ...params, size: 250 });

            return res;
        },
        [project]
    );

    const searchFilterAndSet = async (locations) => {
        try {
            setError(null);
            const res = await search(locations);

            // We need to filter the results from OS and keep only
            // those that were not notified. When the project is live,
            // notifiedUsers will contain a list of users already notified (coming from the audit table).
            // The idea is to display only the users that were not notified. (only when the project is posted)
            const { count, users } = res.results.reduce(
                (acc, cur) => {
                    if (!cur.users_ids) {
                        return acc;
                    }

                    const userIds = cur.users_ids
                        .split(' ')
                        .filter((id) => !notifiedUsers.ids.has(parseInt(id, 10)));

                    if (!userIds.length) {
                        return acc;
                    }

                    return {
                        count: acc.count + 1,
                        users: acc.users + userIds.length,
                    };
                },
                { count: 0, users: 0 }
            );

            setTotal(count);
            setResults({ count, users });
        } catch (e) {
            setError(e);
        }
    };

    return { loading, search, searchFilterAndSet };
};
