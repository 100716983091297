import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { getVendorJS } from './selectors';
import { hideVendorProfileModal, loadVendor } from '../../../actions/vendorProfile';
import { Button, LoadingSpinner, LoadingError, VendorProfile } from '../../../components';

const mapStateToProps = (state) => {
    return {
        loadError: state.vendorProfile.get('loadVendorError'),
        loading: state.vendorProfile.get('loadingVendor'),
        showModal: state.vendorProfile.get('showVendorModal'),
        vendor: getVendorJS(state),
        vendorId: state.vendorProfile.get('vendorId'),
    };
};

const mapDispatchToProps = {
    hideModal: hideVendorProfileModal,
    loadVendor,
};

// @connect
class ConnectedVendorProfileModal extends Component {
    static propTypes = {
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        loadVendor: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
        vendor: PropTypes.object,
        vendorId: PropTypes.number,
    };

    UNSAFE_componentWillUpdate(nextProps) {
        if (!this.props.showModal && nextProps.showModal) {
            const { vendorId } = nextProps;
            this.props.loadVendor(vendorId);
        }
    }

    renderBody() {
        const { loading, loadError, vendor } = this.props;
        if (loading) return <LoadingSpinner />;
        if (loadError) return <LoadingError error={loadError} />;
        if (!vendor) return null;
        return <VendorProfile vendor={vendor} />;
    }

    render() {
        const { showModal, hideModal } = this.props;
        return (
            <Modal onHide={hideModal} show={showModal}>
                <Modal.Body>
                    {this.renderBody()}
                    <div className="text-center">
                        <Button onClick={hideModal} qaTag="connectedVendorProfileModal-done">
                            Done
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const VendorProfileModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedVendorProfileModal);
