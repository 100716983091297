import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import React from 'react';
import { Panel } from 'react-bootstrap';
import Media from 'react-media';

import {
    COMMENT,
    DESCRIPTION,
    DISCOUNT,
    LINE_ITEM,
    QUANTITY,
    TOTAL_COST,
    UNIT_PRICE,
    UNIT_TO_MEASURE,
} from '../../../../shared_config/priceTables';
import { AWARD_TYPE } from './constants';
import { AgGridReact, AgGridReactPanelHeading, Button } from '..';
import { calculatePriceItemTotalCost, currencyFormatter, percentFormatter } from '../../helpers';
import { formatPercent } from '../helpers/utils';
import { SCREEN_SM_MAX } from '../../constants/mediaQuery';
import { conditionalPropCheck } from '../../utils';
import {
    dataTypesDict,
    DOCX_TABLE_LANDSCAPE_WIDTH,
    DOCX_TABLE_PORTRAIT_WIDTH,
} from '../../constants';

const { NUMBER, STRING } = dataTypesDict;

const PANEL_BODY_STYLES = {
    padding: 0,
};

export class VendorLineItemAwardsTable extends React.Component {
    static propTypes = {
        auctionMaxFractionDigits: PropTypes.number,
        isDocx: PropTypes.bool,
        isPublicView: PropTypes.bool,
        omitLineItem: PropTypes.bool.isRequired,
        priceTable: PropTypes.shape({
            awardRows: PropTypes.arrayOf(
                PropTypes.shape({
                    awardId: PropTypes.number.isRequired,
                    awardType: PropTypes.string.isRequired,
                    comment: PropTypes.string,
                    custom1: PropTypes.string,
                    custom2: PropTypes.string,
                    custom3: PropTypes.string,
                    custom4: PropTypes.string,
                    custom5: PropTypes.string,
                    description: PropTypes.string.isRequired,
                    discount: PropTypes.number,
                    discountOnly: PropTypes.bool,
                    lineItem: PropTypes.string,
                    quantity: PropTypes.number.isRequired,
                    taxable: PropTypes.bool,
                    unitPrice: conditionalPropCheck(
                        [NUMBER, STRING], // Unit price is 'N/A' when the price item is `discountOnly`
                        ({ props }) => !props.discountOnly
                    ),
                    unitToMeasure: PropTypes.string.isRequired,
                })
            ),
            description: PropTypes.string,
            hasComment: PropTypes.bool.isRequired,
            hasDiscount: PropTypes.bool.isRequired,
            hasPercentage: PropTypes.bool.isRequired,
            hasSalesTaxRow: PropTypes.bool.isRequired,
            hasQuantity: PropTypes.bool.isRequired,
            headerComment: PropTypes.string.isRequired,
            headerDescription: PropTypes.string.isRequired,
            headerDiscount: PropTypes.string.isRequired,
            headerQuantity: PropTypes.string.isRequired,
            headerTotal: PropTypes.string.isRequired,
            headerUnitPrice: PropTypes.string.isRequired,
            headerUnitToMeasure: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        salesTax: PropTypes.number,
        useLandscape: PropTypes.bool,
        vendorAwardData: PropTypes.shape({
            proposalId: PropTypes.number,
            vendorName: PropTypes.string,
        }).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    get currencyFormatterOpts() {
        const { auctionMaxFractionDigits } = this.props;
        if (auctionMaxFractionDigits) {
            return { maximumFractionDigits: auctionMaxFractionDigits, useSameMinAndMax: true };
        }
        return { use4FractionDigits: true };
    }

    constructor(props) {
        super(props);
        this.state = {
            hideAllColumns: !this.props.isDocx,
        };
    }

    getRowNodeId(data) {
        if (data.isSummaryRow) {
            return 'summaryRow';
        }

        return data.awardId;
    }

    generateColDefs(isMobile) {
        const {
            priceTable: {
                hasComment,
                hasDiscount,
                hasPercentage,
                hasQuantity,
                headerComment,
                headerDescription,
                headerDiscount,
                headerQuantity,
                headerTotal,
                headerUnitPrice,
                headerUnitToMeasure,
            },
            priceTable,
        } = this.props;
        const { hideAllColumns } = this.state;

        const colDefs = [];
        const customColumns = [];

        if (!this.props.omitLineItem) {
            colDefs.push({
                headerName: 'Line Item',
                field: LINE_ITEM,
                cellStyle: this.getCellStyle,
                width: 90,
                pinned: isMobile ? undefined : 'left',
                suppressMenu: true,
            });
        }

        colDefs.push(
            {
                headerName: 'Award Type',
                field: AWARD_TYPE,
                width: 100,
                suppressMenu: true,
                pinned: isMobile ? undefined : 'left',
            },
            {
                headerName: headerDescription,
                field: DESCRIPTION,
                cellClass: ['wrapText'], // Used exclusively for Excel export styles
                autoHeight: true,
                cellStyle: this.getCellStyle,
                width: isMobile ? 150 : 300,
                suppressMenu: true,
                pinned: isMobile ? undefined : 'left',
            },
            {
                headerName: headerQuantity,
                field: QUANTITY,
                width: 91,
                suppressMenu: true,
                pinned: isMobile ? undefined : 'left',
                hide: !hasQuantity,
            },
            {
                headerName: headerUnitToMeasure,
                field: UNIT_TO_MEASURE,
                cellStyle: this.getCellStyle,
                width: 90,
                suppressMenu: true,
                pinned: isMobile ? undefined : 'left',
            },
            {
                headerName: headerUnitPrice,
                field: UNIT_PRICE,
                cellClass: [hasPercentage ? 'percent' : '4FractionCurrency'], // Used exclusively for Excel export styles
                valueFormatter: hasPercentage
                    ? this.unitPricePercentageFormatter
                    : this.unitPriceCurrencyFormatter,
                width: 160,
                suppressMenu: true,
            },
            ...(hasDiscount
                ? [
                      {
                          headerName: headerDiscount,
                          field: DISCOUNT,
                          cellClass: ['percent'], // Used exclusively for Excel export styles
                          valueFormatter: this.unitPricePercentageFormatter,
                          width: 110,
                          suppressMenu: true,
                      },
                  ]
                : []),
            {
                headerName: headerTotal,
                field: TOTAL_COST,
                cellClass: ['4FractionCurrency'], // Used exclusively for Excel export styles
                valueFormatter: this.unitPriceCurrencyFormatter,
                width: 160,
                suppressMenu: true,
                hide: true,
            }
        );

        if (hasComment) {
            colDefs.push({
                headerName: headerComment,
                field: COMMENT,
                width: 120,
                hide: true,
                suppressMenu: true,
            });
        }

        [1, 2, 3, 4, 5].forEach((customColNum) => {
            if (priceTable[`hasCustom${customColNum}`]) {
                customColumns.push({
                    headerName: priceTable[`headerCustom${customColNum}`],
                    field: `custom${customColNum}`,
                    cellStyle: this.getCellStyle,
                    width: 120,
                    hide: hideAllColumns,
                    suppressMenu: true,
                });
            }
        });

        return colDefs.concat(customColumns);
    }

    getCellStyle(params) {
        const { node } = params;

        // Base styles for all cells
        const styles = {
            lineHeight: '18px',
            paddingBottom: '4px',
            paddingTop: '4px',
            whiteSpace: 'normal',
        };

        if (params.colDef.field === DESCRIPTION) {
            styles.whiteSpace = 'pre-wrap';
        }

        if (node.rowPinned && params.node.data.description === 'Total') {
            styles.fontWeight = 'bold';
        }

        return styles;
    }

    generateRows() {
        const {
            priceTable: { awardRows },
        } = this.props;

        return awardRows.map((row) => {
            const totalCost = calculatePriceItemTotalCost({ vendorResponse: row });
            return {
                awardId: row.awardId,
                awardType: capitalize(row.awardType),
                custom1: row.custom1,
                custom2: row.custom2,
                custom3: row.custom3,
                custom4: row.custom4,
                custom5: row.custom5,
                description: row.taxable ? `${row.description} [*]` : row.description,
                discount: row.discount,
                lineItem: row.lineItem,
                priceItemId: row.priceItemId,
                quantity: row.quantity,
                totalCost,
                unitToMeasure: row.unitToMeasure,
                unitPrice: row.unitPrice,
            };
        });
    }

    unitPriceCurrencyFormatter = (params) => currencyFormatter(params, this.currencyFormatterOpts);

    unitPricePercentageFormatter = (data) => percentFormatter(data);

    /**
     * Callback for saving a reference to the underlying AgReactGrid's API once it is ready. We need
     * access to the API to do things such as export data to a CSV.
     *
     * @param {object} params The `onGridReady` callback params
     * @param {object} params.api The underlying AgReactGrid's API
     */
    handleGridReady = (params) => {
        this.setState({ gridApi: params.api });
    };

    toggleHideAllColumns = () =>
        this.setState((prevState) => ({
            hideAllColumns: !prevState.hideAllColumns,
        }));

    renderGridButtons() {
        const { hideAllColumns } = this.state;
        return [
            <Button
                id="column-toggle"
                key="column-toggle"
                onClick={this.toggleHideAllColumns}
                tooltip={
                    hideAllColumns
                        ? 'Click to show all available columns'
                        : 'Click to hide all columns except Unit Cost'
                }
            >
                <i className={hideAllColumns ? 'fa fa-plus-square-o' : 'fa fa-minus-square-o'} />
                &nbsp;{hideAllColumns ? 'Show All Columns' : 'Hide All Columns'}
            </Button>,
        ];
    }

    getDocxCellWidths = (columnNum) => {
        const tableWidth = this.props.useLandscape
            ? DOCX_TABLE_LANDSCAPE_WIDTH
            : DOCX_TABLE_PORTRAIT_WIDTH;

        if (columnNum <= 5) {
            return [tableWidth - 100 * (columnNum - 1), 100];
        }
        if (columnNum <= 7) {
            return [tableWidth - 80 * (columnNum - 1), 80];
        }
        if (columnNum <= 9) {
            return [tableWidth - 60 * (columnNum - 1), 60];
        }
        return [tableWidth / columnNum, tableWidth / columnNum];
    };

    renderDocxTableHeader(columnsData) {
        const thStyle = { backgroundColor: '#003c81' };
        const pStyle = { textAlign: 'center', color: '#FFFFFF' };

        const [descriptionWidth, otherWidth] = this.getDocxCellWidths(columnsData.length);

        const columnHeaders = columnsData.map((columnData) => {
            const { field, headerName, hide } = columnData;

            if (hide) return null;

            const width = field === DESCRIPTION ? descriptionWidth : otherWidth;

            return (
                <th key={field} scope="col" style={thStyle} width={width}>
                    <p className="no-trailing-space" style={pStyle}>
                        <strong>{headerName}</strong>
                    </p>
                </th>
            );
        });

        return <tr className="repeat-header-row">{columnHeaders}</tr>;
    }

    renderDocxTableRows(columnsData, columnNum) {
        const { priceTable, salesTax } = this.props;

        return (
            <>
                {this.generateRows().map((row) => {
                    return (
                        <tr key={row.awardId}>
                            {columnsData.map((col, i) => {
                                if (col.hide) return null;

                                const field = col.field;
                                let value = row[field];

                                if (col.cellClass) {
                                    const name = col.cellClass[0];

                                    if (name === '4FractionCurrency') {
                                        value = currencyFormatter(
                                            { value },
                                            this.currencyFormatterOpts
                                        );
                                    }
                                    if (name === 'currency') {
                                        value = currencyFormatter({ value });
                                    }
                                    if (name === 'percent') {
                                        value = formatPercent(value);
                                    }
                                }

                                return (
                                    <td key={`${row.awardId} ${i}`}>
                                        <p style={{ textAlign: 'center' }}>{value}</p>
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                {priceTable.hasSalesTaxRow && salesTax && (
                    <tr key="taxFooter">
                        <td colSpan={columnNum} style={{ border: 'none' }}>
                            <p style={{ fontSize: 12 }}>[*] Denotes item is taxable</p>
                        </td>
                    </tr>
                )}
            </>
        );
    }

    render() {
        const {
            isDocx,
            isPublicView,
            priceTable: { awardRows, description, hasSalesTaxRow, title },
            salesTax,
            useLandscape,
        } = this.props;

        const { gridApi } = this.state;

        if (!awardRows.length) {
            return null;
        }

        if (isDocx) {
            const columnsData = this.generateColDefs();
            const columnNum = columnsData.length;

            return (
                <div>
                    {title && (
                        <p>
                            <strong>{title.toUpperCase()}</strong>
                        </p>
                    )}
                    {description && <p>{description}</p>}
                    <table
                        style={{ fontSize: columnNum > 7 ? '8pt' : '10pt' }}
                        width={
                            useLandscape ? DOCX_TABLE_LANDSCAPE_WIDTH : DOCX_TABLE_PORTRAIT_WIDTH
                        }
                    >
                        <thead>{this.renderDocxTableHeader(columnsData)}</thead>
                        <tbody>{this.renderDocxTableRows(columnsData, columnNum)}</tbody>
                    </table>
                </div>
            );
        }

        return (
            <Panel className={this.styles.panel} defaultExpanded>
                <AgGridReactPanelHeading
                    buttons={this.renderGridButtons()}
                    gridApi={gridApi}
                    hideButtons={isPublicView}
                    title={title}
                />
                <Panel.Body style={PANEL_BODY_STYLES}>
                    <Media query={`(max-width: ${SCREEN_SM_MAX}px)`}>
                        {(matches) => (
                            <AgGridReact
                                autoHeightMaxRows={30}
                                columns={this.generateColDefs(matches)}
                                getRowNodeId={this.getRowNodeId}
                                onGridReady={this.handleGridReady}
                                rows={this.generateRows()}
                            />
                        )}
                    </Media>
                    {salesTax && hasSalesTaxRow && (
                        <div className={this.styles.tableFooter}>
                            <span>[*]</span>
                            <p> Denotes item is taxable</p>
                        </div>
                    )}
                </Panel.Body>
            </Panel>
        );
    }
}
