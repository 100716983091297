import { createSelector } from 'reselect';

import { fieldNames } from '../../constants';
import { requisitionsCreateFormValueSelector } from '../../selectors';

const {
    REQUESTOR_ID,
    EXPECTED_PURCHASE_ORDER_DATE,
    FISCAL_PERIOD,
    FISCAL_PERIOD_TAG_ID,
    DESIRED_DELIVERY_DATE,
    DESCRIPTION_OF_REQUEST,
    SUMMARY,
    BACKGROUND,
    CREATED_AT,
} = fieldNames;

export const getReviewGeneralInformationData = createSelector(
    [
        (state) => requisitionsCreateFormValueSelector(state, REQUESTOR_ID),
        (state) => requisitionsCreateFormValueSelector(state, EXPECTED_PURCHASE_ORDER_DATE),
        (state) => requisitionsCreateFormValueSelector(state, DESIRED_DELIVERY_DATE),
        (state) => requisitionsCreateFormValueSelector(state, DESCRIPTION_OF_REQUEST),
        (state) => requisitionsCreateFormValueSelector(state, SUMMARY),
        (state) => requisitionsCreateFormValueSelector(state, BACKGROUND),
        (state) => requisitionsCreateFormValueSelector(state, FISCAL_PERIOD),
        (state) => requisitionsCreateFormValueSelector(state, FISCAL_PERIOD_TAG_ID),
        (state) => requisitionsCreateFormValueSelector(state, CREATED_AT),
    ],
    (
        requestorId,
        expectedPurchaseOrderDate,
        desiredDeliveryDate,
        descriptionOfRequest,
        summary,
        background,
        fiscalPeriod,
        fiscalPeriodTagId,
        createdAt
    ) => ({
        requestorId,
        expectedPurchaseOrderDate,
        desiredDeliveryDate,
        descriptionOfRequest,
        summary,
        background,
        fiscalPeriod,
        fiscalPeriodTagId,
        createdAt,
    })
);
