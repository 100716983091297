import { listToDict } from '../../../../../../../../../../../../shared_config/helpers';

const accountFields = [
    'accountNumber',
    'amount',
    'availableBudgetAmount',
    'accountObject',
    'accountExternalId',
];

export const accountFieldNames = listToDict(accountFields);

// TODO: [FMS] accountPseudoKey is a very "FIN" term.  We should rename it to something more generic for FMS connected requisitions.
const accountObjectFields = [
    'accountCompressedFormatted',
    'accountPseudoKey',
    'description',
    'accountType',
    'isExpenseAccount',
];

export const accountObjectFieldNames = listToDict(accountObjectFields);

export const columnsLayout = {
    accountNumber: 4,
    amount: 2,
    toggleAuto: 1,
    budgetCheck: 4,
    remove: 1,
};
