export const numberOfRequestsColors = ['#828487', '#257D34', '#885F99', '#C77110', '#D15336'];
export const activeRequestsColors = ['#6A92D4', '#C76E6B', '#4C5FB5', '#9C4174'];
export const requestsNeedingAttentionColors = {
    ERROR: '#b23e24', // $color-fg-error
    WARNING: '#b06209', // $color-fg-warning-small
};

export const visualizationFontFamily = '"Benton Sans", Helvetica, Arial, sans-serif';

export const borderColor = '#dce0e0';

export const numberOfRequestsVisualization = 'requisitions-number-of-requests';
export const activeRequestsVisualization = 'requisitions-active-requests';
export const requestsNeedingAttentionVisualization = 'requisitions-requests-needing-attention';

export const visualizationColors = {
    [numberOfRequestsVisualization]: numberOfRequestsColors,
    [activeRequestsVisualization]: activeRequestsColors,
    [requestsNeedingAttentionVisualization]: requestsNeedingAttentionColors,
};
