import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { connect } from 'react-redux';

import { showExportModal } from '../../../../actions/exportProject';
import { CDSDropdownButton, MenuItem } from '../../../../components';
import { trackEvent } from '../../../../helpers';

const mapDispatchToProps = {
    showExportModal,
};

// @connect
class ConnectedExportButton extends Component {
    static propTypes = {
        context: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
        projectDocumentUrl: PropTypes.string,
        shouldSave: PropTypes.bool,
        showExportModal: PropTypes.func.isRequired,
        standalone: PropTypes.bool,
    };

    get styles() {
        return require('./shared.scss');
    }

    handleDownloadDocumentClick = () => {
        const { context, shouldSave } = this.props;

        const eventData = { view: context };

        trackEvent('Export Project Modal', eventData);
        this.props.showExportModal(null, shouldSave);
    };

    handleDownloadOriginalClick = () => {
        const { context } = this.props;

        const eventData = { view: context };

        trackEvent('Download Original Project', eventData);
    };

    handlePreviewDocumentClick = () => {
        const { context, shouldSave } = this.props;

        const eventData = { view: context };

        trackEvent('Preview Project', eventData);
        this.props.showExportModal('preview', shouldSave);
    };

    render() {
        const { disabled, projectDocumentUrl, standalone } = this.props;

        const items = (
            <>
                <MenuItem
                    onClick={this.handlePreviewDocumentClick}
                    qaTag="projectActionMenu-previewDocument"
                >
                    <i className="fa fa-fw fa-search" /> Preview Document
                </MenuItem>
                <MenuItem
                    onClick={this.handleDownloadDocumentClick}
                    qaTag="projectActionMenu-downloadDocument"
                >
                    <i className="fa fa-fw fa-download" /> Download Document
                </MenuItem>
                {projectDocumentUrl && (
                    <MenuItem
                        href={projectDocumentUrl}
                        onClick={this.handleDownloadOriginalClick}
                        qaTag="projectActionMenu-downloadOriginal"
                    >
                        <i className="fa fa-fw fa-bookmark" /> Download Original
                    </MenuItem>
                )}
            </>
        );

        if (standalone) {
            return (
                <CDSDropdownButton qaTag="projectCreate-export" title="Export" variant="secondary">
                    {items}
                </CDSDropdownButton>
            );
        }

        return (
            <NavDropdown
                className={classnames(
                    'action-toolbar-export-btn',
                    this.styles.exportButtonDropdown
                )}
                disabled={disabled}
                id="action-toolbar-export-dropdown"
                title={
                    <span>
                        <i className="fa fa-cloud-download" /> Export
                    </span>
                }
            >
                {items}
            </NavDropdown>
        );
    }
}

export const ExportButton = connect(null, mapDispatchToProps)(ConnectedExportButton);
