import { hexToRGB } from '../../../../../../../shared_config/helpers';
import { visualizationColors } from './constants';

export const highlightPoint = (point, name) => {
    if (!point || !point.update) return;

    const colors = visualizationColors[name];

    point.update({
        color: colors[point.colorIndex],
    });
};

export const unhighlightPoint = (point, name) => {
    if (!point || !point.update) return;

    const colors = visualizationColors[name];

    point.update({
        color: hexToRGB(colors[point.colorIndex], 0.3),
    });
};

export const defaultLabelFormatter = (name, y) => {
    return `
    <div style="display:flex;">
        <p style="font-size: 14px; font-weight: 400;">${name}:</p>
        <p style="font-size: 14px;">${y}</p>
    </div>
    `;
};
