import React from 'react';
import { Navigate, Outlet, redirect } from 'react-router-dom';
import { NoSsr } from '@mui/material';

import { Account, Admin } from '../../containers';
import { UserList } from '../../containers/Admin';
import * as Vendor from '../../containers/VendorApp';
import { loader as awardedContractsLoader } from '../../containers/VendorApp/AwardedContracts';
import { OrganizationInfoForm } from '../../components';
import ProposalsRoutes from './Proposals';
import {
    fetchLoaderData,
    legacyFetchData,
    requireVendorAdmin,
    requireVendorLogin,
} from '../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Vendor.App />,
            loader: async (props) => {
                const permissionRedirect = await requireVendorLogin(getState)(props);

                if (permissionRedirect) {
                    return permissionRedirect;
                }

                return legacyFetchData(getState, dispatch, Vendor.App)(props);
            },
            path: '/vendors/:vendorId',
            children: [
                {
                    element: <Outlet />,
                    loader: () => {
                        return redirect('proposals');
                    },
                    index: true,
                },
                {
                    element: <Vendor.ProposalsList />,
                    path: 'proposals',
                    loader: legacyFetchData(getState, dispatch, Vendor.ProposalsList),
                },
                ...ProposalsRoutes(getState, dispatch),
                {
                    element: (
                        <NoSsr>
                            <Navigate replace to="../proposals" />
                        </NoSsr>
                    ),
                    path: 'my-projects',
                },
                {
                    element: <Vendor.GovernmentSubscriptionsList />,
                    path: 'my-subscriptions',
                },
                {
                    element: <Vendor.OpenBids />,
                    loader: legacyFetchData(getState, dispatch, Vendor.OpenBids),
                    path: 'open-bids',
                },
                {
                    element: <Vendor.Awards />,
                    path: 'awards',
                    children: [
                        {
                            element: <Outlet />,
                            loader: () => {
                                return redirect('contracts');
                            },
                            index: true,
                        },
                        {
                            element: <Vendor.AwardedContracts />,
                            loader: fetchLoaderData(awardedContractsLoader(dispatch)),
                            path: 'contracts',
                        },
                        {
                            element: <Vendor.AwardedProjects />,
                            loader: legacyFetchData(getState, dispatch, Vendor.AwardedProjects),
                            path: 'projects',
                        },
                    ],
                },
                {
                    element: <Vendor.ContractsChecklists />,
                    loader: legacyFetchData(getState, dispatch, Vendor.ContractsChecklists),
                    path: 'checklists',
                },
                {
                    element: <Vendor.ContractChecklist />,
                    loader: legacyFetchData(getState, dispatch, Vendor.ContractChecklist),
                    path: 'checklists/:checklistId',
                },
                {
                    element: <Account />,
                    path: 'account',
                },
                {
                    element: <Outlet />,
                    loader: () => {
                        return redirect('../my-subscriptions');
                    },
                    path: 'account/notifications',
                },
                {
                    element: <Admin />,
                    loader: requireVendorAdmin(getState),
                    path: 'admin',
                    children: [
                        {
                            element: <Outlet />,
                            loader: () => {
                                return redirect('users');
                            },
                            index: true,
                        },
                        {
                            element: <UserList />,
                            path: 'users',
                        },
                    ],
                },
                {
                    element: <Outlet />,
                    path: 'profile',
                    children: [
                        {
                            element: <Vendor.VendorProfile />,
                            loader: legacyFetchData(getState, dispatch, Vendor.VendorProfile),
                            index: true,
                        },
                        {
                            element: <Vendor.VendorProfileEdit />,
                            path: 'edit',
                            children: [
                                {
                                    element: <OrganizationInfoForm />,
                                    index: true,
                                },
                                {
                                    element: <OrganizationInfoForm />,
                                    path: 'info',
                                },
                                {
                                    element: <Vendor.VendorCategories />,
                                    path: 'categories',
                                },
                                {
                                    element: <Vendor.VendorCertifications />,
                                    path: 'certifications',
                                },
                                {
                                    element: <Vendor.VendorDocuments />,
                                    loader: legacyFetchData(
                                        getState,
                                        dispatch,
                                        Vendor.VendorDocuments
                                    ),
                                    path: 'documents',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
};
