import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import classNames from 'classnames';

import { CDSButton } from '../SDv2';
import { FiltersCountBadge } from './FiltersCountBadge';
import { useClickOutside } from '../../hooks';
import { Button } from '..';

export const FiltersDropdown = ({
    buttonClassName,
    className,
    defaultAppliedOptions = [],
    onApply,
    children,
}) => {
    const styles = require('./index.scss');

    const dropdownRef = useRef(null); // Ref for the dropdown element. Used to detect clicks outside the dropdown

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(defaultAppliedOptions);
    const [appliedOptions, setAppliedOptions] = useState(defaultAppliedOptions);

    const dropdown = {
        close: () => {
            setIsOpen(false);
        },
        toggle: () => {
            setIsOpen(!isOpen);
        },
        apply: (options) => {
            onApply(options);
            setAppliedOptions(options);
            dropdown.toggle();
        },
        cancel: () => {
            setSelectedOptions(defaultAppliedOptions);
            dropdown.close();
        },
        clearAll: () => {
            setSelectedOptions([]);
        },
        toggleOption: (option) => {
            if (selectedOptions.find((selectedOption) => isEqual(selectedOption, option))) {
                setSelectedOptions(
                    selectedOptions.filter((selectedOption) => !isEqual(selectedOption, option))
                );
            } else {
                setSelectedOptions([...selectedOptions, option]);
            }
        },
        selectedOptions,
        setSelectedOptions,
    };

    // Close the dropdown when the user clicks outside of it
    useClickOutside(dropdownRef, dropdown.cancel);

    return (
        <div className={classNames(styles.checkboxDropdown, className)} ref={dropdownRef}>
            <CDSButton
                className={buttonClassName}
                onClick={dropdown.toggle}
                qaTag="filtersDropdown-toggle"
                variant="secondary"
            >
                <div
                    className={classNames(
                        styles.filtersButton,
                        !isOpen && styles.filtersButtonClosed
                    )}
                >
                    <i className="fa fa-filter" />
                    <span>Filters</span>
                    {appliedOptions.length > 0 ? (
                        <FiltersCountBadge value={appliedOptions.length} />
                    ) : null}
                </div>
            </CDSButton>
            {isOpen && (
                <div className={styles.dropdownContent}>
                    <div className={styles.filtersHeader}>
                        <h4 className={styles.title}>Filters</h4>
                        {selectedOptions.length > 0 && (
                            <Button
                                className={styles.clearAll}
                                onClick={dropdown.clearAll}
                                qaTag="filtersDropdown-clearAll"
                            >
                                <i className="fa fa-close" /> Clear All
                            </Button>
                        )}
                    </div>
                    <div className={styles.groupsContainer}>{children({ dropdown })}</div>
                    <div className={styles.dropdownButtonsContainer}>
                        <div className={styles.dropdownButtons}>
                            <CDSButton
                                onClick={dropdown.cancel}
                                qaTag="filtersDropdown-cancel"
                                variant="secondary"
                            >
                                Cancel
                            </CDSButton>
                            <CDSButton
                                disabled={isEqual(selectedOptions, appliedOptions)}
                                onClick={() => dropdown.apply(selectedOptions)}
                                qaTag="filtersDropdown-apply"
                                variant="primary"
                            >
                                Apply
                            </CDSButton>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

FiltersDropdown.propTypes = {
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    defaultAppliedOptions: PropTypes.arrayOf(PropTypes.object),
    onApply: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
};
