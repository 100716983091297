import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { RouterLink } from '../RouterLink/RouterLink';

export const Tab = ({ label, qaTag, route, activeRoute, onClick }) => {
    const { tab, activeTab, checkIcon, tabContainer } = require('./Tab.scss');

    return (
        <div className={tabContainer} onClick={() => (onClick ? onClick() : null)}>
            <RouterLink
                className={classNames(tab, { [activeTab]: activeRoute === route })}
                qaTag={qaTag}
                to={route}
            >
                {label}
                <i aria-hidden="true" className={`fa fa-check pull-right ${checkIcon}`} />
            </RouterLink>
        </div>
    );
};

Tab.propTypes = {
    activeRoute: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    qaTag: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
};
