import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { form } from './constants';
import { validate } from './validate';
import { setPassword } from '../../../actions/auth';
import { Button, PageTitle, PasswordFormSection } from '../../../components';

const mapStateToProps = (state) => {
    return {
        settingPassword: state.auth.get('settingPassword'),
    };
};

const mapDispatchToProps = {
    setPassword,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedSetPasswordForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        invalid: PropTypes.bool,
        setPassword: PropTypes.func.isRequired,
        settingPassword: PropTypes.bool,
    };

    get styles() {
        return require('./SetPasswordForm.scss');
    }

    handleSetPassword = (data) => {
        const { newPassword1, newPassword2 } = data;

        this.props.setPassword(newPassword1, newPassword2);
    };

    render() {
        const { handleSubmit, invalid, settingPassword } = this.props;

        return (
            <form onSubmit={handleSubmit(this.handleSetPassword)}>
                <PageTitle title="Set Password" />
                <div className={this.styles.passwordContainer}>
                    <PasswordFormSection autoFocus disabled={settingPassword} />
                </div>
                <Button block bsStyle="primary" disabled={invalid || settingPassword} type="submit">
                    {settingPassword ? 'Saving...' : 'Save New Password'}
                </Button>
            </form>
        );
    }
}

export const SetPasswordForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedSetPasswordForm);
