import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import React, { Component } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Box } from '@og-pro/ui';

import { ContractsByContractTags } from './ContractsByContractTags';
import { ContractsByDepartment } from './ContractsByDepartment';
import { ContractsByFundingSourceTags } from './ContractsByFundingSourceTags';
import { ContractsByProcurementTags } from './ContractsByProcurementTags';
import { ContractsByReleasedChecklistsAge } from './ContractsByReleasedChecklistsAge';
import { ContractsByVendorCerts } from './ContractsByVendorCerts';
import { ContractSpendByDepartment } from './ContractSpendByDepartment';
import { goToContracts } from './helpers';
import { InsuranceExpiring } from './InsuranceExpiring';
import { NeedsAttentionChecklists } from './NeedsAttentionChecklists';
import { NoRebidContractsEnding } from './NoRebidContractsEnding';
import { RebidContractsEnding } from './RebidContractsEnding';
import { getAllVendorCertificationsJS } from '../VendorManagement/selectors';
import {
    getContractsJS,
    getContractTagsJS,
    getDepartmentsJS,
    getFundingSourceTagsJS,
    getProcurementClassificationTagsJS,
    getUserJS,
} from '../../App/selectors';
import connectData from '../../ConnectData';
import { isInitialClientLoaded } from '../../selectors';
import { loadAllCertifications } from '../../../actions/adminVendor';
import { loadContracts, resetLoadedContracts } from '../../../actions/contracts';
import {
    DashboardDepartmentSelectForm,
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    SimpleCountPanel,
} from '../../../components';
import { NoContracts } from '../../../components/ContractList/NoContracts';
import {
    contractFilterTypesDict,
    contractStatusesDict,
} from '../../../../../shared_config/contracts';
import { checklistStatusTypes } from '../../../../../shared_config/checklists';

function fetchData(getState, dispatch) {
    const state = getState();
    const promises = [dispatch(loadContracts({ dashboardView: true }))];

    if (!getAllVendorCertificationsJS(state).length) {
        promises.push(dispatch(loadAllCertifications()));
    }

    return Promise.all(promises);
}

const mapStateToProps = (state) => {
    return {
        certifications: getAllVendorCertificationsJS(state),
        contracts: getContractsJS(state),
        contractTags: getContractTagsJS(state),
        fundingSourceTags: getFundingSourceTagsJS(state),
        departments: getDepartmentsJS(state),
        isClientLoaded: isInitialClientLoaded(state),
        loadError: state.contracts.get('loadContractsError'),
        loading: state.contracts.get('loadingContracts'),
        procurementClassificationTags: getProcurementClassificationTagsJS(state),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    loadContracts,
    resetLoadedContracts,
};

class ConnectedContractsDashboard extends Component {
    static propTypes = {
        certifications: PropTypes.array.isRequired,
        contracts: PropTypes.array.isRequired,
        contractTags: PropTypes.array.isRequired,
        departments: PropTypes.array.isRequired,
        fundingSourceTags: PropTypes.array.isRequired,
        isClientLoaded: PropTypes.bool,
        loadContracts: PropTypes.func.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool,
        params: PropTypes.shape({
            governmentId: PropTypes.string.isRequired,
        }).isRequired,
        procurementClassificationTags: PropTypes.array.isRequired,
        resetLoadedContracts: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        user: PropTypes.shape({
            department: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
            government: PropTypes.shape({
                hideContractSpend: PropTypes.bool.isRequired,
            }).isRequired,
            userPermissions: PropTypes.shape({
                isContractAdmin: PropTypes.bool.isRequired,
            }).isRequired,
        }).isRequired,
    };

    static defaultProps = {
        isClientLoaded: false,
        loadError: null,
    };

    componentWillUnmount() {
        this.props.resetLoadedContracts();
    }

    // Hack: When in the `contracts` page and using the back button to navigate back to this page,
    // the `contracts` in the store are still present for a split second before loading starts
    // causing the page to crash as they do not have some attributes required by the dashboard.
    // This check is put in here specifically to address that scenario, but is otherwise unneeded.
    get contractsNotReady() {
        const { contracts } = this.props;

        if (contracts.length && !contracts[0].budget.purchaseOrders) {
            return true;
        }

        return false;
    }

    filterDepartments = (data) => {
        const { departmentIds } = data;
        this.props.loadContracts({
            dashboardView: true,
            filters: [
                {
                    type: contractFilterTypesDict.DEPARTMENT,
                    value: departmentIds,
                },
            ],
        });
    };

    renderBody() {
        const {
            certifications,
            contracts,
            contractTags,
            departments,
            fundingSourceTags,
            loadError,
            loading,
            params: { governmentId },
            procurementClassificationTags,
            router,
            user: {
                department,
                government: { hideContractSpend },
                userPermissions: { isContractAdmin },
            },
        } = this.props;

        if (loading || this.contractsNotReady) return <LoadingSpinner />;
        if (loadError) return <LoadingError error={loadError} />;

        if (contracts.length === 0) return <NoContracts />;

        const govId = parseInt(governmentId, 10);
        const activeContracts = contracts.filter(
            (contract) => contract.status === contractStatusesDict.ACTIVE
        );
        const activeAndExpiredSixMonthsContracts = contracts.filter(
            (contract) => moment().diff(contract.endDate, 'month') <= 6
        );
        const activeAndUpcomingThreeMonthsChecklistsContracts = contracts.filter(
            (contract) =>
                contract.checklists.length > 0 &&
                contract.status === contractStatusesDict.ACTIVE &&
                contract.checklists.some(
                    (checklist) => checklist.status !== checklistStatusTypes.COMPLETE
                )
        );
        const activePiggybackContracts = activeContracts.filter((contract) => contract.isPiggyback);
        const activeCooperativeContracts = activeContracts.filter(
            (contract) => contract.isCooperative
        );

        const activeContractsWithDbe = activeContracts.filter(
            (contract) => contract.contractParty?.vendor?.vendorCertifications.length > 0
        );
        const contractsWithPendingAttachments = contracts.filter(
            ({ contractAttachments }) => contractAttachments.length > 0
        );

        const activeContractsClickHandler = () => {
            return goToContracts(
                govId,
                activeContracts.map((contract) => contract.id),
                router
            );
        };
        const activePiggybackContractsClickHandler = () => {
            return goToContracts(
                govId,
                activePiggybackContracts.map((contract) => contract.id),
                router
            );
        };
        const activeCooperativeContractsClickHandler = () => {
            return goToContracts(
                govId,
                activeCooperativeContracts.map((contract) => contract.id),
                router
            );
        };
        const activeContractsWithDbeClickHandler = () => {
            return goToContracts(
                govId,
                activeContractsWithDbe.map((contract) => contract.id),
                router
            );
        };
        const contractsWithPendingAttachmentsClickHandler = () => {
            return goToContracts(
                govId,
                contractsWithPendingAttachments.map((contract) => contract.id),
                router,
                { detailView: 'pending-documents' }
            );
        };

        return (
            <>
                {!isContractAdmin && (
                    <Box component="h4" mb={2}>
                        Viewing Contracts by Department: {department.name}
                    </Box>
                )}
                <div className="row">
                    <div className="col-md-3">
                        <SimpleCountPanel
                            clickHandler={activeContractsClickHandler}
                            count={activeContracts.length}
                            title="Total Active Contracts"
                        />
                    </div>
                    <div className="col-md-3">
                        <SimpleCountPanel
                            clickHandler={activePiggybackContractsClickHandler}
                            count={activePiggybackContracts.length}
                            title="Total Active Contracts with Piggyback Language"
                        />
                    </div>
                    <div className="col-md-3">
                        <SimpleCountPanel
                            clickHandler={activeCooperativeContractsClickHandler}
                            count={activeCooperativeContracts.length}
                            title="Total Active Contracts Using a Cooperative"
                        />
                    </div>
                    <div className="col-md-3">
                        <SimpleCountPanel
                            clickHandler={contractsWithPendingAttachmentsClickHandler}
                            count={contractsWithPendingAttachments.length}
                            title="Contracts with Pending Documents"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <InsuranceExpiring contracts={activeContracts} />
                    </div>
                    <div className="col-md-3">
                        <RebidContractsEnding contracts={activeAndExpiredSixMonthsContracts} />
                    </div>
                    <div className="col-md-3">
                        <NoRebidContractsEnding contracts={activeAndExpiredSixMonthsContracts} />
                    </div>
                    <div className="col-md-3">
                        <ContractsByReleasedChecklistsAge
                            contracts={activeAndUpcomingThreeMonthsChecklistsContracts}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <NeedsAttentionChecklists
                            contracts={activeAndUpcomingThreeMonthsChecklistsContracts}
                            panelTitle="Checklists in Progress"
                        />
                    </div>
                </div>
                {!hideContractSpend && (
                    <div className="row">
                        <div className="col-xs-12">
                            <ContractSpendByDepartment
                                contracts={activeContracts}
                                departments={departments}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-md-6">
                        <ContractsByDepartment contracts={activeContracts} />
                    </div>
                    <div className="col-md-6">
                        <ContractsByProcurementTags
                            contracts={activeContracts}
                            procurementTags={procurementClassificationTags}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <ContractsByFundingSourceTags
                            contracts={activeContracts}
                            fundingSourceTags={fundingSourceTags}
                        />
                    </div>
                    <div className="col-md-6">
                        <ContractsByContractTags
                            contractTags={contractTags}
                            contracts={activeContracts}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <ContractsByVendorCerts
                            certifications={certifications}
                            contracts={activeContractsWithDbe}
                        />
                    </div>
                    <div className="col-md-3">
                        <SimpleCountPanel
                            clickHandler={activeContractsWithDbeClickHandler}
                            count={activeContractsWithDbe.length}
                            title="Total Active Contracts with DBE"
                        />
                    </div>
                </div>
            </>
        );
    }

    render() {
        const {
            isClientLoaded,
            user: {
                userPermissions: { isContractAdmin },
            },
        } = this.props;

        if (!isClientLoaded) return null;

        return (
            <Main>
                <PageTitle title="Contracts Dashboard" />
                <h1 className="visually-hidden">Contracts Dashboard</h1>
                {isContractAdmin && (
                    <div className="row">
                        <div className="col-md-offset-6 col-md-6">
                            <DashboardDepartmentSelectForm
                                form="contractDashboardDepartmentSelectForm"
                                onSubmit={this.filterDepartments}
                            />
                        </div>
                    </div>
                )}
                {this.renderBody()}
            </Main>
        );
    }
}

export const ContractsDashboard = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedContractsDashboard);
