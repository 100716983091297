import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box } from '@og-pro/ui';

import { getReviewGeneralInformationData } from './selectors';
import { getFiscalYearSelectOptions, getInvitedUsersSelectOptions } from '../../../../../selectors';
import { UserProfilePicture } from '../../../../../../components';
import { getIsRequisitionUsingFMS, getRequisitionJS } from '../../../../../../selectors/govApp';

export const ReviewGeneralInformation = () => {
    const sharedStyles = require('../index.scss');

    const { creator, reviewGroup } = useSelector(getRequisitionJS);
    const data = useSelector(getReviewGeneralInformationData);
    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    const requestorData = useSelector(getInvitedUsersSelectOptions).find(
        (requestor) => requestor.value === data.requestorId
    );

    const fiscalYearSelectOptions = useSelector(getFiscalYearSelectOptions);

    return (
        <>
            <Box className="row" marginBottom={3}>
                <div className={classNames('col-sm-9 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Name of Request </h5>
                    <span>{data.descriptionOfRequest || 'None'}</span>
                </div>
                <div className={classNames('col-sm-3 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Created On</h5>
                    <span>{moment(data.createdAt).format('MM/DD/YYYY')}</span>
                </div>
            </Box>
            <Box className="row" marginBottom={3}>
                <div className={classNames('col-sm-12', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Summary of Request</h5>
                    <span>{data.summary || 'None'}</span>
                </div>
            </Box>
            <Box className="row" marginBottom={3}>
                <div className={classNames('col-sm-12', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Background</h5>
                    <span>{data.background || 'None'}</span>
                </div>
            </Box>
            <Box className="row" marginBottom={3}>
                <div className={classNames('col-md-4 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Requestor</h5>
                    <div className={sharedStyles.requestorContainer}>
                        <UserProfilePicture user={requestorData?.user} />
                        <span>{requestorData?.label}</span>
                    </div>
                </div>
                <div className={classNames('col-md-4 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Approval Group</h5>
                    <span>{reviewGroup.name}</span>
                </div>
                <div className={classNames('col-md-4 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Creator</h5>
                    <div className={sharedStyles.requestorContainer}>
                        <UserProfilePicture user={creator} />
                        <span>{creator.displayName}</span>
                    </div>
                </div>
            </Box>
            <div className="row">
                {hasFMS && (
                    <div className={classNames('col-md-4 col-xs-6', sharedStyles.field)}>
                        <h5 className={sharedStyles.label}>Expected Purchase Order Date</h5>
                        <span>{moment(data.expectedPurchaseOrderDate).format('MM/DD/YYYY')}</span>
                    </div>
                )}
                <div className={classNames('col-md-4 col-xs-6', sharedStyles.field)}>
                    <h5 className={sharedStyles.label}>Desired Delivery Date</h5>
                    <span>{moment(data.desiredDeliveryDate).format('MM/DD/YYYY')}</span>
                </div>
                <div className={classNames('col-md-4 col-xs-12', sharedStyles.field)}>
                    <h5 className={classNames(sharedStyles.label, sharedStyles.budgetYearLabel)}>
                        Fiscal Year
                    </h5>
                    <span className={sharedStyles.textMuted}>
                        {hasFMS
                            ? data.fiscalPeriod
                            : fiscalYearSelectOptions.find(
                                  (option) => option.value === data.fiscalPeriodObject
                              )?.label}
                    </span>
                </div>
            </div>
        </>
    );
};

ReviewGeneralInformation.propTypes = {
    data: PropTypes.shape({
        requestor: PropTypes.number,
        expectedPurchaseOrderDate: PropTypes.instanceOf(Date),
        desiredDeliveryDate: PropTypes.instanceOf(Date),
        descriptionOfRequest: PropTypes.string,
    }),
};
