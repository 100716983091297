import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { getFormSyncErrors } from 'redux-form';

import { AccountField } from './AccountField';
import { getRemainingAmount } from './helpers';
import { ACCOUNTS_SUM, DECIMAL_PLACES, MAX_ACCOUNT_SPLIT_FIELDS } from '../../constants';
import { accountFieldNames } from './AccountField/constants';
import { priceItemFieldNames } from '../../../../../../../constants';
import { getIsRequisitionUsingFMS } from '../../../../../../../../../selectors/govApp';
import { Button } from '../../../../../../../../../components';
import { dollarString } from '../../../../../../../../../Forms/normalizers';
import { useCoaConfigLookup } from '../../../../../../../../../lib/ogFinancials';
import { currencyFormatter } from '../../../../../../../../../../../shared_config/helpers';
import { formConfig } from '../../../../../form';
import { fieldNames } from '../../../../../constants';

const { AMOUNT } = accountFieldNames;
const { ACCOUNT_SPLIT_PRICE_ITEMS } = priceItemFieldNames;
const { PRICE_TABLE, PRICE_ITEMS } = fieldNames;

export const AccountFields = ({
    change,
    disabled,
    isDraft,
    extendedPrice,
    fieldName,
    fields,
    showFormValidation,
    priceItemIndex,
}) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const hasFMS = useSelector(getIsRequisitionUsingFMS);
    const { data: coaConfigLookupData } = useCoaConfigLookup();

    const [remaining, setRemaining] = useState(getRemainingAmount(fields, extendedPrice));

    if (!fields.length) {
        // Add one account field if none exist
        fields.push({ [AMOUNT]: extendedPrice.toString() });
    }

    const handleSetRemaining = () => {
        setRemaining(getRemainingAmount(fields, extendedPrice));
    };
    useEffect(() => {
        if (fields.length === 1) {
            // If there is only one account field, set the amount to the extended price
            dispatch(
                change(
                    `${fieldName}.${ACCOUNT_SPLIT_PRICE_ITEMS}[0].${AMOUNT}`,
                    dollarString(extendedPrice.toString(), {
                        maximumFractionDigits: DECIMAL_PLACES,
                    })
                )
            );
        }

        setRemaining(getRemainingAmount(fields, extendedPrice));
    }, [extendedPrice]);

    useEffect(
        () => setRemaining(getRemainingAmount(fields, extendedPrice)),
        [fields.length, ...fields.getAll().map((f) => f[AMOUNT])]
    );

    const addAccount = () => {
        fields.push({ tempId: uuidv4() }); // Add a new account field with a unique tempId so that the account number input component can be uniquely identified even if it doesn't come from the database
    };

    const removeAccount = (accountIndex) => {
        const newFields = [...fields.getAll()];
        newFields.splice(accountIndex, 1);

        if (newFields.length === 1) {
            newFields[0].amount = extendedPrice;
        }

        dispatch(change(`${fieldName}.${ACCOUNT_SPLIT_PRICE_ITEMS}`, newFields));
    };

    const formErrors = useSelector((state) => getFormSyncErrors(formConfig.form)(state));
    const hasAccountSumError =
        formErrors[PRICE_TABLE]?.[PRICE_ITEMS]?.[priceItemIndex]?.[ACCOUNTS_SUM];

    return (
        <>
            {fields.map((accountFieldName, accountIndex) => {
                const field = fields.get(accountIndex);
                return (
                    <div className={styles.row} key={field.id || field.tempId}>
                        <AccountField
                            change={change}
                            coaConfigLookupData={coaConfigLookupData}
                            disabled={disabled}
                            extendedPrice={extendedPrice}
                            field={field}
                            fieldName={accountFieldName}
                            hasSiblings={fields.length > 1}
                            index={accountIndex}
                            isDraft={isDraft}
                            removeAccount={() => removeAccount(accountIndex)}
                            setRemaining={handleSetRemaining}
                            showFormValidation={showFormValidation}
                        />
                    </div>
                );
            })}
            <div className={classNames(styles.row, styles.buttonContainer)}>
                {hasFMS && <div className={styles.emptyBlock} />}
                <div className={styles.splitAccountButtonContainer}>
                    <Button
                        disabled={disabled || fields.length === MAX_ACCOUNT_SPLIT_FIELDS}
                        onClick={addAccount}
                        qaTag="requisitionCreate-splitAccount"
                        tooltip={
                            fields.length === MAX_ACCOUNT_SPLIT_FIELDS
                                ? 'Max Accounts Reached'
                                : null
                        }
                    >
                        Split with Another Account
                    </Button>
                </div>
                <div
                    className={classNames(styles.remainingAmount, {
                        'has-error': hasAccountSumError,
                    })}
                >
                    {fields.length > 1 && (
                        <>
                            <span className={styles.boldText}>
                                Remaining:&nbsp;
                                <span className={remaining < 0 ? styles.redText : undefined}>
                                    {currencyFormatter(remaining.toString())}
                                </span>
                            </span>
                            of&nbsp;
                            {currencyFormatter(extendedPrice.toString())}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

AccountFields.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isDraft: PropTypes.bool,
    extendedPrice: PropTypes.number.isRequired,
    fieldName: PropTypes.string.isRequired,
    fields: PropTypes.shape({
        get: PropTypes.func.isRequired,
        getAll: PropTypes.func.isRequired,
        length: PropTypes.number.isRequired,
        map: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }).isRequired,
    showFormValidation: PropTypes.bool,
    priceItemIndex: PropTypes.number.isRequired,
};
