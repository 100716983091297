import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@og-pro/ui';
import { useSelector } from 'react-redux';
import { Close as CloseIcon, Warning as WarningIcon } from '@mui/icons-material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { CDSButton } from '../../../../components';
import { getProjectJS } from '../../selectors';
import { SuppliersNetworkModalForm } from './Form';
import { CustomGridLayout } from './CustomGridLayout';
import { fieldName, MAX_VENDOR_CONTACTS } from '../constants';
import { useInitialValues, useVendorSearch } from '../hooks';
import { SkeletonLoading } from './SkeletonLoading';

export const SearchByLocationRow = ({
    categories,
    disabled,
    onRemove,
    meta,
    notifiedUsers,
    // selectedValue is what we save, that's why it's stored one level above
    selectedValue,
    onSelectedValueChange,
    setError,
    setSavingBlocked,
    setTotal,
}) => {
    const [metaValue, setMetaValue] = meta;
    const [results, setResults] = useState({ count: 0, users: 0 });
    const project = useSelector(getProjectJS);
    const {
        initialValues,
        loaded,
        loading: initialValuesLoading,
    } = useInitialValues(metaValue.shouldFetchLocations);
    const { loading, searchFilterAndSet } = useVendorSearch({
        categories,
        setError,
        setResults,
        setTotal,
        notifiedUsers,
    });

    const onLocationsChange = (locations) => {
        onSelectedValueChange(locations);
    };

    // When this component renders and initialValues is computed (check the useInitialValues hook)
    // we need to take that initialValue as put it into the selected locations.
    // These selected locations is what triggers the search. So the idea is that the dialog opens,
    // if we have searchByLocation and initialValues we want to do an initial search to have something to show.
    useEffect(() => {
        if (loaded) {
            if (initialValues.length) {
                onSelectedValueChange(initialValues, project.wasPosted);
            }

            if (!initialValues.length || project.wasPosted) {
                // SearchByLocationRow appears by default, we want to hide it if
                // there's nothing stored or if the project was posted which means we show the read-only panel
                setMetaValue((v) => ({
                    ...v,
                    shouldFetchLocations: false,
                }));
                onRemove();
            }
        }
    }, [initialValues?.length, loaded]);

    useEffect(() => {
        searchFilterAndSet(selectedValue);
    }, [selectedValue?.length]);

    useEffect(() => {
        return () => {
            setResults({ count: 0, users: 0 });
            setSavingBlocked(false);
        };
    }, []);

    useEffect(() => {
        if (results.users > MAX_VENDOR_CONTACTS) {
            setSavingBlocked(true);
        } else {
            setSavingBlocked(false);
        }
    }, [results.users]);

    const styles = require('../index.scss');

    // the form will only get an initial value if the project is not posted or we are not dealing with stored values
    // this is because once the project is posted, whatever we have saved can not be removed and will instead
    // be shown in a different row (ReadOnlySearchByLocationsRow)
    const resolvedInitialValues = project.wasPosted ? [] : initialValues;

    return (
        <>
            {initialValuesLoading && <SkeletonLoading />}
            {!initialValuesLoading && (
                <CustomGridLayout
                    columns={[
                        <>
                            <SuppliersNetworkModalForm
                                disabled={disabled}
                                initialValues={{
                                    [fieldName]: resolvedInitialValues,
                                }}
                                loading={loading || disabled}
                                onLocationsChange={onLocationsChange}
                            />
                            {results?.users > MAX_VENDOR_CONTACTS && (
                                <Box
                                    alignItems="center"
                                    color={capitalDesignTokens.foundations.colors.terracotta600}
                                    display="flex"
                                >
                                    <Box>
                                        <WarningIcon fontSize="inherit" />
                                    </Box>
                                    <Box ml={1}>
                                        <Typography
                                            fontWeight={600}
                                            lineHeight={1}
                                            variant="bodyXSmall"
                                        >
                                            You may add up to 200 Vendor Contacts by location.
                                            Please narrow the search.
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </>,
                        loading ? (
                            <CircularProgress size="small" />
                        ) : (
                            <Box className={styles.tableCount}>{results?.users}</Box>
                        ),
                        loading ? (
                            <CircularProgress size="small" />
                        ) : (
                            <Box className={styles.tableCount}>{results?.count}</Box>
                        ),
                        <Box textAlign="right">
                            {onRemove && (
                                <Box
                                    aria-label="Remove search option"
                                    className={styles.errorDark}
                                    component={CDSButton}
                                    disabled={disabled}
                                    noPadding
                                    onClick={onRemove}
                                    qaTag="supplierNetworkModal-removeSearchOption"
                                    variant="text"
                                >
                                    <CloseIcon fontSize="small" />
                                </Box>
                            )}
                        </Box>,
                    ]}
                />
            )}
        </>
    );
};

SearchByLocationRow.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
    disabled: PropTypes.bool,
    meta: PropTypes.array.isRequired,
    notifiedUsers: PropTypes.shape({ ids: PropTypes.object, organizationIds: PropTypes.object }), // javascript Set
    onRemove: PropTypes.func,
    selectedValue: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelectedValueChange: PropTypes.func.isRequired,
    setSavingBlocked: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    setTotal: PropTypes.func.isRequired,
};
