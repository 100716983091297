import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CommentIcon } from '../../../containers/GovApp';
import { subsectionTypeNames } from '../../../../../shared_config/subsections';
import { sectionTypeNames } from '../../../../../shared_config/sections';
import { getSectionNumberingString } from '../../../../../shared_config/helpers';
import { DocxHeading } from '../..';

const { BODY } = subsectionTypeNames;

const { DIVIDER } = sectionTypeNames;

export class ProjectDetailTitle extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
        onlyTitle: PropTypes.bool,
        projectSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            manualNumber: PropTypes.string,
            sectionNumber: PropTypes.number.isRequired,
            section_type: PropTypes.string.isRequired,
            subsectionNumber: PropTypes.number,
        }).isRequired,
        projectSubsectionId: PropTypes.number.isRequired,
        title: PropTypes.string,
        showComments: PropTypes.bool,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
    };

    get sectionNumbering() {
        const {
            projectSection: { manualNumber, sectionNumber, subsectionNumber },
            useManualNumbering,
        } = this.props;

        return getSectionNumberingString({
            manualNumber,
            sectionNumber,
            subsectionNumber,
            useManualNumbering,
        });
    }

    get isDivider() {
        const {
            projectSection: { section_type: sectionType },
        } = this.props;
        return sectionType === DIVIDER;
    }

    renderDocx() {
        const { title, useManualNumbering, useSectionDividers } = this.props;

        // `<t />` is a custom tag that is replaced with a tabstop in the docx template
        const numberAndTitle = (
            <>
                {(useManualNumbering || useSectionDividers) && this.sectionNumbering}
                {(useManualNumbering || useSectionDividers) && <t />}
                {title}
            </>
        );

        return (
            <DocxHeading
                domElement={this.isDivider ? 'h2' : 'h3'}
                legacy={this.isDivider ? <h2>{numberAndTitle}</h2> : <h3>{numberAndTitle}</h3>}
                numbering={this.sectionNumbering}
                title={title}
                useSectionDividers={useSectionDividers}
            />
        );
    }

    render() {
        const { title, projectSection, projectSubsectionId, showComments, isDocx, onlyTitle } =
            this.props;

        const styles = require('./Title.scss');
        const id = `project-field-project_subsection_id.${projectSubsectionId}`;

        if (isDocx) {
            return this.renderDocx();
        }

        if (onlyTitle) {
            return (
                <h3 className="section-title" id={id}>
                    {title}
                </h3>
            );
        }

        let commentIcon;
        if (showComments) {
            commentIcon = (
                <div className={styles.commentIcon}>
                    <CommentIcon
                        iconLeft
                        projectSection={projectSection}
                        show={showComments}
                        subsectionType={BODY}
                    />
                </div>
            );
        }

        if (this.isDivider) {
            return (
                <h2 className="section-title section-division" id={id}>
                    {this.sectionNumbering}
                    &nbsp;
                    {title}
                    {commentIcon}
                </h2>
            );
        }

        return (
            <h3 className="section-title" id={id}>
                {this.sectionNumbering}
                &nbsp;
                {title}
                {commentIcon}
            </h3>
        );
    }
}
