import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Checkbox, Typography, Popover } from '@og-pro/ui';
import { ViewColumnOutlined as ViewColumnOutlinedIcon } from '@mui/icons-material';
import { tokens } from '@opengov/capital-style';

const Columns = ({ columns, onClickCheckBox }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Box>
            <Button
                aria-describedby={id}
                color="secondary"
                disabled={false}
                onClick={handleClick}
                qaTag="vendorSearch-columns"
                startIcon={<ViewColumnOutlinedIcon />}
                variant="text"
            >
                Columns
            </Button>

            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
                id={id}
                onClose={handleClose}
                open={open}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    pt={1}
                    px={2}
                    sx={{
                        border: `1px solid ${tokens.colors.colorGray600}`,
                        borderRadius: '4px',
                    }}
                    width="375px"
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        height="24px"
                        justifyContent="space-between"
                        mb={1}
                    >
                        <Typography variant="h5">Manage Columns</Typography>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap="6px"
                    px={2}
                    py={1}
                    sx={{
                        border: `1px solid ${tokens.colors.colorGray600}`,
                        borderRadius: '0px 0px 4px 4px',
                        borderWidth: '0px 1px 1px 1px',
                    }}
                    width="375px"
                >
                    {columns
                        .filter((column) => column.field !== 'legalName')
                        .map(
                            ({ columnName, field, headerName, hide }) =>
                                (columnName || headerName) && (
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                        key={field}
                                    >
                                        <Checkbox
                                            checked={!hide}
                                            color="primary"
                                            defaultChecked
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            onChange={() => onClickCheckBox(field)}
                                            sx={{ height: 14, padding: 0, width: 14 }}
                                        />
                                        <Typography sx={{ fontSize: '14px' }}>
                                            {columnName ?? headerName}
                                        </Typography>
                                    </Box>
                                )
                        )}
                </Box>
            </Popover>
        </Box>
    );
};

export default Columns;

Columns.propTypes = {
    columns: PropTypes.array,
    onClickCheckBox: PropTypes.func,
};
