import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { getGovernmentId } from '../../containers/GovApp/selectors';
import { Button, InputText, LoadingButton } from '..';
import { sendCustomEmail } from '../../actions/app';

const { BODY, SUBJECT } = fieldNames;

const mapStateToProps = (state) => {
    return {
        governmentId: getGovernmentId(state),
    };
};

const mapDispatchToProps = {
    sendCustomEmail,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedCustomEmailModal extends Component {
    static propTypes = {
        dirty: PropTypes.bool.isRequired,
        governmentId: PropTypes.number.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        data: PropTypes.shape({
            projectId: PropTypes.number,
            searchParams: PropTypes.any,
            userEmails: PropTypes.array,
            userIds: PropTypes.array,
            vendorListId: PropTypes.number,
        }).isRequired,
        sendCustomEmail: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            emailing: false,
            emailError: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    hideModal = (opts = {}) => {
        const { dirty, hideModal } = this.props;

        if (
            opts.force ||
            !dirty ||
            window.confirm('You have unsaved edits, are you sure you want to exit?') // eslint-disable-line no-alert
        ) {
            hideModal();
        }
    };

    sendCustomEmail = (formData) => {
        const { governmentId, data } = this.props;

        this.setState({ emailing: true });
        this.props
            .sendCustomEmail(governmentId, {
                ...formData,
                data,
            })
            .then(() => {
                this.setState({ emailing: false });
                this.hideModal({ force: true });
            })
            .catch((error) => {
                this.setState({ emailing: false, emailError: error.message });
            });
    };

    renderBody() {
        const { handleSubmit, invalid } = this.props;

        const { emailError, emailing } = this.state;

        return (
            <form onSubmit={handleSubmit(this.sendCustomEmail)}>
                <Field
                    component={InputText}
                    disabled={emailing}
                    hasFeedback={false}
                    label="Subject (Optional)"
                    name={SUBJECT}
                    placeholder="Enter email subject"
                    type="text"
                />
                <Field
                    component={InputText}
                    disabled={emailing}
                    hasFeedback={false}
                    label="Body"
                    name={BODY}
                    placeholder="Enter email body"
                    type="textarea"
                />
                <div className="text-right">
                    <Button bsSize="sm" disabled={emailing} onClick={this.hideModal}>
                        Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <LoadingButton
                        bsStyle="primary"
                        disabled={invalid || emailing}
                        icon="fa-envelope"
                        loading={emailing}
                        text="Send Email"
                        type="submit"
                    />
                </div>
                {emailError && <div className="text-center error-block">{emailError}</div>}
            </form>
        );
    }

    render() {
        return (
            <Modal onHide={this.hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Send an Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const CustomEmailModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedCustomEmailModal);
