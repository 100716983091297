import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Alert } from '../../..';
import { CriteriaItem } from '../CriteriaItem';
import { CriteriaNeedsReviewItem } from '../CriteriaNeedsReviewItem';

export class CriteriaList extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        fields: PropTypes.shape({
            get: PropTypes.func.isRequired,
            getAll: PropTypes.func.isRequired,
            length: PropTypes.number.isRequired,
            map: PropTypes.func.isRequired,
        }).isRequired,
        formKey: PropTypes.number.isRequired,
        handleInsert: PropTypes.func.isRequired,
        hasUpload: PropTypes.bool,
        isCriteriaBuilder: PropTypes.bool,
        isTextArea: PropTypes.bool,
        isSharedSectionForm: PropTypes.bool,
        listItemRefs: PropTypes.array,
        moveFieldDown: PropTypes.func,
        moveFieldUp: PropTypes.func,
        needsReviewOnly: PropTypes.bool,
        projectSection: PropTypes.object.isRequired,
        remove: PropTypes.func.isRequired,
        renderQuestionLogicIcon: PropTypes.func,
        showComments: PropTypes.bool,
        showValidation: PropTypes.bool,
        sortUsingManualNumbering: PropTypes.func,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useManualNumbering: PropTypes.bool,
        useRawDescription: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        hasUpload: false,
        isCriteriaBuilder: false,
        isTextArea: false,
        needsReviewOnly: false,
        showComments: false,
        showValidation: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawDescription: false,
    };

    renderItem(itemDetails, provided, snapshot) {
        const {
            disabled,
            handleInsert,
            hasUpload,
            isCriteriaBuilder,
            isSharedSectionForm,
            isTextArea,
            listItemRefs,
            moveFieldDown,
            moveFieldUp,
            needsReviewOnly,
            projectSection,
            remove,
            renderQuestionLogicIcon,
            showComments,
            showValidation,
            sortUsingManualNumbering,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
            useRawDescription,
        } = this.props;

        const { criteria, forceRenderDragIcon, member, originalIndex } = itemDetails;

        const isHidden = criteria.isHiddenByLogic || (needsReviewOnly && !criteria.needsReview);

        const itemRefsProps = {
            elRef: listItemRefs ? listItemRefs[originalIndex] : null,
            sortUsingManualNumbering,
        };

        if (isHidden) {
            // Hide the list item, but include drag props so it properly reorders
            return <div style={{ display: 'none' }} {...provided.dragHandleProps} />;
        }

        if (criteria.needsReview && !isCriteriaBuilder) {
            return (
                <CriteriaNeedsReviewItem
                    arrayName={member}
                    criteria={criteria}
                    disabled={disabled}
                    dragHandleProps={provided.dragHandleProps}
                    handleInsert={handleInsert}
                    isTextArea={isTextArea}
                    projectSection={projectSection}
                    showComment={showComments}
                    showValidation={showValidation}
                />
            );
        }

        return (
            <CriteriaItem
                {...itemRefsProps}
                arrayName={member}
                criteria={criteria}
                disabled={disabled}
                dragHandleProps={provided.dragHandleProps}
                forceRenderDragIcon={forceRenderDragIcon}
                handleInsert={handleInsert}
                hasUpload={hasUpload}
                index={originalIndex}
                isCriteriaBuilder={isCriteriaBuilder}
                isDragging={snapshot.isDragging}
                isLastItem={this.props.fields.length === originalIndex + 1}
                isSharedSectionForm={isSharedSectionForm}
                isTextArea={isTextArea}
                moveFieldDown={moveFieldDown}
                moveFieldUp={moveFieldUp}
                projectSection={projectSection}
                questionLogicIcon={
                    renderQuestionLogicIcon ? renderQuestionLogicIcon(criteria) : undefined
                }
                remove={remove}
                showComment={showComments}
                showValidation={showValidation}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
                useManualNumbering={useManualNumbering}
                useRawDescription={useRawDescription}
            />
        );
    }

    render() {
        const { fields, formKey, isSharedSectionForm, useManualNumbering } = this.props;

        const someCriteriaHasManualNumber = fields?.getAll()?.some((criteria) => {
            return !!criteria.manualNumber;
        });
        const forceRenderDragIcon =
            isSharedSectionForm && useManualNumbering && fields
                ? !someCriteriaHasManualNumber
                : false;

        return (
            <>
                {isSharedSectionForm && someCriteriaHasManualNumber && (
                    <div>
                        <Alert>
                            Because you are using manual numbers in this shared section, list items
                            will be ordered by the manual numbers you enter.
                        </Alert>
                    </div>
                )}
                {fields
                    .map((member, index) => {
                        /**
                         * Redux forms needs to know the exact index of the criteria. Basically, criteria is
                         * just an array, and you remove the index from the array to delete, or push onto
                         * the array to add.
                         */
                        const criteria = fields.get(index);
                        const key = `${formKey}.${index}`;

                        return {
                            criteria,
                            forceRenderDragIcon,
                            key,
                            member,
                            originalIndex: index,
                        };
                    })
                    .map((itemDetails, index) => {
                        const { key } = itemDetails;

                        return (
                            <Draggable draggableId={key} index={index} key={key}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                        {this.renderItem(itemDetails, provided, snapshot)}
                                    </div>
                                )}
                            </Draggable>
                        );
                    })}
            </>
        );
    }
}
