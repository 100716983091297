import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { Box, Typography } from '@og-pro/ui';

import {
    getQuestionLogicValueDisplayFromQuestion,
    questionLogicActionNames,
    questionLogicLinkableModelNames,
    questionLogicOperatorNamesDisplay,
} from '../../../../../../../../shared_config/questionLogics';
import { useOGTheme } from '../../../../ogThemeProvider';
import { CDSButton } from '../../../../../../components';

const { HIDE } = questionLogicActionNames;

const { PROJECT_SECTION } = questionLogicLinkableModelNames;

export const QuestionLogicItem = (props) => {
    const { isOGThemeEnabledForComponents } = useOGTheme();
    const styles = require('./index.scss');

    const onDelete = (e) => {
        const { deleteHandler, index } = props;

        e.stopPropagation();

        deleteHandler(index);
    };

    const onShowQuestionLogicModal = (e) => {
        e.stopPropagation();

        const { disabled, index, questionLogic, showQuestionLogicModal } = props;

        if (disabled) {
            return;
        }

        showQuestionLogicModal(questionLogic, index);
    };

    const {
        disabled,
        meta: { invalid },
        questionLogic: {
            action,
            linkable,
            linkableItem,
            operator,
            projectSection,
            logicableUpfrontQuestion,
            value,
        },
        showFormValidation,
    } = props;

    const showErrors = showFormValidation && invalid;
    const actionIcon = action === HIDE ? 'ban' : 'check';
    const linkableItemTitle =
        linkable === PROJECT_SECTION
            ? '(Entire section)'
            : `item: ${get(linkableItem, 'title') || 'Untitled'}`;

    if (isOGThemeEnabledForComponents) {
        return (
            <Box className={styles.itemContainer} onClick={onShowQuestionLogicModal}>
                <Box>
                    <Box>
                        <Typography variant="h4">
                            If {logicableUpfrontQuestion.title}
                            <span className={classnames(styles.logicCondition, styles[operator])}>
                                {questionLogicOperatorNamesDisplay[operator].toUpperCase()}
                            </span>
                            &quot;
                            {getQuestionLogicValueDisplayFromQuestion(
                                logicableUpfrontQuestion,
                                value
                            )}
                            &quot;
                        </Typography>
                        <Box className={styles.logicResult}>
                            <i className="fa fa-arrow-right" />
                            Then
                            <span className={classnames(styles.logicCondition, styles[action])}>
                                {action.toUpperCase()}
                            </span>
                            {get(projectSection, 'title') || 'Untitled Section'} {linkableItemTitle}
                        </Box>
                    </Box>
                    <Box mt={2} textAlign="right">
                        <Box
                            component={CDSButton}
                            disabled={disabled}
                            mr={2}
                            onClick={onShowQuestionLogicModal}
                            qaTag="templateEdit-editQuestionLogic"
                            size="small"
                            variant="text"
                        >
                            <i className="fa fa-edit" /> Edit
                        </Box>
                        <CDSButton
                            disabled={disabled}
                            onClick={onDelete}
                            qaTag="templateEdit-deleteQuestionLogic"
                            size="small"
                            variant="text"
                        >
                            <i className="fa fa-trash" /> Delete Automation
                        </CDSButton>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <ListGroupItem onClick={onShowQuestionLogicModal}>
            <div className={classnames(showErrors && 'text-danger')}>
                <div className="pull-right">
                    <span className={styles.deleteButton} onClick={onDelete}>
                        <i className="fa fa-lg fa-times text-danger" />
                    </span>
                </div>
                {showErrors && (
                    <span>
                        <i className="fa fa-exclamation-triangle" />
                        &nbsp;
                    </span>
                )}
                <div className={styles.condition}>
                    If <em>{logicableUpfrontQuestion.title}</em>
                    <strong className={styles.templateTag}>
                        {questionLogicOperatorNamesDisplay[operator].toUpperCase()}
                    </strong>
                    &quot;
                    {getQuestionLogicValueDisplayFromQuestion(logicableUpfrontQuestion, value)}
                    &quot;
                </div>
                <i className="fa fa-arrow-right text-primary" />
                &nbsp; Then
                <strong className={styles.templateTag}>
                    <i className={`fa fa-${actionIcon}`} /> {action.toUpperCase()}
                </strong>
                <em>
                    {get(projectSection, 'title') || 'Untitled Section'} {linkableItemTitle}
                </em>
            </div>
        </ListGroupItem>
    );
};

QuestionLogicItem.propTypes = {
    deleteHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    index: PropTypes.number.isRequired,
    meta: PropTypes.shape({
        invalid: PropTypes.bool.isRequired,
    }).isRequired,
    questionLogic: PropTypes.shape({
        action: PropTypes.string,
        linkable: PropTypes.string,
        linkableItem: PropTypes.shape({
            title: PropTypes.string,
        }).isRequired,
        operator: PropTypes.string,
        projectSection: PropTypes.shape({
            title: PropTypes.string,
        }).isRequired,
        logicableUpfrontQuestion: PropTypes.shape({
            data: PropTypes.shape({
                options: PropTypes.array,
            }),
            title: PropTypes.string.isRequired,
        }).isRequired,
        value: PropTypes.any,
    }).isRequired,
    showFormValidation: PropTypes.bool,
    showQuestionLogicModal: PropTypes.func.isRequired,
};
