import { downloadCSV } from '../helpers';
import { showSnackbar } from './notification';

export const SEARCH_VENDORS = 'public/vendor/SEARCH_VENDORS';
export const SEARCH_VENDORS_SUCCESS = 'public/vendor/SEARCH_VENDORS_SUCCESS';
export const SEARCH_VENDORS_FAIL = 'public/vendor/SEARCH_VENDORS_FAIL';

const getSort = (column, order = 'asc') => {
    switch (column) {
        case 'doingBusinessAs':
            return [
                {
                    doing_business_as_keyword: {
                        order,
                    },
                },
            ];
        case 'legalName':
            return [
                {
                    organization_name_keyword: {
                        order,
                    },
                },
            ];
        default:
            return [];
    }
};

const getSearchData = ({ searchObject, size, from, sortModel }) => {
    let data;

    if (searchObject?.searchText === '') {
        data = {
            query: {
                and: [],
            },
            size,
            from,
            sort: sortModel ? getSort(sortModel[0]?.colId || 'legalName', sortModel[0]?.sort) : [],
            track_total_hits: true,
        };
    } else {
        data = {
            query: {
                and: [
                    {
                        or: [
                            {
                                organization_name: searchObject.searchText,
                            },
                            {
                                doing_business_as: searchObject.searchText,
                            },
                            {
                                organization_city: searchObject.searchText,
                            },
                            {
                                organization_state: searchObject.searchText,
                            },
                            {
                                organization_zip_code: searchObject.searchText,
                            },
                        ],
                    },
                ],
            },
            size,
            from,
            sort: sortModel ? getSort(sortModel[0]?.colId, sortModel[0]?.sort) : [],
            track_total_hits: true,
        };
    }

    if (searchObject.categoryIds?.length > 0) {
        data.query.and.push({
            categories_ids: {
                operator: 'or',
                value: searchObject.categoryIds,
            },
        });
    }

    if (searchObject.certificationIds?.length > 0) {
        data.query.and.push({
            certification_ids: {
                operator: 'or',
                value: searchObject.certificationIds,
            },
        });
    }

    if (searchObject.ethnicities?.length > 0) {
        data.query.and.push({
            ethnicities: {
                operator: 'or',
                value: searchObject.ethnicities,
            },
        });
    }

    if (searchObject.vendorLists?.length > 0) {
        data.query.and.push({
            vendor_list_ids: {
                operator: 'or',
                value: searchObject.vendorLists,
            },
        });
    }

    return data;
};

export function searchVendors(governmentId, search, isPublic) {
    return (dispatch, getState, client) => {
        dispatch({ type: SEARCH_VENDORS });

        const data = getSearchData(search);

        return client
            .post(
                isPublic
                    ? `/government/${governmentId}/vendor-search/searchPublic`
                    : `/government/${governmentId}/vendor-search/searchInternal`,
                {
                    data,
                }
            )
            .then((response) => {
                dispatch({ type: SEARCH_VENDORS_SUCCESS, response });
                return response;
            })
            .catch((error) => {
                dispatch({ type: SEARCH_VENDORS_FAIL, error });
            });
    };
}

export function downloadVendorSearch(governmentId, search, isPublic) {
    return (dispatch, getState, client) => {
        const data = getSearchData(search);

        return client
            .post(
                isPublic
                    ? `/government/${governmentId}/vendor-search/downloadPublic`
                    : `/government/${governmentId}/vendor-search/downloadInternal`,
                {
                    data,
                }
            )
            .then((result) => {
                downloadCSV(result, `vendors-${Date.now()}.csv`);
            })
            .catch(() => {
                dispatch(showSnackbar('Error Downloading', { isError: true }));
            });
    };
}
