import React from 'react';
import { Box, Tooltip } from '@og-pro/ui';

export const ArrayCellRenderer = (params) => {
    if (!Array.isArray(params.value)) {
        return null;
    }

    const renderArray = () => {
        const text = params.value.filter((value) => value !== null).join(', ');
        return (
            <Box minWidth={0} overflow="hidden" textOverflow="ellipsis">
                <Tooltip title={text}>{text}</Tooltip>
            </Box>
        );
    };

    return params.data.expanded ? (
        <Box pt={0.5}>{renderArray()}</Box>
    ) : (
        <Box alignItems="center" display="flex" height="100%">
            {renderArray()}
        </Box>
    );
};
