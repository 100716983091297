import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getTimelines } from '../../../../../containers/GovApp/selectors';
import { TimelineConfiguration } from './TimelineConfiguration';
import { TimelineDates } from './TimelineDates';

export const SDv2Timeline = (props) => {
    const timelineData = useSelector((state) => getTimelines(state, props));
    const [viewMode, setViewMode] = useState(props.showDatesByDefault || false);

    const toggleViewMode = () => setViewMode(!viewMode);

    if (viewMode) {
        return (
            <TimelineDates
                change={props.change}
                disabled={props.disabled}
                isReverseAuction={props.isReverseAuction}
                isTemplate={props.isTemplate}
                project={props.project}
                showValidation={props.showValidation}
                timelineData={timelineData}
                timezone={props.timezone}
                toggleViewMode={toggleViewMode}
            />
        );
    }

    return (
        <TimelineConfiguration
            array={props.array}
            change={props.change}
            disabled={props.disabled}
            isReverseAuction={props.isReverseAuction}
            project={props.project}
            showValidation={props.showValidation}
            timelineData={timelineData}
            toggleViewMode={toggleViewMode}
        />
    );
};

SDv2Timeline.propTypes = {
    change: PropTypes.func.isRequired,
    array: PropTypes.object.isRequired,
    // form is used in the selector
    // eslint-disable-next-line
    form: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isReverseAuction: PropTypes.bool,
    isTemplate: PropTypes.bool,
    showDatesByDefault: PropTypes.bool,
    showValidation: PropTypes.bool,
    timezone: PropTypes.string,
    project: PropTypes.shape({
        id: PropTypes.number,
    }),
};
