import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getUserJS } from '../../../selectors';
import { LoadingError, Main } from '../../../../components';
import { getMetaTitle } from '../../../../helpers';
import { getRequisitionsPath } from '../../RequisitionsDashboard/RequisitionsDashboard/selectors';

export const AccountDetailsPublicRoute = () => {
    const params = useParams();
    const navigate = useNavigate();
    const user = useSelector((state) => getUserJS(state));
    const { government, organization } = user;

    const { accountNumber, fiscalPeriod, ogEntityId } = params;

    const title = `Redirecting to Requisition Account Details`;

    const image = organization && organization.logo ? organization.logo : null;

    const requisitionsPath = getRequisitionsPath({
        params: { ...params, governmentId: government.id },
    });

    useEffect(() => {
        navigate(`${requisitionsPath}/accounts/details/${accountNumber}/${fiscalPeriod}`);
    }, [accountNumber, fiscalPeriod, requisitionsPath]);

    if (ogEntityId !== organization.ogEntityId) {
        return (
            <LoadingError error="You are not authorized to view information of this government." />
        );
    }

    return (
        <Main>
            <h1 className="visually-hidden">{title}</h1>
            <Helmet>
                <title>{getMetaTitle(title)}</title>
                <meta content={image} property="og:image" />
                <meta content={title} property="og:title" />
            </Helmet>
        </Main>
    );
};
