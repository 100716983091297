import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Body } from './Body';
import { getDiscoverVendorData } from './helper';
import { getPreInviteEmailsSet } from './selectors';
import { getProjectJS } from '../selectors';
import { hideVendorDiscoveryModal } from '../../../actions/govProjects';
import { showSnackbar } from '../../../actions/notification';
import {
    LOAD_PRE_INVITE_LIST_SUCCESS,
    createOrUpdatePreInviteList,
    loadPreInviteList,
} from '../../../actions/govProjects/preInvite';
import { discoverVendors } from '../../../actions/vendorDiscovery';
import { inviteVendorsToPortal, vendorSearch } from '../../../actions/vendorList';

export const VendorDiscoveryModal = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(null);
    const [vendors, setVendors] = useState([]);
    const [vendorListSet, setVendorListSet] = useState(new Set());
    const [searchNum, setSearchNum] = useState(1);

    const project = useSelector(getProjectJS);
    const preInviteEmailsSet = useSelector(getPreInviteEmailsSet);

    const dispatch = useDispatch();
    const hideModal = () => dispatch(hideVendorDiscoveryModal());

    // Note: to avoid long wait times when fetching data from OpenAI, you can enable dummy data and
    // set state outside the loading event. Dummy data can be imported from here:
    // import { dummyVendors } from './dummyData';
    // setVendors(dummyVendors);
    useEffect(() => {
        setIsLoading(true);
        setLoadingError(null);
        setVendors([]);

        // Load pre-invite list so we can determine which vendors have already been invited
        dispatch(loadPreInviteList(project.id));

        const data = getDiscoverVendorData(project);
        dispatch(discoverVendors(project, data))
            .then((foundVendors) => {
                setVendors(foundVendors);
                setIsLoading(false);
            })
            .catch((error) => {
                setLoadingError(error.message);
                setIsLoading(false);
            });
    }, [searchNum]);

    useEffect(() => {
        if (vendors.length > 0) {
            // Fetches subscribed vendors by email so we can determine which vendors have already subscribed
            const emails = vendors.filter((v) => v.contactEmail).map((v) => v.contactEmail);
            dispatch(vendorSearch(project.government_id, { search: { emails } })).then(
                (response) => {
                    const foundEmails = response.results.map((user) => user.email);
                    setVendorListSet(new Set(foundEmails));
                }
            );
        }
    }, [vendors]);

    const retrySearch = () => setSearchNum(searchNum + 1);

    const addToPreInviteList = (emails) => {
        const emailDataSet = new Set([...preInviteEmailsSet, ...emails]);
        // Make API call to add vendors to pre-invite list
        dispatch(createOrUpdatePreInviteList(project.id, { emails: [...emailDataSet] }));

        // Update the pre invite list in state
        dispatch({ type: LOAD_PRE_INVITE_LIST_SUCCESS, result: [...emailDataSet] });
        const vendorText = emails.length === 1 ? 'Vendor' : 'Vendors';
        dispatch(showSnackbar(`${emails.length} ${vendorText} added to pre-invite list`));
    };
    const addToVendorList = (emails) => {
        const data = { emails };
        // Make API call to invite vendors
        dispatch(inviteVendorsToPortal(project.government_id, data));

        // Update vendor list in state
        setVendorListSet(new Set([...vendorListSet, ...emails]));
    };

    const styles = require('./index.scss');

    return (
        <Modal bsSize="lg" onHide={hideModal} show>
            <Modal.Body>
                <div className={styles.closeButtonContainer}>
                    <button className="close" onClick={hideModal} type="button">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <Body
                    addToPreInviteList={addToPreInviteList}
                    addToVendorList={addToVendorList}
                    isLoading={isLoading}
                    loadingError={loadingError}
                    preInviteEmailsSet={preInviteEmailsSet}
                    project={project}
                    retrySearch={retrySearch}
                    vendorListSet={vendorListSet}
                    vendors={vendors}
                />
            </Modal.Body>
        </Modal>
    );
};
