import { showSnackbar } from './notification';
import { resourceManager, serializeCriteriaAndSectionDescriptions } from '../helpers';

export const CHECK_OUT_ITEM = 'templateSections/CHECK_OUT_ITEM';
export const CHECK_OUT_ITEM_FAIL = 'templateSections/CHECK_OUT_ITEM_FAIL';
export const CHECK_OUT_ITEM_SUCCESS = 'templateSections/CHECK_OUT_ITEM_SUCCESS';

export const COPY_ITEM = 'templateSections/COPY_ITEM';
export const COPY_ITEM_FAIL = 'templateSections/COPY_ITEM_FAIL';
export const COPY_ITEM_SUCCESS = 'templateSections/COPY_ITEM_SUCCESS';

export const DELETE_ITEM = 'templateSections/DELETE_ITEM';
export const DELETE_ITEM_FAIL = 'templateSections/DELETE_ITEM_FAIL';
export const DELETE_ITEM_SUCCESS = 'templateSections/DELETE_ITEM_SUCCESS';

export const LOAD_ITEM = 'templateSections/LOAD_ITEM';
export const LOAD_ITEM_FAIL = 'templateSections/LOAD_ITEM_FAIL';
export const LOAD_ITEM_SUCCESS = 'templateSections/LOAD_ITEM_SUCCESS';

export const LOAD_LIST = 'templateSections/LOAD_LIST';
export const LOAD_LIST_FAIL = 'templateSections/LOAD_LIST_FAIL';
export const LOAD_LIST_SUCCESS = 'templateSections/LOAD_LIST_SUCCESS';

export const LOAD_TEMPLATE_SECTION_PROJECTS = 'templateSections/LOAD_TEMPLATE_SECTION_PROJECTS';

export const RESET_EDIT = 'templateSections/RESET_EDIT';
export const RESET_LIST = 'templateSections/RESET_LIST';

export const UPDATE_ITEM = 'templateSections/UPDATE_ITEM';
export const UPDATE_ITEM_FAIL = 'templateSections/UPDATE_ITEM_FAIL';
export const UPDATE_ITEM_SUCCESS = 'templateSections/UPDATE_ITEM_SUCCESS';

const serializeTemplateSectionData = (data) => {
    const serializedCriteriaAndSectionDescriptions = serializeCriteriaAndSectionDescriptions(data);

    return {
        ...data,
        ...serializedCriteriaAndSectionDescriptions,
    };
};

export const checkOutTemplateSection = (templateSectionId, isCheckingOut) => {
    return resourceManager({
        method: 'put',
        url: `/sections/templates/${templateSectionId}/check-out`,
        requestOptions: { data: { isCheckingOut } },
        onStart: ({ dispatch }) => dispatch({ type: CHECK_OUT_ITEM, templateSectionId }),
        onSuccess: ({ result, dispatch }) => {
            const status = isCheckingOut ? 'checked out' : 'checked back in';
            dispatch(showSnackbar(`Shared section ${status}!`));
            dispatch({ type: CHECK_OUT_ITEM_SUCCESS, result, templateSectionId });
        },
        onFailure: ({ error, dispatch }) => {
            dispatch({ type: CHECK_OUT_ITEM_FAIL, error, templateSectionId });
        },
    });
};

export const copyTemplateSection = (templateSectionId, onSuccess) => {
    return resourceManager({
        method: 'post',
        url: `/sections/templates/${templateSectionId}/copy`,
        onStart: ({ dispatch }) => dispatch({ type: COPY_ITEM }),
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Shared section copied!'));
            dispatch({ type: COPY_ITEM_SUCCESS, result });
            if (onSuccess) {
                onSuccess(result);
            }
        },
        onFailure: ({ error, dispatch }) => dispatch({ type: COPY_ITEM_FAIL, error }),
    });
};

export const createTemplateSection = (data) => {
    return resourceManager({
        method: 'post',
        requestOptions: { data },
        url: '/sections/templates',
    });
};

export const deleteTemplateSection = (templateSectionId, onSuccess) => {
    return resourceManager({
        method: 'del',
        url: `/sections/templates/${templateSectionId}`,
        onStart: ({ dispatch }) => dispatch({ type: DELETE_ITEM }),
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Shared section deleted'));
            dispatch({ type: DELETE_ITEM_SUCCESS, result });
            if (onSuccess) {
                onSuccess(result);
            }
        },
        onFailure: ({ error, dispatch }) => dispatch({ type: DELETE_ITEM_FAIL, error }),
    });
};

export const loadTemplateSection = (templateSectionId) => {
    return resourceManager({
        method: 'get',
        url: `/sections/templates/${templateSectionId}`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_ITEM }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_ITEM_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_ITEM_FAIL, error }),
    });
};

export const loadTemplateSectionProjects = (templateSectionId) => {
    return resourceManager({
        method: 'get',
        url: `/sections/templates/${templateSectionId}/projects`,
        onSuccess: ({ result, dispatch }) => {
            return dispatch({ type: LOAD_TEMPLATE_SECTION_PROJECTS, result });
        },
    });
};

export const loadTemplateSectionsList = () => {
    return resourceManager({
        method: 'get',
        url: '/sections/templates',
        onStart: ({ dispatch }) => dispatch({ type: LOAD_LIST }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_LIST_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_LIST_FAIL, error }),
    });
};

export function resetTemplateSectionEdit() {
    return { type: RESET_EDIT };
}

export function resetTemplateSectionsList() {
    return { type: RESET_LIST };
}

export const updateTemplateSection = (templateSectionId, data) => {
    return resourceManager({
        method: 'put',
        requestOptions: { data: serializeTemplateSectionData(data) },
        url: `/sections/templates/${templateSectionId}`,
        onStart: ({ dispatch }) => dispatch({ type: UPDATE_ITEM }),
        onSuccess: ({ result, dispatch }) => {
            dispatch(showSnackbar('Shared section saved'));
            dispatch({ type: UPDATE_ITEM_SUCCESS, result });
        },
        onFailure: ({ error, dispatch }) => dispatch({ type: UPDATE_ITEM_FAIL, error }),
    });
};
