import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../components';

export const DefaultSelectInputLabel = ({ selectedCategoriesCount, showModal, label }) => {
    const hasSelections = selectedCategoriesCount > 0;

    const styles = require('../shared.scss');
    const icon = hasSelections ? 'edit' : 'plus';
    const editText = hasSelections ? 'Edit' : 'Add';

    return (
        <>
            <b>{label}</b>
            <Button
                bsStyle="link"
                className={styles.editIcon}
                onClick={showModal}
                qaTag="categorySelectInput-edit"
                zeroPadding
            >
                <i className={`fa fa-${icon}`} /> {editText}
            </Button>
        </>
    );
};

DefaultSelectInputLabel.defaultProps = {
    selectedCategoriesCount: 0,
};

DefaultSelectInputLabel.propTypes = {
    selectedCategoriesCount: PropTypes.number,
    label: PropTypes.string.isRequired,
    showModal: PropTypes.func.isRequired,
};
