import React from 'react';
import { Box } from '@og-pro/ui';

export const ExpandableArrayCellRenderer = (params) => {
    if (!Array.isArray(params.value)) {
        return null;
    }

    return params.data.expanded ? (
        <Box
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            gap={1}
            height="100%"
            justifyContent="center"
        >
            {params.value.map((value) => {
                return (
                    <Box key={value} minHeight="40px">
                        {value}
                    </Box>
                );
            })}
        </Box>
    ) : (
        <Box
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            gap={1}
            height="100%"
            justifyContent="center"
        >
            {params.value[0]}
        </Box>
    );
};
