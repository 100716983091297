const {
    ArrayCellRenderer,
    ExpandableArrayCellRenderer,
    VendorNameCellRenderer,
    WebsiteCellRenderer,
} = require('../AgGridReact/renderers');
const { TextCellRenderer } = require('./cellRenderers/TextCellRenderer');

export const DEFAULT_PAGE_SIZE = 10;
export const CACHE_BLOCK_SIZE = 30;

export const publicColumns = [
    {
        checkboxSelection: true,
        pinned: 'left',
        headerName: undefined,
        suppressMenu: true,
        width: 55,
    },
    {
        columnName: 'Legal Name & Rating (Powered by Procurated)',
        headerName: 'Vendor Legal Name & Rating (Powered by Procurated)',
        field: 'legalName',
        pinned: 'left',
        cellRendererFramework: VendorNameCellRenderer,
        width: 310,
        sortable: true,
    },
    {
        columnName: 'DBA',
        headerName: 'Doing Business As (DBA) Name',
        field: 'doingBusinessAs',
        width: 245,
        sortable: true,
        cellRendererFramework: TextCellRenderer,
    },
    {
        headerName: 'Subscriber Name',
        field: 'subscriberName',
        cellRendererFramework: ExpandableArrayCellRenderer,
    },
    {
        headerName: 'Subscriber Email',
        field: 'subscriberEmail',
        cellRendererFramework: ExpandableArrayCellRenderer,
        width: 260,
    },
    {
        headerName: 'Subscriber Phone',
        field: 'subscriberPhone',
        cellRendererFramework: ExpandableArrayCellRenderer,
    },
    {
        headerName: 'Website',
        field: 'website',
        cellRendererFramework: WebsiteCellRenderer,
        width: 300,
    },
    { headerName: 'Company Phone', field: 'companyPhone', cellRendererFramework: TextCellRenderer },
    {
        headerName: 'Address',
        field: 'address',
        cellRendererFramework: ArrayCellRenderer,
        width: 350,
    },
    { headerName: 'Languages', field: 'languages', cellRendererFramework: TextCellRenderer },
    {
        headerName: 'Verified Certifications',
        field: 'verifiedCertifications',
        cellRendererFramework: ArrayCellRenderer,
    },
    {
        headerName: 'Self-Reported Certifications',
        field: 'selfReportedCertifications',
        cellRendererFramework: ArrayCellRenderer,
        width: 225,
    },
    {
        headerName: 'NIGP Category Codes',
        field: 'nigpCategoryCodes',
        cellRendererFramework: ExpandableArrayCellRenderer,
    },
    {
        headerName: 'NAICS Category Codes',
        field: 'naicsCategoryCodes',
        cellRendererFramework: ExpandableArrayCellRenderer,
    },
    {
        headerName: 'UNSPSC Category Codes',
        field: 'unspscCategoryCodes',
        cellRendererFramework: ExpandableArrayCellRenderer,
    },
    {
        headerName: 'Ethnicity',
        field: 'ethnicity',
        cellRendererFramework: ArrayCellRenderer,
    },
    {
        headerName: 'Lists',
        field: 'vendorLists',
        cellRendererFramework: ArrayCellRenderer,
    },
];

export const internalColumns = [
    ...publicColumns,
    { headerName: 'Business Type', field: 'businessType', cellRendererFramework: TextCellRenderer },
    {
        headerName: 'Billing Address',
        field: 'billingAddress',
        cellRendererFramework: ArrayCellRenderer,
    },
    {
        headerName: 'State of Incorporation',
        field: 'stateOfIncorporation',
        cellRendererFramework: TextCellRenderer,
    },
    { headerName: 'EIN', field: 'ein', cellRendererFramework: TextCellRenderer },
    { headerName: 'UEI', field: 'uei', cellRendererFramework: TextCellRenderer },
    { headerName: 'DUNS', field: 'duns', cellRendererFramework: TextCellRenderer },
    {
        headerName: 'OpenGov Supplier ID',
        field: 'openGovSupplierId',
        cellRendererFramework: TextCellRenderer,
    },
];
