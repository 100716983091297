import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { VendorPortalInviteModal } from '../VendorPortalInviteModal';
import { Button, SectionTitle, UnderlineNav } from '../../../../components';

export default class VendorManagementHeader extends PureComponent {
    static propTypes = {
        governmentId: PropTypes.number.isRequired,
        pathname: PropTypes.string.isRequired,
        portalUrl: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showInviteModal: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { governmentId, pathname, portalUrl } = this.props;

        const { showInviteModal } = this.state;

        const basePath = `/governments/${governmentId}/vendors`;
        const navItems = [
            {
                label: 'All Vendors',
                link: basePath,
                active: pathname === basePath,
                type: 'all-vendors-menu-item',
            },
            {
                label: 'Vendor Lists',
                link: `${basePath}/lists`,
                active: pathname.includes('vendors/lists'),
                type: 'vendor-lists-menu-item',
            },
            {
                label: 'Vendor Certifications',
                link: `${basePath}/certifications`,
                active: pathname.includes('vendors/certifications'),
                type: 'vendor-certifications-menu-item',
            },
            {
                label: 'Invited Vendors',
                link: `${basePath}/invited`,
                active: pathname.includes('vendors/invited'),
                type: 'vendor-invited-menu-item',
            },
        ];

        const renderVendorInstructions = (
            <p>
                List of vendors subscribed to sourcing opportunities
                <br />
                Vendors can subscribe by visiting&nbsp;
                <a href={portalUrl} rel="noreferrer noopener" target="_blank">
                    {portalUrl}
                </a>
                &nbsp;and clicking the &quot;Subscribe&quot; button
            </p>
        );

        return (
            <>
                <h1 className="visually-hidden">Vendor Management</h1>
                <SectionTitle info={renderVendorInstructions} title="Vendor Management" />
                <div className="text-right">
                    <Button
                        bsStyle="primary"
                        onClick={() => this.setState({ showInviteModal: true })}
                        qaTag="vendorManagementHeader-inviteVendors"
                    >
                        <i className="fa fa-envelope" /> Invite Vendors
                    </Button>
                </div>
                <UnderlineNav className={this.styles.nav}>
                    {navItems.map(({ active, label, link, type }) => (
                        <UnderlineNav.NavItem
                            className={classnames({ active })}
                            exact
                            key={type}
                            to={link}
                        >
                            {label}
                        </UnderlineNav.NavItem>
                    ))}
                </UnderlineNav>
                {showInviteModal && (
                    <VendorPortalInviteModal
                        hideModal={() => this.setState({ showInviteModal: false })}
                    />
                )}
            </>
        );
    }
}
