import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { OpenBidsForm } from './OpenBidsForm';
import { getSearchResults } from './selectors';
import connectData from '../../ConnectData';
import { getUserJS } from '../../selectors';
import { loadPublicOpenProjects, searchProjects } from '../../../actions/searchProjects';
import {
    Button,
    LoadingSpinner,
    LoadingError,
    SectionTitle,
    Main,
    TourButton,
} from '../../../components';
import { ProjectList } from '../../../components/PublicApp';

function fetchData(getState, dispatch) {
    return dispatch(loadPublicOpenProjects());
}

const mapStateToProps = (state) => {
    return {
        projects: getSearchResults(state),
        searchError: state.searchProjects.get('searchError'),
        searching: state.searchProjects.get('searching'),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    loadPublicOpenProjects,
    searchProjects,
};

// @connectData
// @connect
class ConnectedOpenBids extends Component {
    static propTypes = {
        loadPublicOpenProjects: PropTypes.func.isRequired,
        projects: PropTypes.arrayOf(
            PropTypes.shape({
                government: PropTypes.shape({
                    code: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired
        ),
        searchError: PropTypes.string,
        searching: PropTypes.bool,
        searchProjects: PropTypes.func.isRequired,
        user: PropTypes.shape({
            email: PropTypes.string,
        }).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    handleSubmit = (formData) => {
        const categoryData = formData.categories || [];
        const stateData = formData.states || [];

        if (categoryData.length === 0 && stateData.length === 0) {
            return this.props.loadPublicOpenProjects();
        }

        const data = {
            ...formData,
            categories: categoryData.map((cat) => cat.id),
            states: stateData.map((state) => state.value),
        };
        return this.props.searchProjects(data, { limit: 1000 }); // Arbitrary large number to get all results. Pagination is handled by the table.
    };

    projectPath = (project) => {
        return `/portal/${project.government.code}/projects`;
    };

    // Only show org search to ProcureNow & OpenGov accounts or in non-prod environments
    renderSearchByOrganizationLink() {
        const { user } = this.props;

        const isOpenGovUser =
            !!user.email.match(/@procurenow\.com$/) || !!user.email.match(/@opengov\.com$/);
        if (
            !isOpenGovUser &&
            (__PROD__ || __SANDBOX__ || __STAGING__ || __PERF__ || __INT__ || __RDEV__)
        ) {
            return null;
        }

        return (
            <Button bsStyle="link" to="/portal">
                Search by Organization [Internal Only]
            </Button>
        );
    }

    renderProjects() {
        const { projects, searchError, searching } = this.props;

        if (searching) {
            return <LoadingSpinner />;
        }

        if (searchError) {
            return <LoadingError error={searchError} />;
        }

        return (
            <ProjectList includeLogo projectPath={this.projectPath} projects={projects} showOrg />
        );
    }

    renderSideBar() {
        const { searching } = this.props;

        return (
            <div className={this.styles.sideBar}>
                <Panel className="text-center">
                    <Panel.Body>
                        <div className="row">
                            <div className="col-xs-offset-3 col-xs-6 col-sm-offset-4 col-sm-4 col-md-offset-1 col-md-10">
                                <img
                                    alt="OpenGov logo"
                                    className={`img-responsive ${this.styles.logo}`}
                                    src="https://assets.procurement.opengov.com/assets/opengov/opengov_emblem_blue.svg"
                                />
                            </div>
                        </div>
                        <h2 className={this.styles.name}>
                            OpenGov
                            <br />
                            Procurement Portal
                        </h2>
                        <p className="text-muted">
                            <em>
                                List of open opportunities on the
                                <br />
                                OpenGov Procurement Platform
                            </em>
                        </p>
                        <div className="text-left">
                            <OpenBidsForm onSubmit={this.handleSubmit} searching={searching} />
                        </div>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }

    render() {
        return (
            <Main className="row">
                <h1 className="visually-hidden">Open Bids</h1>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-xs-3 col-sm-3 col-md-3">
                            <div className={this.styles.tourButtonContainer}>
                                <TourButton
                                    className="tutorial-btn"
                                    qaTag="vendorOpenbidsList-tour"
                                    text="Show Tutorial"
                                />
                            </div>
                        </div>
                        <div className="col-xs-9 col-sm-9 col-md-9 text-right">
                            {this.renderSearchByOrganizationLink()}
                        </div>
                    </div>
                </div>

                <div className="col-md-3">{this.renderSideBar()}</div>
                <div className="col-md-9">
                    <Panel className={this.styles.projectsContainer}>
                        <Panel.Body>
                            <SectionTitle title="OpenGov Current Opportunities" />
                            {this.renderProjects()}
                        </Panel.Body>
                    </Panel>
                </div>
            </Main>
        );
    }
}

export const OpenBids = compose(
    connectData(fetchData),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedOpenBids);
