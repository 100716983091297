import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Box } from '@og-pro/ui';

import { qaTagName } from './constants';
import { LoadingDisplay } from './LoadingDisplay';
import { VendorCard } from './VendorCard';
import { Button, LoadingSpinner } from '../../../components';
import { projectStatusesDict } from '../../../../../shared_config/projects';

export const Body = ({
    addToPreInviteList,
    addToVendorList,
    isLoading,
    loadingError,
    preInviteEmailsSet,
    project,
    retrySearch,
    vendorListSet,
    vendors,
}) => {
    const styles = require('./index.scss');
    if (isLoading) {
        return (
            <Box mb={2.5} mt={2.5}>
                <LoadingSpinner noPadding />
                <LoadingDisplay project={project} />
            </Box>
        );
    }

    if (loadingError) {
        return (
            <div className={styles.loadingErrorContainer}>
                <Box mb={2.5}>
                    <h3>Error Generating Data</h3>
                    <div className={styles.loadingErrorMessage}>{loadingError}</div>
                </Box>
                <Button bsStyle="link" onClick={retrySearch} qaTag={`${qaTagName}-tryAgainError`}>
                    <i className="fa fa-repeat" /> Try Again
                </Button>
            </div>
        );
    }

    if (vendors.length === 0) {
        return (
            <div className={styles.loadingErrorContainer}>
                <Box mb={2.5}>
                    <h3>No results found :(</h3>
                    <div className={styles.loadingErrorMessage}>
                        There was likely an issue generating your search data
                    </div>
                </Box>
                <Button
                    bsStyle="link"
                    onClick={retrySearch}
                    qaTag={`${qaTagName}-tryAgainNoResults`}
                >
                    <i className="fa fa-repeat" /> Try Again
                </Button>
            </div>
        );
    }

    const isPreInviteOpen = !project.wasPosted && project.status !== projectStatusesDict.CLOSED;
    const allEmails = vendors
        .filter(({ contactEmail }) => contactEmail)
        .map(({ contactEmail }) => contactEmail);

    return (
        <>
            <div className={styles.vendorsMenu}>
                <h4 className={styles.vendorsMenuTitle}>AI Recommended Vendors</h4>
                <Button
                    bsSize="sm"
                    bsStyle="link"
                    onClick={retrySearch}
                    qaTag={`${qaTagName}-redoSearch`}
                >
                    <i className="fa fa-fw fa-repeat" /> Redo Search
                </Button>
                &nbsp;
                <Button
                    bsSize="sm"
                    onClick={() => addToVendorList(allEmails)}
                    qaTag={`${qaTagName}-addAllVendorList`}
                >
                    Add All to Vendor List
                </Button>
                {isPreInviteOpen && (
                    <>
                        &nbsp;&nbsp;
                        <Button
                            bsSize="sm"
                            bsStyle="primary"
                            onClick={() => addToPreInviteList(allEmails)}
                            qaTag={`${qaTagName}-preInviteAll`}
                        >
                            Pre-Invite All
                        </Button>
                    </>
                )}
            </div>
            <ListGroup>
                {vendors.map((vendor, index) => (
                    <VendorCard
                        addToPreInviteList={addToPreInviteList}
                        addToVendorList={addToVendorList}
                        isPreInviteOpen={isPreInviteOpen}
                        isPreInvited={preInviteEmailsSet.has(vendor.contactEmail)}
                        isVendorList={vendorListSet.has(vendor.contactEmail)}
                        key={index}
                        timeout={index * 600 + 300}
                        vendor={vendor}
                    />
                ))}
            </ListGroup>
        </>
    );
};

Body.propTypes = {
    addToPreInviteList: PropTypes.func.isRequired,
    addToVendorList: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    loadingError: PropTypes.string,
    preInviteEmailsSet: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    retrySearch: PropTypes.func.isRequired,
    vendorListSet: PropTypes.object.isRequired,
    vendors: PropTypes.array.isRequired,
};
