import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';

import { useSelector } from 'react-redux';

import {
    borderColor,
    requestsNeedingAttentionVisualization,
    visualizationFontFamily,
} from './constants';
import { useRenderChart } from './hooks/useRenderChart';
import { useQueryParam } from '../../../../../hooks';
import { getIsGroupAdminOrLeader } from '../selectors';
import { getInitialParams } from '../helpers';
import { chartInteractionParamsDict } from './helpers/chartInteractions';

const { TYPE } = chartInteractionParamsDict;

export const RequestsNeedingAttention = ({ data }) => {
    const [params, , setQueryParams] = useQueryParam();

    const isAdminOrLeader = useSelector((state) => getIsGroupAdminOrLeader(state));

    const initialParams = getInitialParams(isAdminOrLeader);

    const labelFormatter = (name, y, color, yFontSize, nameFontSize) => {
        return `
        <div style="display:flex; width: 100%; flex-direction: column; gap: 4px; text-align: center; justify-content: center;">
            <div style="font-size: ${yFontSize}px; color: ${color};">${y}</div>
            <div style="font-size: ${nameFontSize}px; font-weight: 400">${name}</div>
        </div>
        `;
    };

    const renderChart = () => {
        Highcharts.chart(requestsNeedingAttentionVisualization, {
            chart: {
                type: 'pie',
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                borderWidth: 1,
                borderColor,
                height: 200,
                events: {
                    // Workaround to center legend items when legend spans into multiple lines
                    // https://github.com/highcharts/highcharts/issues/13817#issuecomment-653483646
                    /* eslint-disable no-underscore-dangle */
                    render() {
                        const chart = this;
                        const legend = chart.legend;

                        const lines = [];
                        let itemY = null;
                        let index = 0;
                        const legendWidth = legend.legendWidth;

                        legend.allItems.forEach(function (item) {
                            if (item._legendItemPos[1] === itemY) {
                                lines[index].items.push(item);
                                lines[index].width += item.itemWidth;
                            } else {
                                itemY = item._legendItemPos[1];
                                index =
                                    lines.push({
                                        items: [item],
                                        y: itemY,
                                        width: item.itemWidth,
                                    }) - 1;
                            }
                        });

                        lines.forEach(function (line) {
                            let offset;
                            const totalItems = line.items.length;

                            if (totalItems === 2) {
                                // Add extra separation for lines with 2 items
                                const extraSpacing = 30;
                                offset = (legendWidth - line.width - extraSpacing) / 2;
                                line.items.forEach(function (item, itemIndex) {
                                    item.legendGroup.attr({
                                        translateX:
                                            offset +
                                            item._legendItemPos[0] +
                                            itemIndex * extraSpacing,
                                    });
                                });
                            } else {
                                // Center items for other lines
                                offset = (legendWidth - line.width) / 2;
                                line.items.forEach(function (item) {
                                    item.legendGroup.attr({
                                        translateX: offset + item._legendItemPos[0],
                                    });
                                });
                            }
                        });
                    },
                    load() {
                        // Add data-qa attributes to legend items
                        const chart = this;
                        chart.series[0].points.forEach((point, index) => {
                            const legendItem = chart.legend.allItems[index].legendGroup.element;
                            legendItem.setAttribute(
                                'data-qa',
                                `requestsNeedingAttention-${point.name}`
                            );
                        });
                    },
                },
            },
            title: {
                text: 'Requests Needing Attention',
                align: 'left',
                y: 20,
                x: 5,
                style: {
                    fontWeight: 500,
                    fontSize: '16px',
                    fontFamily: visualizationFontFamily,
                },
            },
            plotOptions: {
                pie: {
                    visible: false,
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                    point: {
                        events: {
                            legendItemClick() {
                                const clickedPoint = this;
                                const onlyVisible =
                                    clickedPoint.series.points.filter((point) => point.visible)
                                        .length === 1 && clickedPoint.visible;

                                // If clicked element is the only visible one, de-select it
                                setTimeout(() => {
                                    setQueryParams({
                                        ...initialParams,
                                        [TYPE]: onlyVisible ? null : clickedPoint.options.value,
                                    });
                                }, 0);

                                return false;
                            },
                        },
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    type: 'pie',
                    name: 'Requests Needing Attention',
                    innerSize: '50%',
                    data,
                },
            ],
            legend: {
                align: 'center',
                verticalAlign: 'middle',
                layout: 'horizontal',
                itemMarginTop: 10,
                itemStyle: {
                    fontFamily: visualizationFontFamily,
                    textAlign: 'center',
                },
                x: 10,
                useHTML: true,
                symbolPadding: 0,
                itemDistance: 70,
                symbolWidth: 0,
                symbolHeight: 0,
                padding: 0,
                squareSymbol: false,
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 300,
                        },
                        chartOptions: {
                            title: {
                                style: {
                                    fontSize: '16px',
                                },
                            },
                            chart: {
                                height: 260,
                            },
                            legend: {
                                labelFormatter() {
                                    const { name, y, color } = this;
                                    return labelFormatter(name, y, color, 32, 12);
                                },
                                y: 10,
                            },
                        },
                    },
                    {
                        condition: {
                            maxWidth: 485,
                        },
                        chartOptions: {
                            legend: {
                                itemDistance: 30,
                            },
                        },
                    },
                    {
                        condition: {
                            minWidth: 301,
                        },
                        chartOptions: {
                            title: {
                                style: {
                                    fontSize: '14px',
                                },
                            },
                            legend: {
                                labelFormatter() {
                                    const { name, y, color } = this;
                                    return labelFormatter(name, y, color, 32, 12);
                                },
                            },
                        },
                    },
                    {
                        condition: {
                            minWidth: 358,
                        },
                        chartOptions: {
                            title: {
                                style: {
                                    fontSize: '18px',
                                },
                            },
                            legend: {
                                labelFormatter() {
                                    const { name, y, color } = this;
                                    return labelFormatter(name, y, color, 48, 16);
                                },
                            },
                        },
                    },
                ],
            },
        });
    };

    useEffect(() => {
        // Apply legend styles based on query params
        const chart = Highcharts.charts.find(
            (chartElement) => chartElement?.renderTo.id === requestsNeedingAttentionVisualization
        );

        const initialValue = params[TYPE];

        if (chart) {
            chart.series[0].points.forEach((point) => {
                const isVisible = point.options.value === initialValue || !initialValue;
                point.update({ visible: isVisible }, false);

                const legendItem = point.legendItem;
                if (legendItem) {
                    legendItem.css({
                        opacity: isVisible ? 1 : 0.3,
                        filter: isVisible ? 'grayscale(0)' : 'grayscale(1)',
                        color: isVisible ? '#000000' : '#A0A0A0',
                    });
                }
            });

            chart.redraw();
        }
    }, [params]);

    useRenderChart(requestsNeedingAttentionVisualization, data, renderChart, params[TYPE]);

    return <div id={requestsNeedingAttentionVisualization} />;
};

RequestsNeedingAttention.propTypes = {
    data: PropTypes.array.isRequired,
};
