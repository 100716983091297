import { useSelector } from 'react-redux';
import { isEqual, uniqWith } from 'lodash';

import { ogFinancialsClient } from './client';
import { searchVendors, vendorSelection } from './queries';
import { useOgFinancials } from './useOgFinancials';
import {
    getDeliveryCodeSelectOptions,
    getPaymentTermsSelectOptions,
} from '../../containers/App/selectors';
import { NO_EMAIL_FOUND_TEXT } from '../../constants/requisitionsCreate';
import { generateOptions } from '../../helpers';
import { getIsRequisitionUsingFMS } from '../../selectors/govApp';

const useVendorSelection = (options) =>
    useOgFinancials({
        queryKey: 'vendorSelection',
        query: vendorSelection,
        ...options,
    });

const formatDeliveryCodes = (deliveryCodes) =>
    deliveryCodes.map((deliveryCode) => ({
        description: `${deliveryCode.delivery_location_code} - ${deliveryCode.entity_name}`,
        ...deliveryCode,
    }));

/* This is used in AsyncSelect which causes a hooks error if you use react-query's
 * useQuery.
 */
export const fetchVendorSearchOptions = async (search) =>
    ogFinancialsClient.request(searchVendors, { search }).then((res) =>
        res?.vendors?.data.map((vendor) => {
            const addresses = uniqWith(vendor.vendor_addresses, isEqual).map((vendorAddress) => {
                const addressArray = Object.keys(vendorAddress)
                    .filter((key) => vendorAddress[key] !== '')
                    .map((key) => vendorAddress[key]);

                const displayAddress = addressArray.join(', ');

                return {
                    label: displayAddress,
                    value: displayAddress,
                    addressData: {
                        address1: vendorAddress.entity_address_1,
                        address2: vendorAddress.entity_address_2,
                        city: vendorAddress.entity_city,
                        state: vendorAddress.entity_long_state,
                        zipCode: vendorAddress.entity_zip_11,
                    },
                };
            });

            return {
                label: vendor.vendor_name,
                value: vendor.vendor_number,
                subText: vendor.vendor_number.toString(),
                user: {
                    addresses,
                    displayName: vendor.vendor_name,
                    email: vendor.default_email_address || NO_EMAIL_FOUND_TEXT,
                },
                defaultPaymentTerms: vendor.payment_terms,
            };
        })
    ) || [];

export const useVendorSelectionFormDisplay = () => {
    const hasFMS = useSelector(getIsRequisitionUsingFMS);
    let deliveryCodeSelectOptions = useSelector(getDeliveryCodeSelectOptions);
    let paymentTermsSelectOptions = useSelector(getPaymentTermsSelectOptions);
    let defaultDeliveryCode;

    const { data } = useVendorSelection();
    if (hasFMS) {
        if (data?.deliveryCodes?.data) {
            const formattedDeliveryCodes = formatDeliveryCodes(data.deliveryCodes.data);

            deliveryCodeSelectOptions = generateOptions(
                formattedDeliveryCodes,
                'description',
                'delivery_location_code'
            );
        }

        if (data?.paymentTerms) {
            paymentTermsSelectOptions = generateOptions(
                data.paymentTerms,
                'description',
                'payment_terms_code'
            );
        }

        if (data?.accountsPayableSettings[0]?.default_delivery_location_code) {
            defaultDeliveryCode = data?.accountsPayableSettings[0]?.default_delivery_location_code;
        }
    }

    return { deliveryCodeSelectOptions, paymentTermsSelectOptions, defaultDeliveryCode };
};
