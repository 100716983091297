import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldArray } from 'redux-form';

import { ReviewPriceItem } from './PriceItem';
import { fieldNames } from '../../constants';
import { VendorAssignmentModal } from '../../VendorAssignmentModal';
import { PurchaseDetailsSummary } from '../../FormComponents/PurchaseDetails/Summary';
import { Header } from '../../FormComponents/PurchaseDetails/Header';
import { getGovernmentSalesTax, getRequisitionJS } from '../../../../../../selectors/govApp';
import { isRequisitionDraft } from '../../../../../../../../shared_config/requisitions';

const { PRICE_ITEMS, PRICE_TABLE } = fieldNames;

const PriceItemsReview = ({ fields, showVendorSelection, tax }) => {
    const showDiscount = fields.getAll().some((field) => field.discount);

    const [expanded, setExpanded] = useState(-1);

    const handleExpand = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };

    return (
        <>
            <Header showDiscount={showDiscount} tax={tax} />
            {fields.map((fieldName, index) => (
                <ReviewPriceItem
                    expanded={expanded === index}
                    fields={fields}
                    handleExpand={handleExpand}
                    index={index}
                    key={index}
                    showVendorSelection={showVendorSelection}
                    tax={tax}
                />
            ))}
        </>
    );
};

PriceItemsReview.propTypes = {
    fields: PropTypes.object.isRequired,
    showVendorSelection: PropTypes.bool,
    tax: PropTypes.number,
};

export const ReviewPurchaseDetails = ({
    change,
    showVendorSelection,
    vendorAssignmentModalSubmitHandler,
}) => {
    const styles = require('../index.scss');
    const tax = useSelector(getGovernmentSalesTax);
    const requisition = useSelector(getRequisitionJS);
    const isDraft = isRequisitionDraft(requisition.status);

    const isApprovalView = !isDraft;

    return (
        <div className={styles.purchaseDetails}>
            <div className={styles.priceItemsContainer}>
                <FieldArray
                    component={PriceItemsReview}
                    name={`${PRICE_TABLE}.${PRICE_ITEMS}`}
                    showVendorSelection={showVendorSelection}
                    tax={tax}
                />
            </div>
            {isApprovalView ? (
                <div className={classNames(styles.container, styles.containerBackground)}>
                    <PurchaseDetailsSummary />
                </div>
            ) : (
                <PurchaseDetailsSummary />
            )}
            <VendorAssignmentModal
                change={change}
                submitHandler={vendorAssignmentModalSubmitHandler}
            />
        </div>
    );
};

ReviewPurchaseDetails.propTypes = {
    change: PropTypes.func.isRequired,
    showVendorSelection: PropTypes.bool,
    vendorAssignmentModalSubmitHandler: PropTypes.func,
};
