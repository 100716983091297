import React from 'react';
import { Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { TIMELINES } from '../../../../../../containers/GovApp/constants';
import { DateTimePicker } from '../../../../..';
import { customTimelineFieldsDict } from '../../../../../../../../shared_config/timelines';
import { PROP_TYPES } from './constants';

const { DATE } = customTimelineFieldsDict;

export const DateInput = (props) => {
    const { timeline, isTemplate, disabled, showValidation, timezone } = props;
    const styles = require('../shared.scss');

    return (
        <Box display="flex" flex={1}>
            <Box flex={1}>
                <Field
                    component={DateTimePicker}
                    disabled={isTemplate || disabled}
                    horizontal
                    name={timeline.projectField || `${TIMELINES}.${timeline.index}.${DATE}`}
                    placeholder="M/D/YYYY"
                    showValidation={showValidation}
                    time={false}
                    timezone={timeline.displayTime ? timezone : undefined}
                    useOpenGovStyle
                />
            </Box>
            <Box display="flex" flex={1}>
                <Box className={styles.legend} ml={1} mr={1} pt={1.5}>
                    {timeline.displayTime ? 'at' : <>&nbsp;</>}
                </Box>
                <Box flex={1}>
                    {timeline.displayTime && (
                        <Field
                            component={DateTimePicker}
                            date={false}
                            dateFormat="h:mma"
                            disabled={isTemplate || disabled}
                            horizontal
                            name={timeline.projectField || `${TIMELINES}.${timeline.index}.${DATE}`}
                            showValidation={showValidation}
                            time
                            timezone={timeline.displayTime ? timezone : undefined}
                            useOpenGovStyle
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
};
DateInput.propTypes = PROP_TYPES;
