import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CDSButton } from '../../../../../components';
import { downloadRequisitionsListCSVFile } from '../../../../../actions/requisitions';
import { useQueryParam } from '../../../../../hooks';
import { getRequisitionsSearchResults } from '../selectors';
import { getUserJS } from '../../../../selectors';

export const RequisitionsListExport = () => {
    const styles = require('./RequisitionsListExport.scss');
    const dispatch = useDispatch();

    const [params] = useQueryParam();

    const requisitions = useSelector(getRequisitionsSearchResults);
    const { organization } = useSelector(getUserJS);

    const [isExportingToCSV, setIsExportingToCSV] = useState(false);

    const exportToCSVHandler = () => {
        setIsExportingToCSV(true);

        dispatch(downloadRequisitionsListCSVFile(params, organization.timezone)).finally(() =>
            setIsExportingToCSV(false)
        );
    };

    return (
        <CDSButton
            className={styles.exportButton}
            disabled={isExportingToCSV || requisitions.length === 0}
            onClick={exportToCSVHandler}
            qaTag="requisitionsDashboard-export"
            variant="secondary"
        >
            <i className="fa fa-download" /> Export
        </CDSButton>
    );
};
