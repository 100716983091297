import { buildQaTag, componentNames } from '@og-pro/ui';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const RadioButtons = ({ buttonClassName, className, options, input, legend }) => {
    const styles = require('./index.scss');

    const handleRadioChange = (event) => {
        // Call the redux-form onChange function
        input.onChange(event.target.value);
    };

    return (
        <fieldset className={classNames(styles.container, className)}>
            <legend className="hidden">{legend}</legend>
            {options.map((option) => {
                const isSelected = input.value === option.value;
                const tagWithComponentName = buildQaTag(option.qaTag, componentNames.RADIO_ITEM);

                return (
                    <label
                        className={classNames(
                            styles.button,
                            buttonClassName,
                            isSelected && styles.selected
                        )}
                        key={option.value}
                    >
                        <input
                            {...input}
                            checked={isSelected}
                            data-qa={tagWithComponentName}
                            name="option"
                            onChange={handleRadioChange}
                            style={{ display: 'none' }}
                            type="radio"
                            value={option.value}
                        />
                        {option.label}
                    </label>
                );
            })}
        </fieldset>
    );
};

RadioButtons.propTypes = {
    buttonClassName: PropTypes.string,
    className: PropTypes.string,
    legend: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }).isRequired
    ).isRequired,
    input: PropTypes.object.isRequired, // Provided by redux-form
    value: PropTypes.string,
};

RadioButtons.defaultProps = {
    buttonClassName: '',
    className: '',
    value: '',
};
