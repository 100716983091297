import { showConfirmationSimpleModal } from './confirmation';
import { loadAllProjectProposals } from './govProposals';
import { showSnackbar } from './notification';
import { loadProjects } from './projects';
import { projectStatusesDict } from '../../../shared_config/projects';
import { downloadCSV } from '../helpers';

const { OPEN } = projectStatusesDict;

export const LOAD_INVITED_VENDOR_LIST = 'vendorList/LOAD_INVITED_VENDOR_LIST';
export const LOAD_INVITED_VENDOR_LIST_SUCCESS = 'vendorList/LOAD_INVITED_VENDOR_LIST_SUCCESS';
export const LOAD_INVITED_VENDOR_LIST_FAIL = 'vendorList/LOAD_INVITED_VENDOR_LIST_FAIL';
export const UPDATE_SEARCH_VENDOR_PARAMS = 'vendorList/UPDATE_SEARCH_VENDOR_PARAMS';
export const UPDATE_SEARCH_VENDOR_PARAMS_FAIL = 'vendorList/UPDATE_SEARCH_VENDOR_PARAMS_FAIL';

export function vendorSearch(governmentId, searchParams) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE_SEARCH_VENDOR_PARAMS, searchParams });
        return client
            .post(`/government/${governmentId}/vendor-search/search`, { data: searchParams })
            .then((result) => {
                return result;
            })
            .catch(() => {
                dispatch(showSnackbar('Error Searching', { isError: true }));
                dispatch({ type: UPDATE_SEARCH_VENDOR_PARAMS_FAIL });
            });
    };
}

export const DOWNLOAD_VENDOR_LIST_START = 'vendorList/DOWNLOAD_VENDOR_LIST_START';
export const DOWNLOAD_VENDOR_LIST_SUCCESS = 'vendorList/DOWNLOAD_VENDOR_LIST_SUCCESS';
export const DOWNLOAD_VENDOR_LIST_FAIL = 'vendorList/DOWNLOAD_VENDOR_LIST_FAIL';

export function downloadVendorSearch(governmentId) {
    return (dispatch, getState, client) => {
        dispatch({
            type: DOWNLOAD_VENDOR_LIST_START,
        });
        const searchParams = getState().vendorList.get('vendorSearchParams').toJS();
        return client
            .post(`/government/${governmentId}/vendor-search/search`, {
                data: { ...searchParams, format: 'csv' },
            })
            .then((result) => {
                downloadCSV(result, `vendors-${Date.now()}.csv`);
                dispatch({
                    type: DOWNLOAD_VENDOR_LIST_SUCCESS,
                });
            })
            .catch(() => {
                dispatch(showSnackbar('Error Downloading', { isError: true }));
                dispatch({
                    type: DOWNLOAD_VENDOR_LIST_FAIL,
                });
            });
    };
}

export const LOAD_VENDOR_CLASSIFICATION_TOTALS = 'vendorList/LOAD_VENDOR_CLASSIFICATION_TOTALS';
export const LOAD_VENDOR_CLASSIFICATION_TOTALS_SUCCESS =
    'vendorList/LOAD_VENDOR_CLASSIFICATION_TOTALS_SUCCESS';
export const LOAD_VENDOR_CLASSIFICATION_TOTALS_FAIL =
    'vendorList/LOAD_VENDOR_CLASSIFICATION_TOTALS_FAIL';
export function vendorClassificationsReport(governmentId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_VENDOR_CLASSIFICATION_TOTALS });
        return client
            .get(`/government/${governmentId}/vendor-search/classifications`)
            .then((result) => {
                dispatch({
                    type: LOAD_VENDOR_CLASSIFICATION_TOTALS_SUCCESS,
                    result: result.results,
                });
                return result;
            })
            .catch((error) => {
                dispatch({ type: LOAD_VENDOR_CLASSIFICATION_TOTALS_FAIL });
                dispatch(showSnackbar(`Error: ${error.message}`));
                return null;
            });
    };
}

export const LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS =
    'vendorList/LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS';
export const LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS_SUCCESS =
    'vendorList/LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS_SUCCESS';
export const LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS_FAIL =
    'vendorList/LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS_FAIL';
export function loadSubscribedVendorCertifications(governmentId, data = {}) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS });
        return client
            .post(`/government/${governmentId}/vendor-search/vendorCertifications`, {
                data,
            })
            .then((result) => {
                dispatch({ type: LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS_SUCCESS, result });
                return result;
            })
            .catch((error) => {
                dispatch({ type: LOAD_SUBSCRIBED_VENDOR_CERTIFICATIONS_FAIL });
                dispatch(showSnackbar(`Error: ${error.message}`));
                return null;
            });
    };
}

export function loadInvitedVendorList(governmentId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_INVITED_VENDOR_LIST });
        return client
            .get(`/government/${governmentId}/vendor-invites`)
            .then((result) => {
                dispatch({ type: LOAD_INVITED_VENDOR_LIST_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_INVITED_VENDOR_LIST_FAIL, error });
            });
    };
}

export function shouldLoadInvitedVendorList(state) {
    const nextUpdateDate = state.vendorList.get('invitedVendorListUpdateDate');
    return !nextUpdateDate || new Date() > nextUpdateDate;
}

export function inviteVendorsToPortal(governmentId, data, opts = {}) {
    return (dispatch, getState, client) => {
        return client
            .post(`/government/${governmentId}/vendor-invites`, { data })
            .then((result) => {
                if (opts.isReminder) {
                    dispatch(showSnackbar('Vendor invitation re-sent!'));
                } else {
                    const inviteCount = result.existingUsers.length + result.pendingUsers.length;
                    dispatch(showSnackbar(`${inviteCount} Vendor invitations sent!`));
                    dispatch(loadInvitedVendorList(governmentId));
                }

                return result;
            });
    };
}

export const VENDOR_SUBSCRIPTION_REMOVED = 'vendorList/VENDOR_SUBSCRIPTION_REMOVED';

export function removeVendorSubscription(governmentId, governmentSubscriptionId) {
    return (dispatch, getState, client) => {
        return client
            .del(`/government/${governmentId}/vendor-invites/${governmentSubscriptionId}`)
            .then(() => {
                dispatch({ type: VENDOR_SUBSCRIPTION_REMOVED, governmentSubscriptionId });
                dispatch(showSnackbar('Vendor removed'));
            })
            .catch((error) => {
                dispatch(showSnackbar(`Error: ${error.message}`));
            });
    };
}

export const LOAD_VENDOR_LISTS = 'vendorList/LOAD_VENDOR_LISTS';
export const LOAD_VENDOR_LISTS_SUCCESS = 'vendorList/LOAD_VENDOR_LISTS_SUCCESS';
export const LOAD_VENDOR_LISTS_FAIL = 'vendorList/LOAD_VENDOR_LISTS_FAIL';

export function loadVendorLists(governmentId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_VENDOR_LISTS });
        return client
            .get(`/government/${governmentId}/vendor-lists`)
            .then((result) => {
                dispatch({ type: LOAD_VENDOR_LISTS_SUCCESS, lists: result });
                return result;
            })
            .catch((error) => {
                dispatch({ type: LOAD_VENDOR_LISTS_FAIL });
                dispatch(showSnackbar(`Error: ${error.message}`));
                return null;
            });
    };
}

export function loadPublicVendorLists(governmentId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_VENDOR_LISTS });
        return client
            .get(`/government/${governmentId}/vendor-lists/public`)
            .then((result) => {
                dispatch({ type: LOAD_VENDOR_LISTS_SUCCESS, lists: result });
                return result;
            })
            .catch(() => {
                dispatch({ type: LOAD_VENDOR_LISTS_FAIL });
                dispatch(showSnackbar('Error Loading Vendor Lists', { isError: true }));
                return null;
            });
    };
}

export const LOAD_VENDOR_LIST = 'vendorList/LOAD_VENDOR_LIST';
export const LOAD_VENDOR_LIST_FAIL = 'vendorList/LOAD_VENDOR_LIST_FAIL';
export const LOAD_VENDOR_LIST_SUCCESS = 'vendorList/LOAD_VENDOR_LIST_SUCCESS';

export function loadVendorList(governmentId, vendorListId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_VENDOR_LIST });
        return client
            .get(`/government/${governmentId}/vendor-lists/${vendorListId}`)
            .then((result) => {
                dispatch({ type: LOAD_VENDOR_LIST_SUCCESS, result });
                return result;
            })
            .catch((error) => {
                dispatch({ type: LOAD_VENDOR_LIST_FAIL });
                dispatch(showSnackbar(`Error: ${error.message}`));
                return null;
            });
    };
}

export function searchVendorList(governmentId, vendorListId, data) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_VENDOR_LIST });
        return client
            .post(`/government/${governmentId}/vendor-lists/${vendorListId}`, { data })
            .then((result) => {
                dispatch({ type: LOAD_VENDOR_LIST_SUCCESS, result, isSearch: true });
                return result;
            })
            .catch((error) => {
                dispatch({ type: LOAD_VENDOR_LIST_FAIL });
                dispatch(showSnackbar(`Error: ${error.message}`));
                return null;
            });
    };
}

export const FETCH_VENDORS_LISTS_SUCCESS = 'vendorlist/FETCH_VENDORS_LISTS_SUCCESS';
export function loadVendorsLists(governmentId, vendorUserId, pendingUserId) {
    const query = pendingUserId ? `pendingUserId=${pendingUserId}` : `vendorUserId=${vendorUserId}`;
    return (dispatch, getState, client) => {
        return client
            .get(`/government/${governmentId}/vendor-lists?${query}`)
            .then((result) => {
                dispatch({ type: FETCH_VENDORS_LISTS_SUCCESS, lists: result, vendorUserId });
                return result;
            })
            .catch(() => {
                return null;
            });
    };
}

export function createOrUpdateVendorList(governmentId, data) {
    return (dispatch, getState, client) => {
        if (data.id) {
            return client
                .put(`/government/${governmentId}/vendor-lists/${data.id}`, { data })
                .then((result) => {
                    dispatch(loadVendorLists(governmentId));
                    dispatch(loadVendorList(governmentId, data.id));
                    dispatch(showSnackbar('Vendor List updated'));
                    return result;
                })
                .catch(() => {
                    dispatch(showSnackbar('Error Updating Vendor List', { isError: true }));
                    return null;
                });
        }

        return client
            .post(`/government/${governmentId}/vendor-lists`, { data })
            .then((result) => {
                dispatch(loadVendorLists(governmentId));
                if (data.userIds && data.userIds.length === 1) {
                    dispatch(loadVendorsLists(governmentId, data.userIds[0]));
                }
                const match = window.location.pathname.match(
                    /projects\/(\d+)\/(sourcing|evaluation)\/proposals/
                );
                if (match) {
                    dispatch(loadAllProjectProposals(match[1]));
                }
                dispatch(
                    showSnackbar(
                        `Vendor List created${
                            data.userIds && data.userIds.length > 0
                                ? ` with ${data.userIds.length} vendors`
                                : '.'
                        }`
                    )
                );
                return result;
            })
            .catch(() => {
                dispatch(showSnackbar('Error Creating Vendor List', { isError: true }));
                return null;
            });
    };
}

export function deleteVendorList(governmentId, vendorListId) {
    return (dispatch, getState, client) => {
        const deleteAction = () =>
            client
                .del(`/government/${governmentId}/vendor-lists/${vendorListId}`)
                .then(() => {
                    dispatch(loadVendorLists(governmentId));
                    dispatch(showSnackbar('Vendor List deleted'));
                })
                .catch((error) => {
                    dispatch(showSnackbar(`Error Deleting Vendor List: ${error.message}`));
                });

        dispatch(
            showConfirmationSimpleModal(
                () => {
                    deleteAction();
                },
                {
                    bsStyle: 'danger',
                    btnText: 'Delete',
                    icon: 'trash',
                    text: 'Do you want to delete this vendor list?',
                    title: 'Confirm Delete of Vendor List',
                }
            )
        );
    };
}

export const TOGGLE_ADD_VENDORS_TO_LIST_MODAL = 'vendorList/TOGGLE_ADD_VENDORS_TO_LIST_MODAL';

export function toggleAddVendorsToListModal() {
    return {
        type: TOGGLE_ADD_VENDORS_TO_LIST_MODAL,
    };
}

export function showAddVendorsToListModal(governmentId, vendorListId) {
    return (dispatch) => {
        dispatch(loadVendorLists(governmentId)).then((result) => {
            if (result.length) {
                if (vendorListId) {
                    dispatch(loadVendorList(governmentId, vendorListId));
                }
                dispatch(toggleAddVendorsToListModal());
            } else {
                dispatch(showSnackbar("No Vendors lists. You'll need to add one first."));
            }
        });
    };
}

export const ADDING_VENDORS_TO_LIST_MODAL = 'vendorlist/ADDING_VENDORS_TO_LIST_MODAL';
export const ADDING_VENDORS_TO_LIST_MODAL_SUCCESS =
    'vendorlist/ADDING_VENDORS_TO_LIST_MODAL_SUCCESS';
export const ADDING_VENDORS_TO_LIST_MODAL_FAIL = 'vendorlist/ADDING_VENDORS_TO_LIST_MODAL_FAIL';
export function addOrRemoveVendors({
    actionType = 'add',
    governmentId,
    pendingUserIds = [],
    opts = {},
    searchParams,
    userIds = [],
    vendorListId,
}) {
    return (dispatch, getState, client) => {
        const isAddAction = actionType === 'add';
        const numOfVendors = userIds.length + pendingUserIds.length;

        const addAction = () =>
            client
                .post(`/government/${governmentId}/vendor-lists/${vendorListId}/users`, {
                    data: {
                        actionType,
                        pendingUserIds,
                        searchParams: userIds.length ? null : searchParams,
                        userIds,
                    },
                })
                .then(() => {
                    dispatch(showSnackbar(`Vendor(s) ${isAddAction ? 'Added' : 'Removed'}`));
                    dispatch(loadVendorList(governmentId, vendorListId));
                    const singleUserId = userIds.length === 1 ? userIds[0] : null;
                    const singlePendingUserId =
                        pendingUserIds.length === 1 ? pendingUserIds[0] : null;
                    if (singleUserId) {
                        dispatch(loadVendorsLists(governmentId, singleUserId));
                    } else if (singlePendingUserId) {
                        dispatch(loadVendorsLists(governmentId, null, singlePendingUserId));
                    }
                    const match = window.location.pathname.match(
                        /projects\/(\d+)\/(sourcing|evaluation)\/proposals/
                    );
                    if (match) {
                        dispatch(loadAllProjectProposals(match[1]));
                    }
                })
                .catch((error) => {
                    dispatch(
                        showSnackbar(
                            `Error ${isAddAction ? 'Adding' : 'Removing'} Vendors: ${error.message}`
                        )
                    );
                });

        const getText = () => {
            const { email, listName, userName, vendorName } = opts;
            if (isAddAction) {
                return `Add ${numOfVendors || 'Active Search Results'} to ${listName || 'Vendor List'}`;
            }
            if (listName && (userName || email)) {
                const vendorNameDisplay = vendorName ? ` (${vendorName})` : '';
                return `Remove the following from ${listName} list:\n\n${userName || email} ${vendorNameDisplay}`;
            }
            return `Remove ${numOfVendors} from ${listName || 'Vendor List'}`;
        };

        const pluralize = numOfVendors > 1 ? 's' : '';

        const getTitle = () => {
            const addOrRemove = isAddAction ? 'add' : 'remove';
            const theseOrThis = pluralize ? 'these' : 'this';

            return `Are you sure you want to ${addOrRemove} ${theseOrThis} vendor${pluralize}?`;
        };

        dispatch(
            showConfirmationSimpleModal(
                () => {
                    addAction();
                },
                {
                    bsStyle: isAddAction ? 'success' : 'danger',
                    btnText: isAddAction ? `Add Vendor${pluralize}` : `Remove Vendor${pluralize}`,
                    dialogProps: { fullWidth: true, maxWidth: 'sm' },
                    dialogStyle: isAddAction ? 'success' : 'error',
                    icon: isAddAction ? 'fa-plus' : 'trash',
                    text: getText(),
                    title: getTitle(),
                    useOpenGovStyle: opts.useOpenGovStyle,
                }
            )
        );
    };
}

export function vendorSearchSelectList(governmentId, fuzzy, filterPendingUsers, includeVendorId) {
    return (dispatch, getState, client) => {
        return client
            .post(`/government/${governmentId}/vendor-search/fuzzy`, {
                data: {
                    search: {
                        fuzzy,
                    },
                    filterPendingUsers,
                    includeVendorId,
                },
            })
            .then((result) => {
                return result.results;
            })
            .catch(() => {
                return [];
            });
    };
}

export const TOGGLE_INVITE_VENDOR_LIST_TO_PROJECT_MODAL =
    'vendorList/TOGGLE_INVITE_VENDOR_LIST_TO_PROJECT_MODAL';
export function toggleInviteVendorListToProjectModal() {
    return (dispatch) => {
        dispatch(loadProjects({ status: OPEN })).then(() => {
            dispatch({
                type: TOGGLE_INVITE_VENDOR_LIST_TO_PROJECT_MODAL,
            });
        });
    };
}

export function blockVendors(governmentId, userIds, isBlocked) {
    return (dispatch, getState, client) => {
        const data = { isBlocked, userIds };
        return client
            .post(`/government/${governmentId}/vendor-invites/block`, { data })
            .then(() => {
                dispatch(
                    showSnackbar(
                        `${userIds.length} Vendor${userIds.length === 1 ? '' : 's'} ${
                            isBlocked ? 'Blocked' : 'Unblocked'
                        }`
                    )
                );
            })
            .catch((error) => {
                dispatch(showSnackbar(`Error: ${error.message}`));
                return error;
            });
    };
}

export function loadProcuratedRatingsClient(supplierIds) {
    return (dispatch, getState, client) => {
        return client
            .post('/procurated/suppliers', { data: supplierIds })
            .then((result) => {
                return result;
            })
            .catch(() => {
                dispatch(showSnackbar('Error Loading Ratings', { isError: true }));
            });
    };
}

export const UPDATE_VENDOR_COUNT = 'vendorList/UPDATE_VENDOR_COUNT';

export function updateVendorCount(vendorCount) {
    return { type: UPDATE_VENDOR_COUNT, vendorCount };
}
