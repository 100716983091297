import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';
import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import { AgGridReact, ProcuratedVendorCell } from '../../../../components';
import { showVendorProfileModal } from '../../../../actions/vendorProfile';
import { addOrRemoveVendors } from '../../../../actions/vendorList';

export const VendorListTable = ({ governmentId, vendorList }) => {
    const dispatch = useDispatch();

    // TODO: Add the load and set procurated ratings piece

    const columns = [
        {
            field: 'organization.name',
            headerName: 'Vendor Legal Name & Rating (Powered by Procurated)',
            flex: 3,
            minWidth: 420,
            pinned: 'left',
            onCellClicked: (params) => {
                dispatch(showVendorProfileModal(params.data.organization.vendor.id));
            },
            cellRendererFramework: (params) => (
                <ProcuratedVendorCell
                    hideLogo
                    params={{ ...params, data: { ...params.data.organization } }}
                />
            ),
        },
        {
            field: 'displayName',
            headerName: 'Contact',
            flex: 2,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 2,
            minWidth: 250,
        },
        {
            field: 'organization.phoneComplete',
            headerName: 'Company Phone',
            flex: 2,
        },
        {
            cellRendererFramework: () => (
                <div style={{ marginTop: '7px' }}>
                    <DeleteOutlinedIcon />
                </div>
            ),
            onCellClicked: (params) => {
                const pendingUserId = params.data.isPendingUser && params.data.id;
                dispatch(
                    addOrRemoveVendors({
                        actionType: 'remove',
                        governmentId,
                        pendingUserIds: pendingUserId ? [pendingUserId] : [],
                        opts: {
                            email: params.data.email,
                            listName: vendorList.name,
                            useOpenGovStyle: true,
                            userName: params.data.displayName,
                            vendorName: params.data.organization && params.data.organization.name,
                        },
                        searchParams: null,
                        userIds: pendingUserId ? [] : [params.data.id],
                        vendorListId: vendorList.id,
                    })
                );
            },
            width: 50,
        },
    ];

    /**
     * Given a row of data from the grid, get a unique ID for it.
     *
     * @param {object} data The raw data in this row
     * @return {string} The unique title of the row
     */
    const getRowNodeId = (data) => {
        return data.id;
    };

    const defaultColDef = {
        editable: false,
        suppressMenu: true,
    };

    const rows = vendorList.listedUsers.concat(
        vendorList.listedPendingUsers.map((user) => ({ ...user, isPendingUser: true }))
    );

    return (
        <Box mt={1}>
            <AgGridReact
                columns={columns}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                getRowNodeId={getRowNodeId}
                hideSideBar
                pagination
                paginationPageSize={20}
                rows={rows}
                useOpenGovStyle
            />
        </Box>
    );
};

VendorListTable.propTypes = {
    governmentId: PropTypes.number.isRequired,
    vendorList: PropTypes.object.isRequired,
};
