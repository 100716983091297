import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Output as OutputIcon, Search as SearchIcon } from '@mui/icons-material';
import { Box, Button, InputAdornment, Switch, TextField, Typography } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { CertificationsMenu } from './CertificationsMenu';
import { CACHE_BLOCK_SIZE, DEFAULT_PAGE_SIZE, internalColumns, publicColumns } from './constants';
import { retrieveVendors } from './helpers';
import { AgGridReact } from '..';
import { CategorySelectButton } from '../../containers/CategorySelect';
import { FiltersMenu } from './FiltersMenu';
import {
    loadProcuratedRatingsClient,
    loadPublicVendorLists,
    loadVendorLists,
} from '../../actions/vendorList';
import { downloadVendorSearch } from '../../actions/vendorSearch';
import Columns from './Columns';
import { TakeActionsMenu } from './TakeActionsMenu';

export const VendorSearchTable = ({
    addVendorsToList,
    blockVendors,
    emailVendors,
    governmentId,
    inviteVendorsToProject,
    searchVendors,
    isPublic,
}) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const [subscriberView, setSubscriberView] = useState(false);

    const [gridApi, setGridApi] = useState(null);
    const [columns, setColumns] = useState(isPublic ? publicColumns : internalColumns);
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams({
        categories: '',
        certifications: '',
        ethnicities: [],
        languages: [],
        searchText: '',
        vendorLists: [],
    });

    const categories = searchParams.get('categories');
    const categoryIds = categories ? categories.split(',') : [];

    const certifications = searchParams.get('certifications');
    const certificationIds = certifications
        ? certifications.split(',').map((certification) => parseInt(certification, 10))
        : [];

    const ethnicities = searchParams.get('ethnicities')
        ? searchParams.get('ethnicities').split(',')
        : [];

    const vendorLists = searchParams.get('vendorLists')
        ? searchParams.get('vendorLists').split(',')
        : [];

    const searchText = searchParams.get('searchText');

    const defaultColDef = {
        editable: false,
        sortable: false,
        suppressMenu: true,
    };

    const handleSelectionChanged = () => {
        const newSelectedUserIds = gridApi.getSelectedRows().flatMap((row) => {
            return row.subscriberId;
        });
        setSelectedUserIds(newSelectedUserIds);
    };

    const toggleFilterColumns = (field) => {
        setColumns(
            columns.map((column) => {
                if (column.field === field) {
                    return { ...column, hide: !column.hide };
                }
                return column;
            })
        );
    };

    const loadVendors = (api) => {
        const searchApi = retrieveVendors(
            searchVendors,
            governmentId,
            { searchText, categoryIds, certificationIds, ethnicities, vendorLists },
            loadProcuratedRatingsClient,
            dispatch,
            isPublic,
            subscriberView
        );

        api?.setServerSideDatasource(searchApi);
    };

    useEffect(() => {
        if (isPublic) {
            dispatch(loadPublicVendorLists(governmentId));
        } else {
            dispatch(loadVendorLists(governmentId));
        }
    }, []);

    useEffect(() => {
        loadVendors(gridApi);
    }, [searchParams, subscriberView]);

    const getRowNodeId = (data) => data.id;

    const onGridReady = (params) => {
        loadVendors(params.api);
        setGridApi(params.api);
    };

    const onCategorySelect = (selectedCategories, hasChanged) => {
        if (hasChanged) {
            searchParams.set(
                'categories',
                selectedCategories.map((selectedCategory) => selectedCategory.id).join(',')
            );

            setSearchParams(searchParams);
        }
    };

    const onCertificationSelect = (selectedCertification) => {
        const currentIndex = certificationIds.indexOf(selectedCertification);
        const newSelected = [...certificationIds];
        if (currentIndex === -1) {
            newSelected.push(selectedCertification);
        } else {
            newSelected.splice(currentIndex, 1);
        }
        searchParams.set('certifications', newSelected.join(','));
        setSearchParams(searchParams);
    };

    const onExportClick = () => {
        dispatch(
            downloadVendorSearch(governmentId, {
                searchObject: {
                    searchText,
                    categoryIds,
                    certificationIds,
                    ethnicities,
                    vendorLists,
                },
                sortModel: [],
            })
        );
    };

    return (
        <Box pt={3}>
            <Box display="flex" justifyContent="space-between" pb={2}>
                <Box display="flex" flexDirection="column">
                    <form title="Search vendors by name or address">
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment aria-label="search icon" position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props -- Props are different.
                            inputProps={{
                                title: 'Search vendors by name or address',
                                'aria-label': 'Search vendors by name or address',
                            }}
                            onChange={(e) => {
                                searchParams.set('searchText', e.target.value);
                                setSearchParams(searchParams);
                            }}
                            placeholder="Search names or address"
                            qaTag="vendorSearch-search"
                            sx={{
                                padding: 0,
                                marginBottom: '12px',
                                width: '100%',
                                '& .MuiInputBase-root': {
                                    margin: 0,
                                },
                            }}
                        />
                        <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
                            <Typography
                                sx={{
                                    color: tokens.colors.colorGray800,
                                    fontWeight: tokens.typography.fontWeightMedium,
                                    fontSize: tokens.typography.fontSizeSmall,
                                }}
                                variant="h3"
                            >
                                Filter By:
                            </Typography>
                            <CategorySelectButton
                                categoryIds={categoryIds}
                                onSelect={onCategorySelect}
                            />
                            <CertificationsMenu
                                handleToggle={onCertificationSelect}
                                selected={certificationIds}
                            />
                            <FiltersMenu
                                isPublic={isPublic}
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                            />
                        </Box>
                    </form>
                </Box>
                <Box display="flex" flexDirection="column" gap={1} justifyContent="flex-end">
                    <Box display="flex" gap={1} justifyContent="flex-end">
                        {!isPublic && (
                            <TakeActionsMenu
                                addVendorsToList={addVendorsToList}
                                blockVendors={blockVendors}
                                emailVendors={emailVendors}
                                inviteVendorsToProject={inviteVendorsToProject}
                                selectedUserIds={selectedUserIds}
                            />
                        )}
                        <Columns columns={columns} onClickCheckBox={toggleFilterColumns} />
                        <Button
                            color="secondary"
                            onClick={onExportClick}
                            qaTag="vendorSearch-export"
                            startIcon={<OutputIcon />}
                            variant="text"
                        >
                            Export
                        </Button>
                    </Box>
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        justifyContent="flex-end"
                        pr={1.5}
                    >
                        <Box>
                            <Typography
                                for="switch"
                                id="switchLabel"
                                sx={{
                                    color: tokens.colors.colorGray800,
                                    fontSize: tokens.typography.fontSizeBase,
                                }}
                            >
                                Subscriber Details:
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" gap={0.5}>
                            <Typography
                                sx={{
                                    color: tokens.colors.colorGray1000,
                                    fontWeight: tokens.typography.fontWeightMedium,
                                }}
                            >
                                HIDE
                            </Typography>
                            <Switch
                                checked={subscriberView}
                                inputProps={{
                                    id: 'switch',
                                    'aria-label': 'Switch to show or hide subscriber details',
                                    'aria-labelledby': 'switchLabel',
                                }}
                                onChange={(event) => {
                                    setSubscriberView(event.target.checked);
                                }}
                                qaTag="vendorSearch-showHideSubscribers"
                                size="small"
                            />
                            <Typography
                                sx={{
                                    color: tokens.colors.colorGray700,
                                    fontWeight: tokens.typography.fontWeightMedium,
                                }}
                            >
                                SHOW
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <div className={styles.alignCheckbox}>
                <AgGridReact
                    cacheBlockSize={CACHE_BLOCK_SIZE}
                    columns={columns}
                    containerStyle={{ height: '634px' }}
                    defaultColDef={defaultColDef}
                    getRowHeight={(params) => {
                        if (params.data.expanded) {
                            const largestArray = Math.max(
                                params.data.subscriberName.length,
                                params.data.subscriberEmail.length,
                                params.data.subscriberPhone.length
                            );
                            return largestArray * 52;
                        }

                        return 52;
                    }}
                    getRowNodeId={getRowNodeId}
                    hideSideBar
                    onGridReady={onGridReady}
                    onSelectionChanged={handleSelectionChanged}
                    pagination
                    paginationPageSize={DEFAULT_PAGE_SIZE}
                    rowModelType="serverSide"
                    subscriberView={subscriberView}
                />
            </div>
        </Box>
    );
};

VendorSearchTable.propTypes = {
    addVendorsToList: PropTypes.func,
    blockVendors: PropTypes.func,
    emailVendors: PropTypes.func,
    governmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    inviteVendorsToProject: PropTypes.func,
    isPublic: PropTypes.bool,
    searchVendors: PropTypes.func.isRequired,
};
