import React from 'react';
import PropTypes from 'prop-types';

import { getSectionNumberingString, numberItems } from '../../../../shared_config/helpers';
import { sectionTypeNames } from '../../../../shared_config/sections';

const { DIVIDER } = sectionTypeNames;

export const ProjectTableOfContentsDocx = ({ project }) => {
    const { projectSections, useManualNumbering } = project;

    const numberedSections = numberItems(projectSections);

    return numberedSections.map((section) => {
        const {
            id,
            isHidden,
            isHiddenByLogic,
            manualNumber,
            sectionNumber,
            section_type: sectionType,
            subsectionNumber,
            title,
        } = section;

        if (isHidden || isHiddenByLogic) {
            return null;
        }

        const isDivider = sectionType === DIVIDER;

        const numbering = getSectionNumberingString({
            manualNumber,
            sectionNumber,
            subsectionNumber,
            useManualNumbering,
        });

        // `<t />` is a custom tag that is replaced with a tabstop in the docx template.
        // To customize style and tabstops, `TOC Division` & `TOC Section` custom styles need to be added into the Word template.
        return (
            <p className={isDivider ? 'toc-division' : 'toc-section'} key={id}>
                {numbering}
                <t />
                {title}
                <t />
            </p>
        );
    });
};

ProjectTableOfContentsDocx.propTypes = {
    project: PropTypes.shape({
        projectSections: PropTypes.array.isRequired,
        useManualNumbering: PropTypes.bool.isRequired,
    }).isRequired,
};
