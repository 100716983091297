import { listToDict } from '../../../../../../../shared_config/helpers';

export const form = 'reviewSequenceForm';

export const fields = ['icon', 'name', 'reviewSequenceSteps', 'user_id'];

const reviewSequenceStepFields = [
    'allowGeneralEdit',
    'allowOverBudget',
    'allowPriceEdit',
    'allowVendorEdit',
    'minPositionReviews',
    'name',
    'orderById',
    'positionIds',
    'questionLogic',
    'rejectionPolicies',
    'requireOverBudgetNote',
    'requireRejectionNote',
    'requireVendor',
    'reviewSequenceStepPositions',
    'reviewType',
];

// Fields do not exist in DB. Used for form display purposes only.
const reviewSequenceStepPseudoFields = ['hasQuestionLogic', 'logicableField'];

const reviewSequenceStepQuestionLogicFields = [
    'action',
    'linkable',
    'linkable_id',
    'logicable',
    'logicable_id',
    'operator',
    'value',
];

const reviewSequenceStepPositionFields = ['position_id'];

export const fieldNames = listToDict(fields);

export const reviewSequenceStepFieldNames = listToDict(reviewSequenceStepFields);

export const reviewSequenceStepPseudoFieldNames = listToDict(reviewSequenceStepPseudoFields);

export const reviewSequenceStepQuestionLogicFieldNames = listToDict(
    reviewSequenceStepQuestionLogicFields
);

export const reviewSequenceStepPositionFieldNames = listToDict(reviewSequenceStepPositionFields);

export const formSections = ['info', 'steps'];

export const formSectionNames = listToDict(formSections);

// Fake field used for verifying if the specified section is valid
export const FORM_SECTIONS_KEY = 'sections';

// Fake field used for verifying if entire form is valid
export const ALL_SECTIONS = 'allSections';

export const incompleteSectionInstructions = {
    [formSectionNames.INFO]: 'Finish naming your sequence',
    [formSectionNames.STEPS]: 'Your sequence configuration is invalid',
};

export const MAX_NAME_LENGTH = 128;
