import { Map, fromJS } from 'immutable';

import * as searchActions from '../actions/searchProjects';

const initialState = new Map();

export default function searchReducer(state = initialState, action = {}) {
    switch (action.type) {
        case searchActions.SEARCH:
            return state.merge(
                fromJS({
                    searching: true,
                    searchResults: null,
                    searchError: null,
                })
            );
        case searchActions.SEARCH_SUCCESS:
            return state.merge(
                fromJS({
                    searchResults: fromJS(action.result),
                    searching: false,
                })
            );
        case searchActions.SEARCH_FAIL:
            return state.merge(
                fromJS({
                    searching: false,
                    searchError: action.error.message,
                })
            );
        default:
            return state;
    }
}
