import React from 'react';
import PropTypes from 'prop-types';
import { useFlags as LDUseFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

// Kebab case on flags
export const FLAGS = {
    TEST_FLAG: 'pro-test-flag',
    VENDOR_DISCOVERY_AI: 'pro-vendor-discovery-ai',
    PLATFORM_PASSWORD_RESET: 'pro-platform-password-reset',
    ACCOUNT_SPLIT_AUTO_ADJUSTMENTS: 'pro-requisition-line-item-account-split-auto-adjustments',
    SHOW_SERVICE_CLOUD_CHAT_BOT: 'pro-show-service-cloud-chat-bot',
    ENABLE_MUI_TEXTAREA_AUTOSIZE: 'pro-enable-mui-textarea-autosize',
    ENABLE_REACT_TABLE_8: 'pro-enable-react-table-8',
    ENABLE_REQUISITIONS_ATTACHMENTS: 'PRO-3460-requisitions-attachment',
    ATTACHMENTS_SCANNING: 'pro-4363-attachment-scanning',
    ENABLE_SUPPLIER_NETWORK_SEARCH_DIALOG: 'pro-enable-supplier-network-search-dialog',
    PUBLIC_VENDOR_SEARCH_PORTAL: 'pro-4968-public-vendor-search-portal',
    ALL_PROPOSALS_REPORT: 'pro-all-proposals-report',
    SHOW_CERTIFICATIONS_IMPORT: 'pro-4468-enable-vendor-certifications-import',
    DOING_BUSINESS_AS: 'pro-5274-doing-business-as-field',
    ENABLE_GLOBAL_NAV_GOV_USERS: 'pro-enable-global-nav',
    ENABLE_VENDOR_LISTS_UPDATE: 'pro-5282-updated-vendor-lists',
    ENABLE_VENDOR_SEARCH_THROUGH_API: 'PRO-api-proxy-vendor-search',
};

const flagValues = Object.values(FLAGS);

export const isDevMode = !process.env.LAUNCH_DARKLY_CLIENTSIDE_ID;

export const getLDFlag = (ldFlags, flag) => {
    if (isDevMode) {
        return true;
    }

    if (!flagValues.includes(flag)) {
        throw new Error('Invalid flag! Flags must be defined in launchdarkly.js!');
    }

    // If we don't have flags just return false. Don't want to expose features on accident when internet goes down.
    return (ldFlags && ldFlags[flag]) || false;
};

export const withFlags = () => {
    return (WrappedComponent) => {
        class ComponentWithFlags extends React.Component {
            static propTypes = {
                flags: PropTypes.object,
            };

            getFlag = (flag) => {
                return getLDFlag(this.props.flags, flag);
            };

            render() {
                return <WrappedComponent {...this.props} getFlag={this.getFlag} />;
            }
        }
        if (isDevMode) {
            return ComponentWithFlags;
        }
        return withLDConsumer()(ComponentWithFlags);
    };
};

export const useFlags = (flag) => {
    if (isDevMode) {
        return true;
    }
    const flags = LDUseFlags();

    return getLDFlag(flags, flag);
};
