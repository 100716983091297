import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addVendorContainerStyle, vendorContainerStyle } from './styles';
import { VendorCard } from '../VendorCard';
import { VendorSearch } from '../VendorSearch';
import { fieldNames } from '../../../constants';
import { getCanAddVendor } from '../../../selectors';
import { qaTagPageName } from '../../../../constants';
import { getEmptyVendorItem } from '../../../../utils';
import { vendorSelectionStates } from '../../../../../../../../../shared_config/requisitions';

const { VENDOR_SELECTION_STATE } = fieldNames;

export const Vendors = ({
    associatedContractData,
    associatedVendorData,
    change,
    disabled,
    fields,
    hasFMS,
    loadingVendorData,
    setShowAddVendorInput,
    showAddVendorInput,
    showFormValidation,
    showMismatchModal,
    vendorSelectionFormDisplay,
}) => {
    const dispatch = useDispatch();

    const showAddVendorInputHandler = () => setShowAddVendorInput(true);

    const onAddVendor = (data) => {
        fields.push({
            ...getEmptyVendorItem(),
            deliveryCodeText: vendorSelectionFormDisplay.defaultDeliveryCode,
            ...data,
        });

        dispatch(change(VENDOR_SELECTION_STATE, vendorSelectionStates.SELECTED_VENDORS));
        setShowAddVendorInput(false);
    };

    const canAddVendor = useSelector(getCanAddVendor);

    return (
        <>
            <Typography paddingBottom={2} variant="h4">
                Add Vendor(s)
            </Typography>
            <Box {...(fields.length > 0 && vendorContainerStyle)}>
                {fields.map((fieldName, index) => {
                    const vendor = fields.get(index);
                    return (
                        <VendorCard
                            associatedContractData={
                                associatedContractData?.[vendor.vendorAssignedNo?.toLowerCase()]
                            }
                            associatedVendorData={associatedVendorData?.[vendor.pro_vendor_id]}
                            change={change}
                            disabled={disabled}
                            fieldName={fieldName}
                            fields={fields}
                            hasFMS={hasFMS}
                            index={index}
                            key={vendor.vendorAssignmentUuid}
                            loading={loadingVendorData}
                            showAddVendorInputHandler={showAddVendorInputHandler}
                            showFormValidation={showFormValidation}
                            showMismatchModal={showMismatchModal}
                            showVendorSelection={fields.length > 1}
                            vendorSelectionFormDisplay={vendorSelectionFormDisplay}
                        />
                    );
                })}
            </Box>
            {showAddVendorInput && canAddVendor ? (
                <Card sx={{ ...addVendorContainerStyle, overflow: 'visible' }}>
                    <CardContent>
                        <Box padding={1}>
                            <VendorSearch
                                changeHandler={onAddVendor}
                                disabled={disabled}
                                hasFMS={hasFMS}
                                showFormValidation={showFormValidation}
                                showMismatchModal={showMismatchModal}
                            />
                        </Box>
                    </CardContent>
                </Card>
            ) : (
                <Box sx={addVendorContainerStyle}>
                    <Button
                        disabled={disabled || !canAddVendor}
                        onClick={showAddVendorInputHandler}
                        qaTag={`${qaTagPageName}-addAVendor`}
                        startIcon={<AddIcon />}
                        tooltip={
                            canAddVendor
                                ? undefined
                                : 'Add more line items before adding another vendor. There must be unassigned line items to add more vendors.'
                        }
                        variant="outlined"
                    >
                        Add Another Vendor
                    </Button>
                </Box>
            )}
        </>
    );
};

Vendors.propTypes = {
    associatedContractData: PropTypes.object,
    associatedVendorData: PropTypes.object,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    hasFMS: PropTypes.bool.isRequired,
    loadingVendorData: PropTypes.bool,
    setShowAddVendorInput: PropTypes.func.isRequired,
    showMismatchModal: PropTypes.func.isRequired,
    showAddVendorInput: PropTypes.bool,
    showFormValidation: PropTypes.bool,
    vendorSelectionFormDisplay: PropTypes.object.isRequired,
};
