import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Step } from '../Step';
import { getIsRequisitionUsingFMS } from '../../../../../../selectors/govApp';
import { isPoCreated } from '../../../../../../../../shared_config/requisitions';
import { getPurchaseOrderUrls } from '../../../helpers';

export const PurchaseOrderStep = ({ requisition: { purchaseOrderObject, status } }) => {
    const styles = require('./index.scss');
    const hasPurchaseOrder = isPoCreated(status);
    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    if (hasPurchaseOrder) {
        if (!hasFMS || !purchaseOrderObject?.length) {
            return (
                <Step
                    stepName="Final Approval Received"
                    stepStatusIcon="fa fa-dollar"
                    stepStatusIconClassName={styles.successBackground}
                />
            );
        }

        const purchaseOrders = getPurchaseOrderUrls(purchaseOrderObject);

        return purchaseOrders.map(({ href, number }) => (
            <Step
                StepStatusComponent={<i className={`fa fa-external-link ${styles.externalLink}`} />}
                href={href}
                key={number}
                stepName={`PO #${number}`}
                stepNameClassName={styles.actionText}
                stepStatusIcon="fa fa-dollar"
                stepStatusIconClassName={styles.actionBackground}
                stepStatusText="Purchase Order Created"
                stepStatusTextClassName={styles.actionText}
            />
        ));
    }

    return (
        <Step
            stepName="No Purchase Order Yet"
            stepNameClassName={styles.mutedText}
            stepStatusIcon="fa fa-dollar"
            stepStatusIconClassName={styles.mutedBackground}
        />
    );
};

PurchaseOrderStep.propTypes = {
    requisition: PropTypes.shape({
        purchaseOrderObject: PropTypes.arrayOf(
            PropTypes.shape({
                po_number: PropTypes.number,
            })
        ),
        status: PropTypes.number.isRequired,
    }).isRequired,
};
