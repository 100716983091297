import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { form } from './constants';
import { MULTIPLE_CHOICE, YES_NO } from '../../../../../shared_config/questionnaires';
import {
    questionLogicActionNames,
    questionLogicFieldNames,
    questionLogicOperatorNames,
} from '../../../../../shared_config/questionLogics';

const { SHOW } = questionLogicActionNames;

const { ACTION, OPERATOR, LOGICABLE_ID } = questionLogicFieldNames;

const { EQUAL, INCLUDE, NOT_INCLUDE } = questionLogicOperatorNames;

const formSelector = formValueSelector(form);

const getModalFormData = (state) => state.app.get('conditionalLogicModalData');

export const hasMultiSelectOperator = (state) => {
    return [INCLUDE, NOT_INCLUDE].includes(formSelector(state, OPERATOR));
};

export const getModalFormDataJS = createSelector([getModalFormData], (rawModalData) =>
    rawModalData.toJS()
);

export const getInitialFormValues = createSelector([getModalFormDataJS], (modalData) => {
    return {
        [OPERATOR]: EQUAL,
        [ACTION]: SHOW,
        [LOGICABLE_ID]: modalData.parentQuestion.id,
    };
});

export const getConditionalLogicParentQuestionOptions = createSelector(
    [getModalFormDataJS],
    (modalData) => {
        const { parentQuestion } = modalData;

        return [
            {
                label: parentQuestion.title,
                parentQuestion,
                value: parentQuestion.id,
            },
        ];
    }
);

export const getParentQuestionValueSelectOptions = createSelector(
    [getModalFormDataJS],
    (modalData) => {
        const { parentQuestion } = modalData;

        if (parentQuestion.type === YES_NO) {
            return [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
            ];
        }

        if (parentQuestion.type === MULTIPLE_CHOICE) {
            return parentQuestion.data.options.map((option, index) => {
                return {
                    label: option,
                    value: index,
                };
            });
        }
        return [];
    }
);

export const getShowConditionalLogicModal = (state) => state.app.get('showConditionalLogicModal');
