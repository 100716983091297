import { buildQaTag, componentNames } from '@og-pro/ui';
import React from 'react';
import PropTypes from 'prop-types';

const TabAccordionTrigger = ({ isOpen, label, onClick }) => {
    const { tabAccordionTrigger, angleIcon } = require('./index.scss');

    let angleDirection = 'down';
    if (isOpen) {
        angleDirection = 'up';
    }

    const qaTag = buildQaTag('templateAdmin-tabAccordionTrigger', componentNames.BUTTON);

    return (
        <button className={tabAccordionTrigger} data-qa={qaTag} onClick={onClick}>
            {label}
            <i
                aria-hidden="true"
                className={`fa fa-angle-${angleDirection} fa-2x pull-right ${angleIcon}`}
            />
        </button>
    );
};

TabAccordionTrigger.propTypes = {
    isOpen: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default TabAccordionTrigger;
