import { resourceManager } from '../helpers';

export const SEARCH_REQUISITIONS = 'requisitionsDashboard/SEARCH_REQUISITIONS';
export const SEARCH_REQUISITIONS_SUCCESS = 'requisitionsDashboard/SEARCH_REQUISITIONS_SUCCESS';
export const SEARCH_REQUISITIONS_FAILURE = 'requisitionsDashboard/SEARCH_REQUISITIONS_FAILURE';

export const LOAD_REQUISITIONS_SUMMARY = 'requisitions/LOAD_REQUISITIONS_SUMMARY';
export const LOAD_REQUISITIONS_SUMMARY_SUCCESS = 'requisitions/LOAD_REQUISITIONS_SUMMARY_SUCCESS';
export const LOAD_REQUISITIONS_SUMMARY_FAILURE = 'requisitions/LOAD_REQUISITIONS_SUMMARY_FAILURE';

export const searchRequisitions = (data) => {
    return resourceManager({
        method: 'post',
        url: '/requisitions/requisitions/search',
        requestOptions: { data },
        onStart: ({ dispatch }) => dispatch({ type: SEARCH_REQUISITIONS }),
        onSuccess: ({ dispatch, result }) =>
            dispatch({ type: SEARCH_REQUISITIONS_SUCCESS, result }),
        onFailure: ({ dispatch, error }) => dispatch({ type: SEARCH_REQUISITIONS_FAILURE, error }),
    });
};

export const loadRequisitionsSummary = () => {
    return resourceManager({
        method: 'get',
        url: '/requisitions/requisitions/summary',
        onStart: ({ dispatch }) => {
            dispatch({ type: LOAD_REQUISITIONS_SUMMARY });
        },
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: LOAD_REQUISITIONS_SUMMARY_SUCCESS, result });
        },
        onFailure: ({ dispatch, error }) =>
            dispatch({ type: LOAD_REQUISITIONS_SUMMARY_FAILURE, error }),
    });
};
