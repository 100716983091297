import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { fieldNames, form } from './constants';
import { validate } from './validate';
import { Button, SearchSelect } from '../../../../components';
import { toggleInviteVendorListToProjectModal } from '../../../../actions/vendorList';
import { inviteVendorsToProject } from '../../../../actions/projectPost';
import { PROJECT_ID } from '../../../../components/LineItemAwardTable/constants';

const { VENDOR_LIST_ID, USER_IDS, SEARCH_PARAMS } = fieldNames;

const mapStateToProps = (state) => {
    const projects = state.projects
        .get('projects')
        .toJS()
        .filter((project) => project.status === 'open');
    return {
        projects,
        invalid: projects.length === 0,
        inviting: state.projectPost.get('invitingVendors'),
    };
};

const mapDispatchToProps = {
    hideModal: toggleInviteVendorListToProjectModal,
    inviteVendorsToProject,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedAddVendorsToListModal extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        inviting: PropTypes.bool.isRequired,
        inviteVendorsToProject: PropTypes.func.isRequired,
        projects: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                title: PropTypes.string,
            })
        ),
    };

    get styles() {
        return require('./index.scss');
    }

    inviteVendors = (rawData) => {
        const { hideModal } = this.props;

        const data = {};

        if (rawData[VENDOR_LIST_ID]) {
            data[VENDOR_LIST_ID] = rawData[VENDOR_LIST_ID];
        }

        if (rawData[USER_IDS]) {
            data[USER_IDS] = rawData[USER_IDS];
        }

        if (rawData[SEARCH_PARAMS]) {
            data[SEARCH_PARAMS] = rawData[SEARCH_PARAMS];
        }

        this.props.inviteVendorsToProject(rawData[PROJECT_ID], data).then((usersOrError) => {
            if (!(usersOrError instanceof Error)) {
                hideModal();
            }
        });
    };

    renderBody() {
        const { handleSubmit, hideModal, inviting, invalid, projects } = this.props;

        return (
            <form onSubmit={handleSubmit(this.inviteVendors)}>
                {projects.length === 0 && <p>No Projects are open to vendors</p>}
                {projects.length > 0 && (
                    <Field
                        component={SearchSelect}
                        disabled={inviting}
                        isClearable
                        label="Select a Project"
                        name={PROJECT_ID}
                        options={projects.map((item) => {
                            return {
                                label: item.title,
                                value: item.id,
                            };
                        })}
                        placeholder="Select a Project"
                    />
                )}
                <div className="row">
                    <div className="col-sm-12 text-right">
                        <Button disabled={inviting} onClick={hideModal}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button bsStyle="primary" disabled={invalid || inviting} type="submit">
                            <i className="fa fa-plus" /> Invite Vendors to Project
                        </Button>
                    </div>
                </div>
            </form>
        );
    }

    render() {
        const { hideModal } = this.props;

        return (
            <Modal onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Invite Vendors to Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const InviteVendorsToProjectModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedAddVendorsToListModal);
