import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { evaluationTypeOptions, form, TYPE } from './constants';
import { validate } from './validate';
import { MultipleChoiceInput } from '../../../components';
import { SCORED } from '../../../../../shared_config/evaluations';

const mapStateToProps = (state, props) => {
    const onSubmit = (rawData) => {
        props.onSubmit(rawData[TYPE]);
    };

    // Handles cases when there is no choice to make
    const forceType = (props.priceTables || []).length === 0 ? SCORED : undefined;

    return {
        forceType,
        initialValues: { [TYPE]: forceType },
        onSubmit, // `onSubmit` must be specified to form for remote submission to work
    };
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedEvaluationTypeForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        forceType: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        priceTables: PropTypes.array.isRequired, // eslint-disable-line react/no-unused-prop-types
    };

    render() {
        const { disabled, forceType, handleSubmit } = this.props;

        const styles = require('./index.scss');

        return (
            <form className={styles.container} onSubmit={handleSubmit}>
                <p className={`${styles.text} text-muted`}>
                    Creating an evaluation will allow you to collect and score vendor responses to
                    your project.
                </p>
                {!forceType && (
                    <Field
                        component={MultipleChoiceInput}
                        disabled={disabled}
                        label="How would you like to evaluate vendors?"
                        name={TYPE}
                        options={evaluationTypeOptions}
                    />
                )}
            </form>
        );
    }
}

export const EvaluationTypeForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedEvaluationTypeForm);
