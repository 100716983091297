const moment = require('moment');

const { actionTypes } = require('.');
const { reviewTypes } = require('../reviewSequences');

const {
    APPROVE,
    BUDGET_OVERRIDE,
    CLOSED_CANCELLED,
    CLOSED_PO_CREATED,
    CLOSED_REJECTED,
    COMMENT,
    CREATE,
    EDIT,
    OFF_HOLD,
    ON_HOLD,
    REJECT,
    STATUS_CHANGE,
    SUBMIT,
} = actionTypes;

exports.getPositionName = (requisitionLog, requisition) => {
    if (requisitionLog.actionType === COMMENT) {
        return;
    }

    const positionName = requisitionLog.stepPositionApprover?.stepPositionApproval?.position?.name;
    if (positionName) {
        return positionName;
    }
    if (requisitionLog.user_id === requisition.creator_id) {
        return 'Creator';
    }
    if (requisitionLog.user_id === requisition.requestor_id) {
        return 'Requestor';
    }
};

exports.getDetails = (requisitionLog) => {
    switch (requisitionLog.actionType) {
        case EDIT:
            return null;
        default:
            return requisitionLog.details;
    }
};

exports.getInformation = (requisitionLog) => {
    const userName = requisitionLog.user.displayName;
    const date = moment(requisitionLog.date).format('M/D/YY [at] h:mma');

    switch (requisitionLog.actionType) {
        case EDIT: {
            let editedItem = 'the request';
            if (requisitionLog.details === 'allowPriceEdit') {
                editedItem = 'the Purchase Details section';
            } else if (requisitionLog.details === 'allowVendorEdit') {
                editedItem = 'the Vendor Selection section';
            } else if (requisitionLog.details === 'allowGeneralEdit') {
                editedItem = 'the General Information section';
            }
            return `${userName} edited ${editedItem} on ${date}`;
        }
        case COMMENT:
            return date;
        default:
            return `${userName} on ${date}`;
    }
};

const approveData = {
    bsStyle: 'success',
    icon: 'fa-check-circle',
};

const rejectData = {
    bsStyle: 'danger',
    icon: 'fa-times-circle',
};

const getApproveActionData = (requisitionLog) => {
    switch (requisitionLog?.reviewSequenceStep?.reviewType) {
        case reviewTypes.CONFIRM:
            return { ...approveData, text: 'Acknowledged' };
        case reviewTypes.NOTIFY:
            return { ...approveData, icon: 'fa fa-envelope', text: 'Notification Sent', by: 'to' };
        case reviewTypes.APPROVE:
        default:
            return { ...approveData, text: 'Approved' };
    }
};

exports.getActionData = (requisitionLog) => {
    switch (requisitionLog.actionType) {
        case CREATE:
            return { ...approveData, text: 'Created' };
        case SUBMIT:
            return { ...approveData, text: 'Submitted' };
        case APPROVE:
            return getApproveActionData(requisitionLog);
        case BUDGET_OVERRIDE:
            return { bsStyle: 'warning', icon: 'fa-chevron-circle-up', text: 'Budget Override' };
        case REJECT:
            return { ...rejectData, text: 'Rejected' };
        case CLOSED_CANCELLED:
            return { ...rejectData, icon: 'fa-ban', text: 'Closed - Cancelled' };
        case CLOSED_REJECTED:
            return { ...rejectData, icon: 'fa-ban', text: 'Closed - Rejected' };
        case CLOSED_PO_CREATED:
            return { ...approveData, icon: 'fa-usd', text: 'PO Created' };
        case ON_HOLD:
            return { bsStyle: 'warning', icon: 'fa-pause-circle', text: 'Put On Hold' };
        case OFF_HOLD:
            return { bsStyle: 'warning', icon: 'fa-play-circle', text: 'Taken Off Hold' };
        case EDIT:
            return { bsStyle: 'primary', icon: 'fa-pencil', text: 'Edited' };
        case COMMENT:
            return { text: requisitionLog.user.displayName };
        case STATUS_CHANGE:
        default:
            return { ...approveData, icon: 'fa-question-circle', text: 'Unknown Action' };
    }
};
