const get = require('lodash/get');
const isNil = require('lodash/isNil');
const upperFirst = require('lodash/upperFirst');

const { listToDict, normalizeNumberWithCommas } = require('../helpers');

const questionLogicFields = [
    'action',
    'linkable',
    'linkable_id',
    'operator',
    'value',
    'logicable',
    'logicable_id',
];
exports.questionLogicFieldNames = listToDict(questionLogicFields);

exports.questionLogicOperators = [
    'equal',
    'greater',
    'greaterOrEqual',
    'include',
    'lesser',
    'lesserOrEqual',
    'notEqual',
    'notInclude',
];
exports.questionLogicOperatorNames = listToDict(exports.questionLogicOperators);

exports.questionLogicOperatorNamesDisplay = {
    [exports.questionLogicOperatorNames.EQUAL]: 'equals',
    [exports.questionLogicOperatorNames.INCLUDE]: 'includes',
    [exports.questionLogicOperatorNames.NOT_EQUAL]: 'does not equal',
    [exports.questionLogicOperatorNames.NOT_INCLUDE]: 'does not include',
    [exports.questionLogicOperatorNames.GREATER]: '>',
    [exports.questionLogicOperatorNames.GREATER_OR_EQUAL]: '>=',
    [exports.questionLogicOperatorNames.LESSER]: '<',
    [exports.questionLogicOperatorNames.LESSER_OR_EQUAL]: '<=',
};

exports.questionLogicNumericOperators = [
    exports.questionLogicOperatorNames.GREATER,
    exports.questionLogicOperatorNames.GREATER_OR_EQUAL,
    exports.questionLogicOperatorNames.LESSER,
    exports.questionLogicOperatorNames.LESSER_OR_EQUAL,
];

exports.questionLogicActions = ['show', 'hide'];
exports.questionLogicActionNames = listToDict(exports.questionLogicActions);

// If new models are added also update `server/db/polymorphicRelations.js` for `QuestionLogic`
exports.questionLogicLinkableModels = [
    'attachment',
    'criteria',
    'projectSection',
    'questionnaire',
    'scoringCriteria',
    'linkedUpfrontQuestion',
    'linkedQuestionnaire',
    'reviewSequenceStep',
];
exports.questionLogicLinkableModelNames = listToDict(exports.questionLogicLinkableModels);

exports.getYesNoQuestionLogicValueDisplay = (value) => {
    return value ? 'Yes' : 'No';
};

// If new models are added also update `server/db/polymorphicRelations.js` for `QuestionLogic`
exports.questionLogicLogicableModels = [
    'flag',
    'projectField',
    'requisitionField',
    'upfrontQuestion',
    'questionnaire',
];
exports.questionLogicLogicableModelNames = listToDict(exports.questionLogicLogicableModels);
exports.questionLogicLogicableModelAlias = Object.keys(
    exports.questionLogicLogicableModelNames
).reduce((acc, key) => {
    return {
        ...acc,
        [key]: `logicable${upperFirst(exports.questionLogicLogicableModelNames[key])}`,
    };
}, {});

exports.projectFieldLogicableIds = {
    IS_EMERGENCY: 100,
};

exports.requisitionFieldLogicableIds = {
    TOTAL_SPEND: 100,
};

exports.inputValueTypes = {
    DOLLARS: 'dollars',
    SELECT: 'select',
};

const logicableConfig = {
    [exports.questionLogicLogicableModelNames.FLAG]: {
        inputValueOptions: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
        ],
        inputValueType: exports.inputValueTypes.SELECT,
        operatorOptions: [
            exports.questionLogicOperatorNames.EQUAL,
            exports.questionLogicOperatorNames.NOT_EQUAL,
        ],
    },
    [`${exports.questionLogicLogicableModelNames.REQUISITION_FIELD}-${exports.requisitionFieldLogicableIds.TOTAL_SPEND}`]:
        {
            inputValueType: exports.inputValueTypes.DOLLARS,
            operatorOptions: exports.questionLogicNumericOperators,
        },
};

exports.getLogicableConfig = (questionLogic) => {
    const { logicable, logicable_id: logicableId } = questionLogic;
    if (!logicable || isNil(logicableId)) {
        return {};
    }

    return logicableConfig[logicable] || logicableConfig[`${logicable}-${logicableId}`];
};

exports.getQuestionLogicValueDisplay = (questionLogic) => {
    const { value } = questionLogic;
    const { inputValueType } = exports.getLogicableConfig(questionLogic);

    if (Array.isArray(value)) {
        return value
            .map((val) => exports.getQuestionLogicValueDisplay({ ...questionLogic, value: val }))
            .join('" OR "');
    }

    if (typeof value === 'boolean') {
        return exports.getYesNoQuestionLogicValueDisplay(value);
    }

    if (typeof value === 'number') {
        const displayNumber = normalizeNumberWithCommas(value);
        return inputValueType === exports.inputValueTypes.DOLLARS
            ? `$${displayNumber}`
            : displayNumber;
    }

    return value;
};

// TODO: Remove in favor of getQuestionLogicValueDisplay
exports.getQuestionLogicValueDisplayFromQuestion = (linkedUpfrontQuestion, value) => {
    if (Array.isArray(value)) {
        return value
            .map((val) =>
                exports.getQuestionLogicValueDisplayFromQuestion(linkedUpfrontQuestion, val)
            )
            .join('" OR "');
    }

    if (typeof value === 'boolean') {
        return exports.getYesNoQuestionLogicValueDisplay(value);
    }

    if (typeof value === 'number') {
        return get(linkedUpfrontQuestion, `data.options.${value}`);
    }

    return value;
};
