import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fade, ListGroupItem } from 'react-bootstrap';
import { Box } from '@og-pro/ui';

import { qaTagName } from './constants';
import { Button, ContactAddress } from '../../../components';

export const VendorCard = ({
    addToPreInviteList,
    addToVendorList,
    isPreInviteOpen,
    isPreInvited,
    isVendorList,
    timeout,
    vendor,
}) => {
    const {
        address,
        city,
        contactEmail,
        description,
        stateAbbreviation,
        vendorName,
        website,
        zipCode,
    } = vendor;

    const [show, setShow] = useState(false);

    // This logic along with the `timeout` prop is here to make the items fade in one at a time.
    // It's purely for display effect.
    useEffect(() => {
        setTimeout(() => setShow(true), timeout);
    }, []);

    const styles = require('./index.scss');

    return (
        <Fade in={show}>
            <ListGroupItem>
                <div className={styles.vendorListItemCol1}>
                    <Button
                        active={isPreInvited}
                        block
                        bsSize="sm"
                        disabled={!isPreInviteOpen}
                        onClick={
                            isPreInvited || !contactEmail
                                ? undefined
                                : () => addToPreInviteList([contactEmail])
                        }
                        qaTag={`${qaTagName}-addPreInvite`}
                    >
                        {isPreInvited ? (
                            <span className="text-success">
                                <i className="fa fa-check" /> Pre-Invited
                            </span>
                        ) : (
                            <>
                                <i className="fa fa-envelope" /> Add to Pre-Invite List
                            </>
                        )}
                    </Button>
                    <Button
                        active={isVendorList}
                        block
                        bsSize="sm"
                        onClick={
                            isVendorList || !contactEmail
                                ? undefined
                                : () => addToVendorList([contactEmail])
                        }
                        qaTag={`${qaTagName}-addVendorList`}
                        tooltip={
                            isVendorList
                                ? undefined
                                : "Sends an invite email to the vendor and adds them to your organization's vendor list"
                        }
                    >
                        {isVendorList ? (
                            <span className="text-success">
                                <i className="fa fa-check" /> Subscribed
                            </span>
                        ) : (
                            <>
                                <i className="fa fa-user-plus" /> Invite to Portal
                            </>
                        )}
                    </Button>
                    <Button
                        block
                        bsSize="sm"
                        bsStyle="link"
                        href={website}
                        qaTag={`${qaTagName}-website`}
                        rel="noreferrer"
                        target="_blank"
                        zeroPadding
                    >
                        Website <i className="fa fa-external-link" />
                    </Button>
                </div>
                <div className={styles.vendorListItemCol2}>
                    <div>
                        <strong>{vendorName}</strong>
                    </div>
                    {contactEmail && (
                        <div>
                            <a href={`mailto:${contactEmail}`} rel="noreferrer" target="_blank">
                                {contactEmail}
                            </a>
                        </div>
                    )}
                    <ContactAddress
                        address1={address}
                        city={city}
                        state={stateAbbreviation}
                        zipCode={zipCode}
                    />
                    <Box className="text-muted" mt={0.5}>
                        <em>{description}</em>
                    </Box>
                </div>
            </ListGroupItem>
        </Fade>
    );
};

VendorCard.propTypes = {
    addToPreInviteList: PropTypes.func.isRequired,
    addToVendorList: PropTypes.func.isRequired,
    isPreInviteOpen: PropTypes.object.isRequired,
    isPreInvited: PropTypes.bool,
    isVendorList: PropTypes.bool,
    timeout: PropTypes.number.isRequired,
    vendor: PropTypes.object.isRequired,
};
