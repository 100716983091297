import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { projectTypesPathsDict } from '../../../../../../shared_config/projects';
import { createNavItemId } from './utils';
import { getUserJS } from '../../../../containers/selectors';

const { PROJECTS, CONTRACTS } = projectTypesPathsDict;

export const useDashboardNavItems = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector(getUserJS);

    const { pathname } = location;
    const {
        government_id: governmentId,
        government: {
            hasContracting,
            hasDocBuilder,
            hasEvaluation,
            hasRequisitionOnly,
            hasSourcing,
        },
        userPermissions: {
            isContractAdmin,
            isDepartmentContractAdmin,
            isDepartmentEditor,
            isGlobalEditor,
        },
    } = user;

    const navItems = [];
    const subNavItems = [];

    // Do not include dashboard if user does not have certain permissions or modules
    if (
        hasRequisitionOnly ||
        (!isGlobalEditor && !isDepartmentEditor && !isContractAdmin && !isDepartmentContractAdmin)
    ) {
        return navItems;
    }

    const urlRegex = new RegExp(`/dashboard/+(${CONTRACTS})|/dashboard/+(${PROJECTS})`, 'g');
    const isActive = pathname.match(urlRegex);

    if ((hasDocBuilder || hasSourcing || hasEvaluation) && (isGlobalEditor || isDepartmentEditor)) {
        subNavItems.push({
            id: createNavItemId('dashboards-solicitations'),
            label: 'Solicitations',
            onClick: () => navigate(`/governments/${governmentId}/dashboard/${PROJECTS}`),
        });
    }

    if (hasContracting && (isContractAdmin || isDepartmentContractAdmin)) {
        subNavItems.push({
            id: createNavItemId('dashboards-contracts'),
            label: 'Contracts',
            onClick: () => navigate(`/governments/${governmentId}/dashboard/${CONTRACTS}`),
        });
    }

    navItems.push({
        id: createNavItemId('dashboards'),
        label: 'Dashboards',
        submenuSections: [{ items: subNavItems }],
        isActive,
    });

    return navItems;
};
