/* eslint no-use-before-define: ["error", { "functions": false }] */

// NOTE: every callback should dispatch at least a single action describing what happened
// NOTE: if your callback calls another function which _could_ return something (such as a Promise),
// don't forget to return the call to it so that our promise chain isn't broken.
// NOTE: if you create a callback that calls another callback, you're probably doing something that
// doesn't match the pattern of how we use actions. Do it in the reducer via the action.

import { browserHistory } from '@og-pro-migration-tools/react-router';
import { change } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';
import { get } from 'lodash';
import qs from 'qs';

import { showSnackbar, showNotification } from './notification';
import {
    hideConfirmationModal,
    showConfirmationModal,
    showConfirmationModalError,
    updatingConfirmationModal,
} from './confirmation';
import * as menuActions from '../constants/menuActions';
import { resourceManager, trackEvent } from '../helpers';
import request from '../request';
import { convertToDateString, exportArrayToCSV } from '../utils';
import {
    DEFAULT_PAGE,
    DEFAULT_PAGE_SIZE_LIMIT,
    contractFilterTypesDict,
} from '../../../shared_config/contracts';

export const ADD_SUBSCRIBERS = 'contracts/ADD_SUBSCRIBERS';
export const ADD_SUBSCRIBERS_FAIL = 'contracts/ADD_SUBSCRIBERS_FAIL';
export const ADD_SUBSCRIBERS_SUCCESS = 'contracts/ADD_SUBSCRIBERS_SUCCESS';
export const ATTACHMENT_PROGRESS = 'contracts/ATTACHMENT_PROGRESS'; // This tracks the entire process, not just the upload to S3
export const CHANGE_CONTRACT_PERMISSION = 'contracts/CHANGE_CONTRACT_PERMISSION';
export const CHANGE_CONTRACT_PERMISSION_FAIL = 'contracts/CHANGE_CONTRACT_PERMISSION_FAIL';
export const CHANGE_CONTRACT_PERMISSION_SUCCESS = 'contracts/CHANGE_CONTRACT_PERMISSION_SUCCESS';
export const PENDING_CONTRACT_DOCUMENT_CREATED = 'contracts/PENDING_CONTRACT_DOCUMENT_CREATED';
export const PENDING_CONTRACT_DOCUMENT_APPROVED = 'contracts/PENDING_CONTRACT_DOCUMENT_APPROVED';
export const PENDING_CONTRACT_DOCUMENT_REJECTED = 'contracts/PENDING_CONTRACT_DOCUMENT_REJECTED';
export const CREATE = 'contracts/CREATE';
export const CREATE_ATTACHMENT = 'contracts/CREATE_ATTACHMENT';
export const CREATE_ATTACHMENT_FAIL = 'contracts/CREATE_ATTACHMENT_FAIL';
export const CREATE_ATTACHMENT_SUCCESS = 'contracts/CREATE_ATTACHMENT_SUCCESS';
export const CREATE_CONTRACT_COMPLAINT_SUCCESS = 'contracts/CREATE_CONTRACT_COMPLAINT_SUCCESS';
export const CREATE_CONTRACT_PUBLIC_AUDIT_LOG_SUCCESS =
    'contracts/CREATE_CONTRACT_PUBLIC_AUDIT_LOG_SUCCESS';
export const CREATE_CONTRACT_REVIEW_SUCCESS = 'contracts/CREATE_CONTRACT_REVIEW_SUCCESS';
export const CREATE_FAIL = 'contracts/CREATE_FAIL';
export const CREATE_SUCCESS = 'contracts/CREATE_SUCCESS';
export const DELETE_ATTACHMENT_SUCCESS = 'contracts/DELETE_ATTACHMENT_SUCCESS';
export const DELETE_CONTRACT_COMPLAINT_SUCCESS = 'contracts/DELETE_CONTRACT_COMPLAINT_SUCCESS';
export const DELETE_CONTRACT_REVIEW_SUCCESS = 'contracts/DELETE_CONTRACT_REVIEW_SUCCESS';
export const DELETE = 'contracts/DELETE';
export const DELETE_FAIL = 'contracts/DELETE_FAIL';
export const DELETE_SUCCESS = 'contracts/DELETE_SUCCESS';
export const GET_ATTACHMENT_S3_URL = 'contracts/GET_ATTACHMENT_S3_URL';
export const GET_ATTACHMENT_S3_URL_FAIL = 'contracts/GET_ATTACHMENT_S3_URL_FAIL';
export const GET_ATTACHMENT_S3_URL_SUCCESS = 'contracts/GET_ATTACHMENT_S3_URL_SUCCESS';
export const HIDE_CONTRACT_COMPLAINT_MODAL = 'contracts/HIDE_CONTRACT_COMPLAINT_MODAL';
export const HIDE_DEPARTMENT_MODAL = 'contracts/HIDE_DEPARTMENT_MODAL';
export const HIDE_PERMISSIONS_MODAL = 'contracts/HIDE_PERMISSIONS_MODAL';
export const LOAD = 'contracts/LOAD';
export const LOAD_CONTRACT_CHECKLISTS = 'contracts/LOAD_CONTRACT_CHECKLISTS';
export const LOAD_CONTRACT_CHECKLISTS_FAIL = 'contracts/LOAD_CONTRACT_CHECKLISTS_FAIL';
export const LOAD_CONTRACT_CHECKLISTS_SUCCESS = 'contracts/LOAD_CONTRACT_CHECKLISTS_SUCCESS';
export const LOAD_CONTRACT_LINE_ITEMS = 'contracts/LOAD_CONTRACT_LINE_ITEMS';
export const LOAD_CONTRACT_LINE_ITEMS_FAIL = 'contracts/LOAD_CONTRACT_LINE_ITEMS_FAIL';
export const LOAD_CONTRACT_LINE_ITEMS_SUCCESS = 'contracts/LOAD_CONTRACT_LINE_ITEMS_SUCCESS';
export const LOAD_CONTRACT_REVIEW = 'contracts/LOAD_CONTRACT_REVIEW';
export const LOAD_CONTRACT_REVIEW_FAIL = 'contracts/LOAD_CONTRACT_REVIEW_FAIL';
export const LOAD_CONTRACT_REVIEW_SUCCESS = 'contracts/LOAD_CONTRACT_REVIEW_SUCCESS';
export const LOAD_CONTRACT_REVIEWS = 'contracts/LOAD_CONTRACT_REVIEWS';
export const LOAD_CONTRACT_REVIEWS_FAIL = 'contracts/LOAD_CONTRACT_REVIEWS_FAIL';
export const LOAD_CONTRACT_REVIEWS_SUCCESS = 'contracts/LOAD_CONTRACT_REVIEWS_SUCCESS';
export const LOAD_CONTRACTS = 'contracts/LOAD_CONTRACTS';
export const LOAD_CONTRACTS_FAIL = 'contracts/LOAD_CONTRACTS_FAIL';
export const LOAD_CONTRACTS_SUCCESS = 'contracts/LOAD_CONTRACTS_SUCCESS';
export const LOAD_CONTRACTS_FOR_CSV_EXPORT = 'contracts/LOAD_CONTRACTS_FOR_CSV_EXPORT';
export const LOAD_CONTRACTS_FOR_CSV_EXPORT_SUCCESS =
    'contracts/LOAD_CONTRACTS_FOR_CSV_EXPORT_SUCCESS';
export const LOAD_CONTRACT_NOTIFICATIONS = 'contracts/LOAD_CONTRACT_NOTIFICATIONS';
export const LOAD_CONTRACT_NOTIFICATIONS_FAIL = 'contracts/LOAD_CONTRACT_NOTIFICATIONS_FAIL';
export const LOAD_CONTRACT_NOTIFICATIONS_SUCCESS = 'contracts/LOAD_CONTRACT_NOTIFICATIONS_SUCCESS';
export const LOAD_CONTRACT_PUBLIC_AUDIT_LOGS = 'contracts/LOAD_CONTRACT_PUBLIC_AUDIT_LOGS';
export const LOAD_CONTRACT_PUBLIC_AUDIT_LOGS_FAIL =
    'contracts/LOAD_CONTRACT_PUBLIC_AUDIT_LOGS_FAIL';
export const LOAD_CONTRACT_PUBLIC_AUDIT_LOGS_SUCCESS =
    'contracts/LOAD_CONTRACT_PUBLIC_AUDIT_LOGS_SUCCESS';
export const LOAD_PURCHASE_ORDERS = 'contracts/LOAD_PURCHASE_ORDERS';
export const LOAD_PURCHASE_ORDERS_FAIL = 'contracts/LOAD_PURCHASE_ORDERS_FAIL';
export const LOAD_PURCHASE_ORDERS_SUCCESS = 'contracts/LOAD_PURCHASE_ORDERS_SUCCESS';
export const LOAD_VENDOR_EMAIL_AUDITS_SUCCESS = 'gov/contracts/LOAD_VENDOR_EMAIL_AUDITS_SUCCESS';
export const LOAD_FAIL = 'contracts/LOAD_FAIL';
export const LOAD_SUCCESS = 'contracts/LOAD_SUCCESS';
export const REMOVE_SUBSCRIBER = 'contracts/REMOVE_SUBSCRIBER';
export const REMOVE_SUBSCRIBER_FAIL = 'contracts/REMOVE_SUBSCRIBER_FAIL';
export const REMOVE_SUBSCRIBER_SUCCESS = 'contracts/REMOVE_SUBSCRIBER_SUCCESS';
export const RESET = 'contracts/RESET';
export const RESET_CONTRACT_BUDGET = 'contracts/RESET_CONTRACT_BUDGET';
export const RESET_LOADED_CONTRACTS = 'contracts/RESET_LOADED_CONTRACTS';
export const SHOW_CONTRACT_COMPLAINT_MODAL = 'contracts/SHOW_CONTRACT_COMPLAINT_MODAL';
export const SHOW_DEPARTMENT_MODAL = 'contracts/SHOW_DEPARTMENT_MODAL';
export const SHOW_PERMISSIONS_MODAL = 'contracts/SHOW_PERMISSIONS_MODAL';
export const UPDATE = 'contracts/UPDATE';
export const UPDATE_FAIL = 'contracts/UPDATE_FAIL';
export const UPDATE_BUDGET_SUCCESS = 'contracts/UPDATE_BUDGET_SUCCESS';
export const UPDATE_CONTACTS = 'contracts/UPDATE_CONTACTS';
export const UPDATE_CONTACTS_FAIL = 'contracts/UPDATE_CONTACTS_FAIL';
export const UPDATE_CONTACTS_SUCCESS = 'contracts/UPDATE_CONTACTS_SUCCESS';
export const UPDATE_INSURANCES = 'contracts/UPDATE_INSURANCES';
export const UPDATE_INSURANCES_FAIL = 'contracts/UPDATE_INSURANCES_FAIL';
export const UPDATE_INSURANCES_SUCCESS = 'contracts/UPDATE_INSURANCES_SUCCESS';
export const UPDATE_MILESTONE_NOTIFICATIONS = 'contracts/UPDATE_MILESTONE_NOTIFICATIONS';
export const UPDATE_MILESTONE_NOTIFICATIONS_FAIL = 'contracts/UPDATE_MILESTONE_NOTIFICATIONS_FAIL';
export const UPDATE_MILESTONE_NOTIFICATIONS_SUCCESS =
    'contracts/UPDATE_MILESTONE_NOTIFICATIONS_SUCCESS';
export const UPDATE_RENEWAL = 'contracts/UPDATE_RENEWAL';
export const UPDATE_RENEWAL_FAIL = 'contracts/UPDATE_RENEWAL_FAIL';
export const UPDATE_RENEWAL_SUCCESS = 'contracts/UPDATE_RENEWAL_SUCCESS';
export const UPDATE_SUCCESS = 'contracts/UPDATE_SUCCESS';
export const UPDATE_CONTRACT_COMPLAINT_SUCCESS = 'contracts/UPDATE_CONTRACT_COMPLAINT_SUCCESS';
export const UPDATE_CONTRACT_REVIEW_SUCCESS = 'contracts/UPDATE_CONTRACT_REVIEW_SUCCESS';
export const UPLOAD_ATTACHMENT = 'contracts/UPLOAD_ATTACHMENT';
export const UPLOAD_ATTACHMENT_TO_S3 = 'contracts/UPLOAD_ATTACHMENT_TO_S3';
export const UPLOAD_ATTACHMENT_TO_S3_FAIL = 'contracts/UPLOAD_ATTACHMENT_TO_S3_FAIL';
export const UPLOAD_ATTACHMENT_TO_S3_SUCCESS = 'contracts/UPLOAD_ATTACHMENT_TO_S3_SUCCESS';

// Ensure we convert the dates from the user's organization's time to UTC
const serializeContract = (contractData, timezone) => {
    const serializedContract = { ...contractData };
    const { contractMilestones, endDate, startDate } = serializedContract;

    if (startDate) {
        serializedContract.startDate = convertToDateString(startDate, timezone);
    }

    if (endDate) {
        serializedContract.endDate = convertToDateString(endDate, timezone);
    }

    if (contractMilestones) {
        serializedContract.contractMilestones = contractMilestones.map((milestone) => {
            if (milestone.isStartDate) {
                return {
                    ...milestone,
                    date: serializedContract.startDate,
                };
            }
            if (milestone.isEndDate) {
                return {
                    ...milestone,
                    date: serializedContract.endDate,
                };
            }
            return {
                ...milestone,
                date: convertToDateString(milestone.date, timezone),
            };
        });
    }

    return serializedContract;
};

const generateAddContractSubscribersOptions = ({ context }) => {
    return { data: context.contractSubscriberData };
};

const generateChangeContractPermissionOptions = ({ context }) => {
    return {
        data: {
            role: context.role,
            userId: context.userId,
        },
    };
};

const generateCreateContractAttachmentOptions = ({ context }) => {
    return {
        data: {
            bucket: context.signedData.bucket,
            contractId: context.contractId,
            filename: context.signedData.filename,
            path: context.signedData.key,
        },
    };
};

const generateCreateContractOptions = ({ context, getState }) => {
    // Ensure we convert the dates from the user's organization's time to UTC
    const timezone = getState().auth.getIn(['user', 'organization', 'timezone']);
    const contractData = serializeContract(context.contractData, timezone);

    return { data: contractData };
};

const generateLoadContractOptions = ({ context }) => {
    const params = {};

    if (context.publicView) {
        params.publicView = context.publicView;
    }

    return { params };
};

const generateLoadContractsOptions = ({ context }) => {
    const data = {
        limit: context.limit ?? DEFAULT_PAGE_SIZE_LIMIT,
        page: context.page ?? DEFAULT_PAGE,
    };

    if (context.filters) {
        data.filters = context.filters;
    }

    // When contract IDs are present, this is the only filter to filter contracts on
    // This case is when the user clicks "Total Active Contracts" or "Total Active Contracts with DBE" from the Contracts Dashboard
    // context.ids is a comma separated string, but it needs to be an array of strings for the API
    if (context.ids) {
        data.filters = [{ type: contractFilterTypesDict.IDS, value: context.ids.split(',') }];
    }

    if (context.sort) {
        data.sort = context.sort;
    }

    if (context.quickSearchQuery) {
        data.quickSearchQuery = context.quickSearchQuery;
    }

    if (context.governmentCode) {
        data.governmentCode = context.governmentCode;
    }

    if (context.dashboardView) {
        data.dashboardView = context.dashboardView;
    }

    if (context.projectId) {
        data.projectId = context.projectId;
    }

    if (context.publicView) {
        data.publicView = context.publicView;
    }

    if (context.exportAsCSV) {
        data.exportAsCSV = context.exportAsCSV;
    }

    return { data };
};

const generateUploadContractAttachmentS3UrlOptions = ({ context }) => {
    return {
        params: {
            contentType: context.file.type,
            filename: context.file.name,
        },
    };
};

const generateUpdateContractContactsOptions = ({ context }) => {
    return { data: context.contactData };
};

const generateUpdateContractInsurancesOptions = ({ context }) => {
    return { data: context.insuranceData };
};

const generateUpdateContractOptions = ({ context, getState }) => {
    // Ensure we convert the dates from the user's organization's time to UTC
    const timezone = getState().auth.getIn(['user', 'organization', 'timezone']);
    const contractData = serializeContract(context.contractData, timezone);

    if (contractData.attachments) {
        contractData.attachments = contractData.attachments.map((attachment) => {
            const dataForServer = {
                id: attachment.id,
                isPublic: get(attachment, ['contractAttachment', 'isPublic'], false),
            };
            if (attachment.tags) {
                dataForServer.tags = { id: attachment.tags };
            }
            return dataForServer;
        });
    }

    return { data: contractData };
};

const generateUpdateContractMilestoneNotificationsOptions = ({ context }) => {
    return { data: context.contractMilestoneNotificationsData };
};

const generateUpdateContractRenewalOptions = ({ context }) => {
    return { data: context.renewalData };
};

const onAddContractSubscribersFailure = ({ error, dispatch }) => {
    dispatch({ type: ADD_SUBSCRIBERS_FAIL, error });
};

const onAddContractSubscribersStart = ({ dispatch }) => {
    dispatch({ type: ADD_SUBSCRIBERS });
};

const onAddContractSubscribersSuccess = ({ context, result, dispatch }) => {
    dispatch({ type: ADD_SUBSCRIBERS_SUCCESS, result });
    if (context.onSuccess) {
        context.onSuccess();
    }
};

const onChangeContractPermissionFailure = ({ error, dispatch }) => {
    dispatch({ type: CHANGE_CONTRACT_PERMISSION_FAIL, error });
    return error;
};

const onChangeContractPermissionStart = ({ dispatch }) => {
    dispatch({ type: CHANGE_CONTRACT_PERMISSION });
};

const onChangeContractPermissionSuccess = ({ context, result, dispatch }) => {
    dispatch({
        type: CHANGE_CONTRACT_PERMISSION_SUCCESS,
        result,
        role: context.role,
    });
};

const onCreateContractAttachmentFailure = ({ context, error, dispatch }) => {
    dispatch({ type: CREATE_ATTACHMENT_FAIL, error, uploadId: context.uploadId });
    dispatch(showSnackbar(`Upload failed: ${error.message}`, { isError: true }));
    return error;
};

const onCreateContractAttachmentStart = ({ context, dispatch }) => {
    dispatch({ type: CREATE_ATTACHMENT, uploadId: context.uploadId });
};

const onCreateContractAttachmentSuccess = ({ context, result, dispatch }) => {
    dispatch({ type: ATTACHMENT_PROGRESS, progress: 100, uploadId: context.uploadId });
    dispatch({ type: CREATE_ATTACHMENT_SUCCESS, result, uploadId: context.uploadId });
    return result;
};

const onCreateContractFailure = ({ error, dispatch }) => {
    dispatch({ type: CREATE_FAIL, error });
    return error;
};

const onCreateContractStart = ({ context, dispatch }) => {
    dispatch({ type: CREATE });

    if (context.contractData.projectId) {
        browserHistory.push(
            `/governments/${context.contractData.governmentId}/projects/${context.contractData.projectId}/contracts/create`
        );
    } else {
        browserHistory.push(`/governments/${context.contractData.governmentId}/contracts/create`);
    }
};

const onCreateContractSuccess = ({ result, dispatch }) => {
    trackEvent('Contract Created', { contractId: result.id });

    dispatch({ type: CREATE_SUCCESS, result });

    return result;
};

const onDeleteContractAttachmentSuccess = ({ result, dispatch }) => {
    dispatch({ type: DELETE_ATTACHMENT_SUCCESS, attachmentId: result.id });
    dispatch(showSnackbar('Attachment deleted'));
    return result;
};

const onDeleteContractFailure = ({ error, dispatch }) => {
    dispatch({ type: DELETE_FAIL, error });
    dispatch(showSnackbar(`Failed to delete contract: ${error.message}`, { isError: true }));
};

const onDeleteContractStart = ({ dispatch }) => {
    dispatch({ type: DELETE });
};

const onDeleteContractSuccess = ({ context, dispatch, getState }) => {
    const govId = getState().auth.getIn(['user', 'government_id']);

    if (context.projectId) {
        browserHistory.push(`/governments/${govId}/projects/${context.projectId}`);
    } else {
        browserHistory.push(`/governments/${govId}/contracts`);
    }

    dispatch({ type: DELETE_SUCCESS });
    dispatch(showSnackbar('Contract deleted'));
};

const onLoadContractsFailure = ({ context, error, dispatch }) => {
    dispatch({ type: LOAD_CONTRACTS_FAIL, error });

    if (context.rethrowError) {
        throw error;
    }
};

const onLoadContractsStart = ({ dispatch }) => {
    dispatch({ type: LOAD_CONTRACTS });
};

const onLoadContractsForCSVExportStart = ({ dispatch }) => {
    dispatch({ type: LOAD_CONTRACTS_FOR_CSV_EXPORT });
};

const onLoadContractsSuccess = ({ context, result, dispatch }) => {
    dispatch({
        type: LOAD_CONTRACTS_SUCCESS,
        result: result.contracts,
        count: result.count,
        contractProcurementContacts: result.contractProcurementContacts,
    });

    // Not the most ideal place to be doing route changes, but it's the most reliable to ensure
    // there are query params in the URL that can be used for searching, filtering, etc.
    // Checks are in place so this only happens on the gov contracts page and we don't do needless
    // route changes or accidentally get into an infinite loop.
    const location = browserHistory.location();
    const search = `?${qs.stringify(result.queryParams)}`;
    const updateQueryParams = !context.projectId && !context.dashboardView && !context.publicView;

    if (updateQueryParams && location.search !== search && !context.stopNavigation) {
        browserHistory.push({
            pathname: location.pathname,
            search,
        });
    }

    return result.contracts;
};

const onLoadContractsForCSVExportSuccess = ({ result, dispatch }) => {
    dispatch({ type: LOAD_CONTRACTS_FOR_CSV_EXPORT_SUCCESS });

    return result;
};

const onLoadContractFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_FAIL, error });
};

const onLoadContractStart = ({ dispatch }) => {
    dispatch({ type: LOAD });
};

const onLoadContractSuccess = ({ result, dispatch }) => {
    dispatch({ type: LOAD_SUCCESS, result });
};

const onLoadContractNotificationsFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_CONTRACT_NOTIFICATIONS_FAIL, error });
};

const onLoadContractNotificationsStart = ({ dispatch }) => {
    dispatch({ type: LOAD_CONTRACT_NOTIFICATIONS });
};

const onLoadContractNotificationsSuccess = ({ result, dispatch }) => {
    dispatch({ type: LOAD_CONTRACT_NOTIFICATIONS_SUCCESS, result });
};

const onLoadContractReviewFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_CONTRACT_REVIEW_FAIL, error });
};

const onLoadContractReviewStart = ({ dispatch }) => {
    dispatch({ type: LOAD_CONTRACT_REVIEW });
};

const onLoadContractReviewSuccess = ({ result, dispatch }) => {
    dispatch({ type: LOAD_CONTRACT_REVIEW_SUCCESS, result });
};

const onRemoveContractSubscriberFailure = ({ context, error, dispatch }) => {
    dispatch({
        type: REMOVE_SUBSCRIBER_FAIL,
        error,
        contractSubscriberId: context.contractSubscriberId,
    });
};

const onRemoveContractSubscriberStart = ({ context, dispatch }) => {
    dispatch({ type: REMOVE_SUBSCRIBER, contractSubscriberId: context.contractSubscriberId });
};

const onRemoveContractSubscriberSuccess = ({ result, dispatch }) => {
    dispatch({ type: REMOVE_SUBSCRIBER_SUCCESS, result });
    return result;
};

const onUpdateContractFailure = ({ error, dispatch }) => {
    dispatch({ type: UPDATE_FAIL, error });
};

const onUpdateContractBudgetSuccess = ({ dispatch, result }) => {
    dispatch({ type: UPDATE_BUDGET_SUCCESS, result });
    dispatch(showSnackbar('Contract amount updated'));
};

const onUpdateContractContactsFailure = ({ error, dispatch }) => {
    dispatch({ type: UPDATE_CONTACTS_FAIL, error });
};

const onUpdateContractContactsStart = ({ dispatch }) => {
    dispatch({ type: UPDATE_CONTACTS });
};

const onUpdateContractContactsSuccess = ({ result, dispatch }) => {
    dispatch({ type: UPDATE_CONTACTS_SUCCESS, result });

    dispatch(showSnackbar('Contacts Updated'));
};

const onUpdateContractInsurancesFailure = ({ error, dispatch }) => {
    dispatch({ type: UPDATE_INSURANCES_FAIL, error });
};

const onUpdateContractInsurancesStart = ({ dispatch }) => {
    dispatch({ type: UPDATE_INSURANCES });
};

const onUpdateContractInsurancesSuccess = ({ result, dispatch }) => {
    dispatch({ type: UPDATE_INSURANCES_SUCCESS, result });

    dispatch(showSnackbar('Insurance Updated'));
};

const onUpdateContractMilestoneNotificationsFailure = ({ context, error, dispatch }) => {
    dispatch({
        type: UPDATE_MILESTONE_NOTIFICATIONS_FAIL,
        error,
        milestoneId: context.milestoneId,
    });
};

const onUpdateContractMilestoneNotificationsStart = ({ context, dispatch }) => {
    dispatch({ type: UPDATE_MILESTONE_NOTIFICATIONS, milestoneId: context.milestoneId });
};

const onUpdateContractMilestoneNotificationsSuccess = ({ context, result, dispatch }) => {
    dispatch({
        type: UPDATE_MILESTONE_NOTIFICATIONS_SUCCESS,
        result,
        milestoneId: context.milestoneId,
    });
    dispatch(showSnackbar('Notifications updated'));

    if (context.onSuccess) {
        context.onSuccess();
    }

    return result;
};

const onUpdateContractRenewalFailure = ({ context, error, dispatch }) => {
    dispatch({
        type: UPDATE_RENEWAL_FAIL,
        error,
        renewalId: context.renewalId,
    });
};

const onUpdateContractRenewalStart = ({ context, dispatch }) => {
    dispatch({ type: UPDATE_RENEWAL, milestoneId: context.milestoneId });
};

const onUpdateContractRenewalSuccess = ({ context, result, dispatch }) => {
    dispatch({
        type: UPDATE_RENEWAL_SUCCESS,
        result,
        renewalId: context.renewalId,
    });
    dispatch(showSnackbar('Renewal updated'));

    if (context.onSuccess) {
        context.onSuccess(result);
    }

    return result;
};

const onUpdateContractStart = ({ dispatch }) => {
    dispatch({ type: UPDATE });
};

const onUpdateContractSuccess = ({ context, result, dispatch }) => {
    dispatch({ type: UPDATE_SUCCESS, result });
    dispatch(showSnackbar('Contract updated'));
    if (context.onSuccess) {
        context.onSuccess(result);
    }
    return result;
};

const onUploadContractAttachmentToS3Start = (context, dispatch) => {
    dispatch({ type: UPLOAD_ATTACHMENT_TO_S3, uploadId: context.uploadId });
};

const onUploadContractAttachmentToS3Failure = (context, error, dispatch) => {
    dispatch({ type: UPLOAD_ATTACHMENT_TO_S3_FAIL, error, uploadId: context.uploadId });
    dispatch(showSnackbar(`Upload failed: ${error.message}`, { isError: true }));
};

const onUploadContractAttachmentToS3Success = (context, signedData, dispatch) => {
    dispatch({
        type: UPLOAD_ATTACHMENT_TO_S3_SUCCESS,
        result: signedData,
        uploadId: context.uploadId,
    });
    dispatch({
        type: ATTACHMENT_PROGRESS,
        progress: 95, // Just incase the `onProgress` callback wasn't called with a high value
        uploadId: context.uploadId,
    });

    return dispatch(createContractAttachment(context, signedData));
};

const onUploadContractAttachmentS3UrlFailure = ({ context, error, dispatch }) => {
    dispatch({ type: GET_ATTACHMENT_S3_URL_FAIL, error, uploadId: context.uploadId });
    dispatch(showSnackbar(`Upload failed: ${error.message}`, { isError: true }));
};

const onUploadContractAttachmentS3UrlStart = ({ context, dispatch }) => {
    dispatch({ type: GET_ATTACHMENT_S3_URL, uploadId: context.uploadId, file: context.file });
};

const onUploadContractAttachmentS3UrlSuccess = ({ context, result, dispatch }) => {
    dispatch({
        type: GET_ATTACHMENT_S3_URL_SUCCESS,
        result,
        uploadId: context.uploadId,
    });
    // We'll say that we are 5% done at this point
    dispatch({ type: ATTACHMENT_PROGRESS, progress: 5, uploadId: context.uploadId });

    return uploadContractAttachmentToS3(context, result, dispatch);
};

function createContractAttachment(context, signedData) {
    return resourceManager({
        method: 'post',
        url: `/contracts/${context.contractId}/attachments`,
        requestOptions: generateCreateContractAttachmentOptions,
        onStart: onCreateContractAttachmentStart,
        onSuccess: onCreateContractAttachmentSuccess,
        onFailure: onCreateContractAttachmentFailure,
        context: { ...context, signedData },
    });
}

export function createContract(contractData) {
    return resourceManager({
        method: 'post',
        url: '/contracts',
        requestOptions: generateCreateContractOptions,
        onStart: onCreateContractStart,
        onSuccess: onCreateContractSuccess,
        onFailure: onCreateContractFailure,
        context: { contractData },
    });
}

export function createContractChecklist(contractId, data) {
    return resourceManager({
        method: 'post',
        url: `/contracts/${contractId}/checklists`,
        requestOptions: { data },
    });
}

export function createContractComplaint(contractId, contractReviewId, data) {
    return resourceManager({
        method: 'post',
        url: `/contracts/${contractId}/reviews/${contractReviewId}/complaints`,
        requestOptions: { data },
        onSuccess: ({ dispatch, result }) =>
            dispatch({ type: CREATE_CONTRACT_COMPLAINT_SUCCESS, result }),
    });
}

export function createContractReview(contractId) {
    return resourceManager({
        method: 'post',
        url: `/contracts/${contractId}/reviews`,
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: CREATE_CONTRACT_REVIEW_SUCCESS, result });
            return result;
        },
    });
}

export function addContractSubscribers(contractId, contractSubscriberData, onSuccess) {
    return resourceManager({
        method: 'post',
        url: `/contracts/${contractId}/subscribers`,
        requestOptions: generateAddContractSubscribersOptions,
        onStart: onAddContractSubscribersStart,
        onSuccess: onAddContractSubscribersSuccess,
        onFailure: onAddContractSubscribersFailure,
        context: { contractId, contractSubscriberData, onSuccess },
    });
}

export function changeDepartmentForm(values, formName) {
    return (dispatch) => {
        dispatch(change(formName, 'departmentName', values.name));
        dispatch(change(formName, 'departmentHead', values.departmentHead));
        dispatch(change(formName, 'departmentHeadTitle', values.departmentHeadTitle));
    };
}

export function changeContractPermission(contractId, userId, role) {
    return resourceManager({
        method: 'post',
        url: `/contracts/${contractId}/permissions`,
        requestOptions: generateChangeContractPermissionOptions,
        onStart: onChangeContractPermissionStart,
        onSuccess: onChangeContractPermissionSuccess,
        onFailure: onChangeContractPermissionFailure,
        context: { role, userId },
    });
}

export function createPurchaseOrder(contractId, data) {
    return resourceManager({
        method: 'post',
        url: `/contracts/${contractId}/budget/purchase-orders`,
        requestOptions: { data },
        onSuccess: ({ dispatch, result }) => {
            dispatch(loadContractLineItems(contractId));
            dispatch(showSnackbar('Order created!'));
            return dispatch({ type: LOAD_PURCHASE_ORDERS_SUCCESS, result });
        },
    });
}

export function deleteContract(contractId, projectId) {
    return resourceManager({
        method: 'del',
        url: `/contracts/${contractId}`,
        requestOptions: undefined,
        onStart: onDeleteContractStart,
        onSuccess: onDeleteContractSuccess,
        onFailure: onDeleteContractFailure,
        context: { projectId },
    });
}

export function deleteContractAttachment(contractId, attachmentId) {
    return resourceManager({
        method: 'del',
        url: `/contracts/${contractId}/attachments/${attachmentId}`,
        requestOptions: undefined,
        onSuccess: onDeleteContractAttachmentSuccess,
        context: { attachmentId },
    });
}

export function deleteContractComplaint(contractId, contractReviewId, contractComplaintId) {
    return resourceManager({
        method: 'del',
        url: `/contracts/${contractId}/reviews/${contractReviewId}/complaints/${contractComplaintId}`,
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: DELETE_CONTRACT_COMPLAINT_SUCCESS, result });
        },
    });
}

export function deleteContractReview(contractId, contractReviewId, onSuccess) {
    return resourceManager({
        method: 'del',
        url: `/contracts/${contractId}/reviews/${contractReviewId}`,
        onSuccess: ({ dispatch, result }) => {
            onSuccess(result);
            dispatch({ type: DELETE_CONTRACT_REVIEW_SUCCESS, result });
        },
    });
}

export function deletePurchaseOrder(contractId, purchaseOrderId) {
    return resourceManager({
        method: 'del',
        url: `/contracts/${contractId}/budget/purchase-orders/${purchaseOrderId}`,
        onSuccess: ({ dispatch, result }) => {
            dispatch(loadContractLineItems(contractId));
            dispatch(showSnackbar('Order deleted'));
            return dispatch({ type: LOAD_PURCHASE_ORDERS_SUCCESS, result });
        },
    });
}

export function hideContractComplaintModal() {
    return {
        type: HIDE_CONTRACT_COMPLAINT_MODAL,
    };
}

export function hideDepartmentModal() {
    return {
        type: HIDE_DEPARTMENT_MODAL,
    };
}

export function hidePermissionsModal() {
    return {
        type: HIDE_PERMISSIONS_MODAL,
    };
}

export function loadContract(contractId, publicView) {
    return resourceManager({
        method: 'get',
        url: `/contracts/${contractId}`,
        requestOptions: generateLoadContractOptions,
        onStart: onLoadContractStart,
        onSuccess: onLoadContractSuccess,
        onFailure: onLoadContractFailure,
        context: { publicView },
    });
}

export function loadContractBudget(contractId) {
    return resourceManager({
        method: 'get',
        url: `/contracts/${contractId}/budget`,
    });
}

export function loadContractBudgetPurchaseOrders(contractId) {
    return resourceManager({
        method: 'get',
        url: `/contracts/${contractId}/budget/purchase-orders`,
        onStart: ({ dispatch }) => {
            return dispatch({ type: LOAD_PURCHASE_ORDERS });
        },
        onSuccess: ({ dispatch, result }) => {
            return dispatch({ type: LOAD_PURCHASE_ORDERS_SUCCESS, result });
        },
        onFailure: ({ dispatch, error }) => {
            return dispatch({ type: LOAD_PURCHASE_ORDERS_FAIL, error: error.message });
        },
    });
}

export function loadContractNotifications(contractId) {
    return resourceManager({
        method: 'get',
        url: `/contracts/${contractId}/milestones/notifications`,
        onStart: onLoadContractNotificationsStart,
        onSuccess: onLoadContractNotificationsSuccess,
        onFailure: onLoadContractNotificationsFailure,
    });
}

export function loadContractReview(contractId, contractReviewId) {
    return resourceManager({
        method: 'get',
        url: `/contracts/${contractId}/reviews/${contractReviewId}`,
        onStart: onLoadContractReviewStart,
        onSuccess: onLoadContractReviewSuccess,
        onFailure: onLoadContractReviewFailure,
    });
}

export function loadContractReviews(contractId) {
    return resourceManager({
        method: 'get',
        url: `/contracts/${contractId}/reviews`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_CONTRACT_REVIEWS }),
        onSuccess: ({ dispatch, result }) =>
            dispatch({ type: LOAD_CONTRACT_REVIEWS_SUCCESS, result }),
        onFailure: ({ dispatch, error }) => dispatch({ type: LOAD_CONTRACT_REVIEWS_FAIL, error }),
    });
}

/**
 * Load the list of contracts.
 * @param {object} options The set of options to pass to the request context
 * @param {string} [options.governmentCode] A governmentCode to filter the list by
 * @param {boolean} [options.dashboardView] Indicates data is for the contracts dashboard
 * @param {number} [options.projectId] A projectId to filter the list by
 * @param {object[]} [options.filters] A list of additional filters to filer the list by
 * @param {boolean} [options.publicView] Filters the list based on if the contracts are public
 * @param {boolean} [options.rethrowError] Rethrows error on failure
 * @param {boolean} [options.stopNavigation] Stops the page from being navigated on search
 * @return {Promise<object[]>} The list of contracts
 */
export function loadContracts(options) {
    return resourceManager({
        method: 'post',
        url: '/contracts/list',
        requestOptions: generateLoadContractsOptions,
        onStart: onLoadContractsStart,
        onSuccess: onLoadContractsSuccess,
        onFailure: onLoadContractsFailure,
        context: options,
    });
}

export function loadContractsRaw(options) {
    return resourceManager({
        method: 'post',
        url: '/contracts/list',
        requestOptions: generateLoadContractsOptions,
        context: options,
    });
}

export function loadContractsForCSVExport(options) {
    return resourceManager({
        method: 'post',
        url: '/contracts/list',
        requestOptions: generateLoadContractsOptions,
        onStart: onLoadContractsForCSVExportStart,
        onSuccess: onLoadContractsForCSVExportSuccess,
        onFailure: onLoadContractsFailure,
        context: options,
    });
}

export function removeContractSubscriber(contractId, contractSubscriberId) {
    return resourceManager({
        method: 'del',
        url: `/contracts/${contractId}/subscribers/${contractSubscriberId}`,
        requestOptions: undefined,
        onStart: onRemoveContractSubscriberStart,
        onSuccess: onRemoveContractSubscriberSuccess,
        onFailure: onRemoveContractSubscriberFailure,
        context: { contractSubscriberId },
    });
}

export function resetContract() {
    return { type: RESET };
}

export function resetLoadedContracts() {
    return (dispatch) => {
        dispatch({ type: RESET_LOADED_CONTRACTS });
    };
}

export function resetContractBudget() {
    return { type: RESET_CONTRACT_BUDGET };
}

export function showContractComplaintModal(data) {
    return (dispatch) => {
        dispatch({ type: SHOW_CONTRACT_COMPLAINT_MODAL, data });
    };
}

export function showDepartmentModal() {
    return (dispatch) => {
        dispatch({ type: SHOW_DEPARTMENT_MODAL });
    };
}

export function showPermissionsModal() {
    return {
        type: SHOW_PERMISSIONS_MODAL,
    };
}

export function updateContract(contractId, contractData, onSuccess) {
    return resourceManager({
        method: 'put',
        url: `/contracts/${contractId}`,
        requestOptions: generateUpdateContractOptions,
        onStart: onUpdateContractStart,
        onSuccess: onUpdateContractSuccess,
        onFailure: onUpdateContractFailure,
        context: { contractData, onSuccess },
    });
}

export function updateContractBudget(contractId, budgetData) {
    return resourceManager({
        method: 'put',
        requestOptions: ({ context }) => ({ data: context.budgetData }),
        url: `/contracts/${contractId}/budget`,
        context: { budgetData },
        onSuccess: onUpdateContractBudgetSuccess,
    });
}

export function updateContractContacts(contractId, contactData) {
    return resourceManager({
        method: 'put',
        url: `/contracts/${contractId}/contacts`,
        requestOptions: generateUpdateContractContactsOptions,
        onStart: onUpdateContractContactsStart,
        onSuccess: onUpdateContractContactsSuccess,
        onFailure: onUpdateContractContactsFailure,
        context: { contactData },
    });
}

export function updateContractInsurances(contractId, insuranceData) {
    return resourceManager({
        method: 'put',
        url: `/contracts/${contractId}/insurance`,
        requestOptions: generateUpdateContractInsurancesOptions,
        onStart: onUpdateContractInsurancesStart,
        onSuccess: onUpdateContractInsurancesSuccess,
        onFailure: onUpdateContractInsurancesFailure,
        context: { insuranceData },
    });
}

export function updateContractMilestoneNotifications(
    contractId,
    milestoneId,
    contractMilestoneNotificationsData,
    onSuccess
) {
    return resourceManager({
        method: 'put',
        url: `/contracts/${contractId}/milestones/${milestoneId}/notifications`,
        requestOptions: generateUpdateContractMilestoneNotificationsOptions,
        onStart: onUpdateContractMilestoneNotificationsStart,
        onSuccess: onUpdateContractMilestoneNotificationsSuccess,
        onFailure: onUpdateContractMilestoneNotificationsFailure,
        context: { contractId, contractMilestoneNotificationsData, milestoneId, onSuccess },
    });
}

export function updateContractComplaint(contractId, contractReviewId, contractComplaintId, data) {
    return resourceManager({
        method: 'put',
        url: `/contracts/${contractId}/reviews/${contractReviewId}/complaints/${contractComplaintId}`,
        requestOptions: { data },
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: UPDATE_CONTRACT_COMPLAINT_SUCCESS, result });
        },
    });
}

export function updateContractReview(contractId, contractReviewId, data) {
    return resourceManager({
        method: 'put',
        url: `/contracts/${contractId}/reviews/${contractReviewId}`,
        requestOptions: { data },
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: UPDATE_CONTRACT_REVIEW_SUCCESS, result });
            return result;
        },
    });
}

export function updatePurchaseOrder(contractId, purchaseOrderId, data, params) {
    return resourceManager({
        method: 'put',
        url: `/contracts/${contractId}/budget/purchase-orders/${purchaseOrderId}`,
        requestOptions: { data, params },
        onSuccess: ({ dispatch, result }) => {
            dispatch(loadContractLineItems(contractId));
            dispatch(showSnackbar('Order updated!'));
            return dispatch({ type: LOAD_PURCHASE_ORDERS_SUCCESS, result });
        },
    });
}

export function updateRenewal(contractId, renewalId, renewalData, onSuccess) {
    return resourceManager({
        method: 'put',
        url: `/contracts/${contractId}/renewals/${renewalId}`,
        requestOptions: generateUpdateContractRenewalOptions,
        onStart: onUpdateContractRenewalStart,
        onSuccess: onUpdateContractRenewalSuccess,
        onFailure: onUpdateContractRenewalFailure,
        context: { contractId, renewalData, renewalId, onSuccess },
    });
}

/**
 * Steps:
 * 1. Get signed S3 url from our API: this is where we are going to upload the file to
 * 2. Upload the file to S3 using the signed url
 * 3. Create an Attachment object in our db with the path to the S3 object
 * 4. (In Reducer): add Attachment to the list of `attachments` on the `contract`
 *
 * @param  {number} [contractId] ID of contract to associate attachment with (if provided)
 * @param  {object} file         File instance
 * @return {Promise<object>} The created attachment
 */
export function uploadAndCreateContractAttachment(contractId, file) {
    return resourceManager({
        method: 'get',
        url: `/contracts/${contractId}/s3`,
        requestOptions: generateUploadContractAttachmentS3UrlOptions,
        onStart: onUploadContractAttachmentS3UrlStart,
        onSuccess: onUploadContractAttachmentS3UrlSuccess,
        onFailure: onUploadContractAttachmentS3UrlFailure,
        context: { contractId, file, uploadId: UUIDv4() },
    });
}

function uploadContractAttachmentToS3(context, signedData, dispatch) {
    onUploadContractAttachmentToS3Start(context, dispatch);

    const postData = {
        data: context.file,
        headers: { 'Content-Type': context.file.type },
        onProgress: (e) => {
            // We say we are 5% done once we get the signed url data
            // We then get to 95% done when the file is uploaded to S3
            // We are at 100% done when we have created the Attachment in our db
            let progress = e.percent;
            if (progress < 5) {
                progress = 5;
            } else if (progress > 95) {
                progress = 95;
            }

            dispatch({
                type: ATTACHMENT_PROGRESS,
                progress,
                uploadId: context.uploadId,
            });
        },
    };

    // Write to the S3 API
    return request
        .put(signedData.signedPutUrl, postData)
        .then(() => onUploadContractAttachmentToS3Success(context, signedData, dispatch))
        .catch((error) => onUploadContractAttachmentToS3Failure(context, error, dispatch));
}

export function getContractAutoNumber(contractId) {
    return (dispatch, getState, client) => {
        const autoNumberId = getState().auth.getIn(['user', 'government', 'contract_auto_id']);
        return client
            .get(`/auto-numbers/${autoNumberId}`, { params: { contract: contractId } })
            .catch((error) => {
                dispatch(
                    showSnackbar(`Could not get Auto Number: ${error.message}`, {
                        isError: true,
                    })
                );
            });
    };
}

export function incrementContractAutoNumber(contractId) {
    return (dispatch, getState, client) => {
        const autoNumberId = getState().auth.getIn(['user', 'government', 'contract_auto_id']);
        return client
            .put(`/auto-numbers/${autoNumberId}`, { params: { contract: contractId } })
            .catch((error) => {
                dispatch(
                    showSnackbar(`Could not insert Auto Number: ${error.message}`, {
                        isError: true,
                    })
                );
            });
    };
}

export function loadPurchaseOrdersImportData() {
    return (dispatch, getState, client) => {
        return client.get('/purchase-orders/import');
    };
}

export function importPurchaseOrders(importData) {
    return (dispatch, getState, client) => {
        return client.post('/purchase-orders/import', { data: importData });
    };
}

export function exportPurchaseOrders() {
    return (dispatch, getState, client) => {
        return client
            .get('/purchase-orders/export')
            .then((result) => {
                dispatch(
                    showNotification(
                        'Export complete! You should be prompted to download your file.',
                        { duration: 5000 }
                    )
                );
                return exportArrayToCSV(result, {
                    fileName: 'purchase_orders_export',
                    headers: true,
                    timestamp: true,
                });
            })
            .catch((error) => {
                dispatch(showSnackbar(`Export Error: ${error.message}`, { isError: true }));
            });
    };
}

export function loadContractChecklists(contractId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_CONTRACT_CHECKLISTS });
        return client
            .get(`/contracts/${contractId}/checklists`)
            .then((result) => {
                dispatch({ type: LOAD_CONTRACT_CHECKLISTS_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_CONTRACT_CHECKLISTS_FAIL, error });
            });
    };
}

export function loadContractLineItems(contractId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_CONTRACT_LINE_ITEMS });
        return client
            .get(`/contracts/${contractId}/budget/line-items`)
            .then((result) => {
                dispatch({ type: LOAD_CONTRACT_LINE_ITEMS_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_CONTRACT_LINE_ITEMS_FAIL, error });
            });
    };
}

export function loadEmailAudits(contractId) {
    return (dispatch, getState, client) => {
        return client
            .get(`/contracts/${contractId}/email-audits`)
            .then((result) => dispatch({ type: LOAD_VENDOR_EMAIL_AUDITS_SUCCESS, result }));
    };
}

// State is manage by the calling component internally instead of redux
export function loadEmailAudit(contractId, emailUuid) {
    return (dispatch, getState, client) => {
        return client.get(`/contracts/${contractId}/email-audits/${emailUuid}`);
    };
}

export function loadContractPublicAuditLogs(contractId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_CONTRACT_PUBLIC_AUDIT_LOGS });
        return client
            .get(`/contracts/${contractId}/public-audit-logs`)
            .then((result) => dispatch({ type: LOAD_CONTRACT_PUBLIC_AUDIT_LOGS_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_CONTRACT_PUBLIC_AUDIT_LOGS_FAIL, error }));
    };
}

export function createContractPublicAuditLog(contractId, data) {
    return (dispatch, getState, client) => {
        dispatch(updatingConfirmationModal());
        return client
            .post(`/contracts/${contractId}/public-audit-logs`, { data })
            .then((result) => {
                dispatch(hideConfirmationModal());
                dispatch({ type: CREATE_CONTRACT_PUBLIC_AUDIT_LOG_SUCCESS, result });
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
            });
    };
}

export function copyContract(contractId) {
    return (dispatch, getState, client) => {
        dispatch(updatingConfirmationModal());
        return client
            .post(`/contracts/${contractId}/copy`)
            .then((result) => {
                dispatch(hideConfirmationModal());
                browserHistory.push(`/governments/${result.government_id}/contracts/${result.id}`);
                dispatch(showSnackbar('Contract Copied'));
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
            });
    };
}

export function contractMenuActionHandler(actionType, contract, opts) {
    return (dispatch) => {
        switch (actionType) {
            case menuActions.COPY_CONTRACT:
                return dispatch(showConfirmationModal(actionType, { contract, ...opts }));
            default:
                return null;
        }
    };
}

export function contractAttachmentApproval(contractId, attachmentId, data = {}) {
    return (dispatch, getState, client) => {
        const { isApproved } = data;
        return client
            .post(`/contracts/${contractId}/attachments/${attachmentId}/approval`, { data })
            .then((result) => {
                const action = isApproved
                    ? PENDING_CONTRACT_DOCUMENT_APPROVED
                    : PENDING_CONTRACT_DOCUMENT_REJECTED;
                dispatch({ type: action, result });
                dispatch(showSnackbar(isApproved ? 'Document Approved' : 'Document Rejected'));
            })
            .catch((error) => {
                dispatch(showSnackbar(`Update failed: ${error.message}`));
            });
    };
}

export function createVendorContractAttachment(contractId, data) {
    return (dispatch, getState, client) => {
        return client.post(`/contracts/${contractId}/attachments`, { data }).then((result) => {
            dispatch({ type: PENDING_CONTRACT_DOCUMENT_CREATED, result });
            dispatch(showSnackbar('File uploaded!'));
        });
    };
}
