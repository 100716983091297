export const formName = 'suppliersNetworkModalForm';
export const fieldName = 'locations';
export const emailsFieldName = 'emails';

export const MAX_VENDOR_CONTACTS = 200;

export const SEARCH_METHODS = {
    AUTOMATICALLY_ADDED: 'automaticallyAdded',
    PRE_INVITE_LIST: 'preInviteList',
    SEARCH_BY_LOCATION: 'searchByLocation',
    READONLY_SEARCH_BY_LOCATION: 'readOnlySearchByLocation',
    SEARCH_BY_PERSON: 'searchByPerson',
    EMAIL_ADDRESSES: 'emailAddresses',
    SEARCH_BY_VENDOR_LIST: 'searchByVendorList',
};

export const TOTALS_INITIAL_VALUES = {
    [SEARCH_METHODS.AUTOMATICALLY_ADDED]: 0,
    [SEARCH_METHODS.PRE_INVITE_LIST]: 0,
    [SEARCH_METHODS.SEARCH_BY_LOCATION]: 0,
    [SEARCH_METHODS.READONLY_SEARCH_BY_LOCATION]: 0,
    [SEARCH_METHODS.SEARCH_BY_PERSON]: 0,
    [SEARCH_METHODS.EMAIL_ADDRESSES]: 0,
    [SEARCH_METHODS.SEARCH_BY_VENDOR_LIST]: 0,
};

export const SELECTED_VALUES_INITIAL_VALUES = {
    [SEARCH_METHODS.SEARCH_BY_LOCATION]: [],
    [SEARCH_METHODS.READONLY_SEARCH_BY_LOCATION]: [],
    [SEARCH_METHODS.EMAIL_ADDRESSES]: [],
    [SEARCH_METHODS.SEARCH_BY_PERSON]: [],
    [SEARCH_METHODS.SEARCH_BY_VENDOR_LIST]: [],
    [SEARCH_METHODS.PRE_INVITE_LIST]: [],
};

export const SEARCH_METHODS_INITIAL_VALUES = {
    [SEARCH_METHODS.SEARCH_BY_LOCATION]: true,
    [SEARCH_METHODS.READONLY_SEARCH_BY_LOCATION]: false,
    [SEARCH_METHODS.SEARCH_BY_PERSON]: false,
    [SEARCH_METHODS.EMAIL_ADDRESSES]: false,
    [SEARCH_METHODS.SEARCH_BY_VENDOR_LIST]: false,
    [SEARCH_METHODS.PRE_INVITE_LIST]: true,
};

export const META_INITIAL_VALUES = {
    shouldFetchLocations: true,
    shouldFetchPreInviteList: true,
};

export const NOTIFIED_USERS_INITIAL_VALUES = {
    loading: true,
    ids: new Set(),
    organizationIds: new Set(),
    emails: new Set(),
};

export const ASSOCIATED_CATEGORIES_INITIAL_VALUES = {
    loading: true,
    categories: [],
};
