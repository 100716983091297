import classNames from 'classnames';
import React from 'react';
import { Field } from 'redux-form';

import { InputText } from '../../../../../../components';
import { useDebounce, useQueryParam } from '../../../../../../hooks';
import { qaTagPageName, fieldNames } from '../../constants';

export const RequisitionsListSearch = () => {
    const [, , setQueryParams] = useQueryParam('search');

    const { SEARCH } = fieldNames;

    const handleSearch = (value) => {
        setQueryParams({
            page: 1,
            search: value,
        });
    };

    const debounceSetSearchQuery = useDebounce(handleSearch);

    const styles = require('./index.scss');

    const handleChange = (event) => {
        const newValue = event.target.value;
        debounceSetSearchQuery(newValue);
    };

    return (
        <Field
            aria-label="Search Requisitions"
            className={styles.searchInput}
            component={InputText}
            formGroupClassName={styles.searchContainer}
            hasFeedback={false}
            inputGroupPrefix={<i className={classNames('fa fa-search', styles.searchIcon)} />}
            name={SEARCH}
            onChange={handleChange}
            placeholder="Search by number or description"
            qaTag={`${qaTagPageName}-search`}
            type="text"
        />
    );
};
