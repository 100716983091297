import { listToDict } from '../../../../../../../shared_config/helpers';

export const fields = [
    'builderSection',
    'isWritingForm',
    'manualNumber',
    'orderById',
    'projectSections',
    'section_type',
    'shortName',
    'title',
];

export const fieldNames = listToDict(fields);
export const PARENT_DIVIDER = 'parentDivider';

const builderSectionFields = ['instructions'];

export const builderSectionFieldNames = listToDict(builderSectionFields);

// Fake field used for verifying if the specified project section is valid
export const HAS_ERRORS = 'hasErrors';

export const editorSelectOptions = [
    {
        icon: 'user',
        label: 'Project Editors & Admins',
        value: true,
    },
    {
        icon: 'lock',
        label: 'Admins Only',
        value: false,
    },
];
