import { get, isEmpty, isNumber } from 'lodash';

import { ALL_SECTIONS, fieldNames } from './constants';
import {
    arrayError,
    budget,
    contact,
    department,
    evaluationCriteriaItem,
    financialId,
    manualNumber,
    MAX_TEXT_AREA_LENGTH,
    projectTimelines,
    questionnaire as questionnaireValidator,
    questionnaireResponse as questionnaireResponseValidator,
    questionnaireResponseComplete as questionnaireResponseCompleteValidator,
    rawSummary,
    REQUIRED_TEXT,
    requisitionIdentifier,
    sectionsValidate,
    title,
} from '../../../Forms/validation';
import { hasFormErrors } from '../../../helpers';
import { mappedProcurementContactFields } from '../../../../../shared_config/contacts';
import {
    customPriceItemFields,
    customPriceTableHeaderFields,
    priceTableFieldNames,
    priceTableHeaderFields,
    LINE_ITEM,
    DESCRIPTION,
    QUANTITY,
    UNIT_PRICE,
    UNIT_TO_MEASURE,
} from '../../../../../shared_config/priceTables';
import { sectionTypeNames, pseudoSectionTypeNames } from '../../../../../shared_config/sections';
import { subsectionTypeNames } from '../../../../../shared_config/subsections';
import { timelineDates } from '../../../../../shared_config/timelines';
import { isPurchaseWithoutIntroduction } from '../../../helpers/projectSections';

const {
    DIVIDER,
    EVALUATION_CRITERIA,
    EVALUATION_PHASE,
    INTRODUCTION,
    PRICING,
    QUESTIONNAIRE,
    SCOPE,
    TERMS,
    TEXT_AREA,
} = sectionTypeNames;

const { DOCUMENT_SETUP, GLOBAL_DATA, OVERVIEW } = pseudoSectionTypeNames;

const { BODY } = subsectionTypeNames;

const { PROCUREMENT_CONTACT_ID, UPFRONT_QUESTIONS } = fieldNames;

const { MIN_BID_DECREMENT } = priceTableFieldNames;

const overviewValidate = (values, props) => {
    return {
        ...title(values),
        ...contact(values),
        ...department(values),
        ...financialId(values, props),
        ...requisitionIdentifier(values),
    };
};

const globalDataValidate = (values, props) => {
    return {
        ...title(values),
        ...budget(values, props),
        ...contact(values),
        ...contact(values, {
            fieldNames: mappedProcurementContactFields,
            idFieldName: PROCUREMENT_CONTACT_ID,
        }),
        ...department(values),
        ...financialId(values, props),
        ...requisitionIdentifier(values),
    };
};

const introValidate = (values, props) => {
    const errors = {};

    const isIntake = get(props, 'project.isIntake');

    return {
        ...rawSummary(values),
        ...projectTimelines(values, { skipRequiredFields: isIntake }), // Skip timeline validation for intakes
        ...errors,
    };
};

function criteriaItemsValidate(criteria, isTextAreaSection, useManualNumbering) {
    return criteria.map((criteriaItem, index) => {
        const criteriaErrors = {};

        // Don't validate `isHiddenByLogic` items
        if (criteriaItem.isHiddenByLogic) {
            return criteriaErrors;
        }

        const { needsReview } = criteriaItem;
        const nextItemNestLevel = get(criteria[index + 1], 'nestLevel');
        const isNestedItem = nextItemNestLevel && nextItemNestLevel > criteriaItem.nestLevel;

        if (criteriaItem.isUnused || needsReview || isNestedItem) {
            // User can do whatever with unused, needs review, or nested criteria descriptions
        } else if (!criteriaItem.rawDescription) {
            criteriaErrors.rawDescription = REQUIRED_TEXT;
        } else if (criteriaItem.rawDescription.length >= MAX_TEXT_AREA_LENGTH) {
            criteriaErrors.rawDescription = 'This field is too long';
        }

        if (isTextAreaSection) {
            // Text area section types do not have titles
        } else if (!criteriaItem.title) {
            criteriaErrors.title = REQUIRED_TEXT;
        } else if (criteriaItem.title.length >= 200) {
            criteriaErrors.title = 'This field is too long';
        }

        if (useManualNumbering && !isTextAreaSection) {
            criteriaErrors.manualNumber = manualNumber(criteriaItem.manualNumber);
        }

        // For items needing review verify the review was performed
        if (needsReview && !criteriaItem.isReviewed) {
            criteriaErrors.isReviewed =
                'This item needs to be reviewed. ' +
                'Please follow the instructions to complete review.';
            // We use isReviewed field to validate the entire state of the
            // terms review item. Set an error on it if there are errors
            // elsewhere in the item.
        } else if (needsReview && hasFormErrors(criteriaErrors)) {
            criteriaErrors.isReviewed = 'Please fix the errors in the form';
        }

        return criteriaErrors;
    });
}

/**
 * Maps criteria errors for the given section to an error object for setting in redux-form
 * @param  {object} values  All the values in form
 * @param  {object} projectSection Section to validate
 * @return {object}         Map corresponding to the errors in criteria
 */
function criteriaValidate(values, projectSection) {
    const { criteria = {}, useManualNumbering } = values;
    const {
        id: projectSectionId,
        isWritingForm,
        projectSubsections,
        section_type: sectionType,
    } = projectSection;

    // Gets the criteria field key to validate
    const projectSubsectionId = projectSubsections.find((sub) => sub.subsection_type === BODY).id;
    const criteriaKey = `${projectSectionId}_${projectSubsectionId}`;

    const criteriaToValidate = criteria[criteriaKey] || [];

    const hasCriteria = criteriaToValidate.length > 0;
    if (!hasCriteria && isWritingForm) {
        return {
            criteria: {
                [criteriaKey]: arrayError('Please include at least one item'),
            },
        };
    }

    const isTextAreaSection = sectionType === TEXT_AREA;
    const criteriaErrors = criteriaItemsValidate(
        criteriaToValidate,
        isTextAreaSection,
        useManualNumbering
    );

    return {
        criteria: {
            [criteriaKey]: criteriaErrors,
        },
    };
}

function pricingTablesValidate(values, props) {
    const errors = {};

    const { priceTables = [] } = values;

    // At least one price table must be included
    if (priceTables.length === 0) {
        errors.priceTables = arrayError(
            'Please include at least one pricing table or upload a pricing table file'
        );
        return errors;
    }

    errors.priceTables = priceTables.map((priceTable) => {
        const priceTableErrors = {};

        if (priceTable.title && priceTable.title.length >= 250) {
            priceTableErrors.title = 'Title is too long';
        }

        if (priceTable.description && priceTable.description.length >= 3000) {
            priceTableErrors.description = 'Description is too long';
        }

        if (!priceTable.priceItems || priceTable.priceItems.length === 0) {
            priceTableErrors.priceItems = arrayError(
                'Please include at least one item in the price table or delete the table'
            );
            return priceTableErrors;
        }

        priceTableHeaderFields.forEach((headerName) => {
            if (!priceTable[headerName]) {
                priceTableErrors[headerName] = REQUIRED_TEXT;
            } else if (priceTable[headerName].length > 64) {
                priceTableErrors[headerName] = 'This field is too long';
            }
        });

        customPriceTableHeaderFields.forEach((headerName) => {
            if (priceTable[headerName] && priceTable[headerName].length > 64) {
                priceTableErrors[headerName] = 'This field is too long';
            }
        });

        if (get(props, 'project.template.isReverseAuction')) {
            const minBidDecrement = parseFloat(priceTable[MIN_BID_DECREMENT]);

            if (!isNumber(minBidDecrement)) {
                priceTableErrors[MIN_BID_DECREMENT] = 'Must provide a minimum increment';
            }

            if (minBidDecrement && minBidDecrement >= 100000000) {
                priceTableErrors[MIN_BID_DECREMENT] = 'Number is too large';
            }

            // Temporarily commenting out Min & Max Bid validations.
            // Will remove when we are 100% sure these are unnecessary fields.
            // if (priceTable[MIN_BID] && priceTable[MIN_BID] >= 100000000) {
            //     priceTableErrors[MIN_BID] = 'Number is too large';
            // }

            // if (priceTable[MAX_BID] && priceTable[MAX_BID] >= 100000000) {
            //     priceTableErrors[MAX_BID] = 'Number is too large';
            // }

            // if (
            //     isNumber(priceTable[MAX_BID]) &&
            //     isNumber(priceTable[MIN_BID]) &&
            //     priceTable[MIN_BID] >= priceTable[MAX_BID]
            // ) {
            //     priceTableErrors[MAX_BID] = 'Max bid must be greater than min bid';
            // }
        }

        priceTableErrors.priceItems = priceTable.priceItems.map((priceItem) => {
            const priceItemErrors = {};

            // Errors are on `LINE_ITEM` as that is the col used for displaying/editing header text from `DESCRIPTION`
            if (priceItem.isHeaderRow) {
                if (!priceItem[DESCRIPTION]) {
                    priceItemErrors[LINE_ITEM] = REQUIRED_TEXT;
                } else if (priceItem.isHeaderRow && !priceItem[DESCRIPTION].length > 3000) {
                    priceItemErrors[LINE_ITEM] = 'This field is too long';
                }
                return priceItemErrors;
            }

            if (!priceTable.omitLineItem && !priceItem[LINE_ITEM]) {
                priceItemErrors[LINE_ITEM] = REQUIRED_TEXT;
            } else if (!priceTable.omitLineItem && priceItem[LINE_ITEM].length > 64) {
                priceItemErrors[LINE_ITEM] = 'This field is too long';
            }

            if (!priceItem[DESCRIPTION]) {
                priceItemErrors[DESCRIPTION] = REQUIRED_TEXT;
            } else if (priceItem[DESCRIPTION].length > 3000) {
                priceItemErrors[DESCRIPTION] = 'This field is too long';
            }

            if (!priceItem[UNIT_TO_MEASURE]) {
                priceItemErrors[UNIT_TO_MEASURE] = REQUIRED_TEXT;
            } else if (priceItem[UNIT_TO_MEASURE].length > 64) {
                priceItemErrors[UNIT_TO_MEASURE] = 'This field is too long';
            }

            const isQuantityRequired = priceTable.hasQuantity && priceTable.specifyQuantity;
            if (isQuantityRequired && !priceItem[QUANTITY] && priceItem[QUANTITY] !== 0) {
                priceItemErrors[QUANTITY] = REQUIRED_TEXT;
            }
            if (priceItem[QUANTITY] && priceItem[QUANTITY] >= 100000000) {
                priceItemErrors[QUANTITY] = 'Number is too large';
            }

            if (priceItem[UNIT_PRICE] && priceItem[UNIT_PRICE] >= 100000000) {
                priceItemErrors[UNIT_PRICE] = 'Number is too large';
            }

            customPriceItemFields.forEach((customField) => {
                if (priceItem[customField] && priceItem[customField].length > 510) {
                    priceItemErrors[customField] = 'This field is too long';
                }
            });

            return priceItemErrors;
        });

        return priceTableErrors;
    });

    return errors;
}

function evaluationPhaseValidate(values, isMultiPhase) {
    const errors = {};

    // At least one evaluation phase must be included
    if (!values.evaluationPhases || values.evaluationPhases.length === 0) {
        errors.evaluationPhases = arrayError('Please include at least one evaluation criteria');
        return errors;
    }

    errors.evaluationPhases = values.evaluationPhases.map((phase) => {
        const evaluationPhaseErrors = {};

        if (isMultiPhase) {
            if (!phase.title) {
                evaluationPhaseErrors.title = REQUIRED_TEXT;
            } else if (phase.title.length > 250) {
                evaluationPhaseErrors.title = 'This field is too long';
            }
        }

        if (!phase.scoringCriteria || phase.scoringCriteria.length === 0) {
            evaluationPhaseErrors.scoringCriteria = arrayError(
                'Please add at least one evaluation criteria'
            );
            return evaluationPhaseErrors;
        }

        evaluationPhaseErrors.scoringCriteria = phase.scoringCriteria.map((criteriaItem) =>
            evaluationCriteriaItem(criteriaItem)
        );

        return evaluationPhaseErrors;
    });

    return errors;
}

function questionnaireValidate(values) {
    const errors = {};

    // At least one questionnaire must be included
    if (!values.questionnaires || values.questionnaires.length === 0) {
        errors.questionnaires = arrayError('Please include at least one vendor question');
        return errors;
    }

    errors.questionnaires = values.questionnaires.map((question) =>
        questionnaireValidator(question)
    );

    return errors;
}

function upfrontQuestionsValidate(values) {
    const errors = {};

    errors[UPFRONT_QUESTIONS] = (values[UPFRONT_QUESTIONS] || []).map((question) => {
        return questionnaireResponseValidator(question, { isUpfrontQuestion: true });
    });

    return errors;
}

function validateSectionType(projectSection, values, props) {
    const { section_type: sectionType } = projectSection;

    switch (sectionType) {
        case SCOPE:
        case TERMS:
        case TEXT_AREA:
            return criteriaValidate(values, projectSection);
        case DOCUMENT_SETUP:
            return upfrontQuestionsValidate(values);
        case EVALUATION_CRITERIA:
        case EVALUATION_PHASE:
            return evaluationPhaseValidate(values, sectionType === EVALUATION_PHASE);
        case GLOBAL_DATA:
            return globalDataValidate(values, props);
        case INTRODUCTION:
            return introValidate(values, props);
        case OVERVIEW:
            return overviewValidate(values, props);
        case PRICING:
            return pricingTablesValidate(values, props);
        case QUESTIONNAIRE:
            return questionnaireValidate(values);
        case DIVIDER: // Divider sections have no content to validate
        default:
            return null;
    }
}

const projectPropertiesErrors = (errors, isIntake) => {
    let timeline;

    if (isIntake) {
        timeline = 0;
    } else {
        timeline =
            (errors.timelines || []).filter((t) => !isEmpty(t)).length +
            Object.keys(errors.timelineConfig || {}).length;

        timeline = timelineDates.reduce((acc, fieldName) => {
            return acc + (errors[fieldName] ? 1 : 0);
        }, timeline);
    }

    return {
        projectProperties: {
            projectInformation: [
                errors.title,
                errors.budget,
                errors.contact_id,
                errors[PROCUREMENT_CONTACT_ID],
            ].filter((e) => !isEmpty(e)).length,
            setupQuestions:
                errors.upfrontQuestions && Array.isArray(errors.upfrontQuestions)
                    ? errors.upfrontQuestions.filter((e) => !isEmpty(e)).length
                    : 0,
            summaryAndBackground: errors.rawSummary ? 1 : 0,
            timeline,
        },
    };
};

export function validateAll(values, props) {
    const isIntake = get(props, 'project.isIntake');
    const projectSections = props.writingSections || [];

    const sectionsToValidate = [];
    const formErrors = projectSections.reduce((errorObj, projectSection) => {
        const validation = validateSectionType(projectSection, values, props);

        // if it's the new editor don't mark the introduction section as errored
        // because the errors are displayed elsewhere
        if (
            !props.validateForSDv2Editor ||
            (props.validateForSDv2Editor && projectSection.section_type !== INTRODUCTION)
        ) {
            sectionsToValidate.push([projectSection.id, validation]);
        }

        if (get(validation, 'criteria')) {
            return {
                ...errorObj,
                criteria: {
                    ...errorObj.criteria,
                    ...validation.criteria,
                },
            };
        }
        return {
            ...errorObj,
            ...validation,
        };
    }, {});

    const sectionsErrors = sectionsValidate(...sectionsToValidate);
    const formHasErrors = hasFormErrors(sectionsErrors);

    let errors = {
        [ALL_SECTIONS]: formHasErrors,
        ...formErrors,
        ...sectionsErrors,
    };

    if (isPurchaseWithoutIntroduction(values.type, projectSections)) {
        errors = {
            ...errors,
            ...introValidate(values, props),
        };
    }

    return {
        ...errors,
        ...projectPropertiesErrors(errors, isIntake),
        // Using the `isValid` selector from redux-form was missing
        // errors nested in pricing tables. Setting the `_error` key
        // ensures the form is marked as invalid when errors are present.
        _error: formHasErrors,
    };
}

export const warn = (values) => {
    const warnings = {};

    if (values[UPFRONT_QUESTIONS]) {
        warnings[UPFRONT_QUESTIONS] = values[UPFRONT_QUESTIONS].map((question) => {
            return questionnaireResponseCompleteValidator(question, {
                isUpfrontQuestion: true,
                optional: true,
            });
        });
    }

    return warnings;
};
