import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { TabsNavigation } from '../../../../../components';
import { getInitialTabs } from '../helpers';
import { useQueryParam } from '../../../../../hooks';
import { requisitionTabsNames } from '../../../../../../../shared_config/requisitions';
import { getIsGroupAdminOrLeader } from '../selectors';
import { RequisitionAccountsLink } from '../RequisitionAccountsLink';
import { useChartInteraction } from '../Visualizations/hooks/useChartInteraction';

const { MY_APPROVALS, ALL_REQUESTS } = requisitionTabsNames;

export const RequisitionsListHeader = () => {
    const location = useLocation();
    const styles = require('./RequisitionsListHeader.scss');

    const isAdminOrLeader = useSelector((state) => getIsGroupAdminOrLeader(state));

    const tabs = useMemo(() => getInitialTabs(isAdminOrLeader, location), [isAdminOrLeader]);

    const defaultTab = isAdminOrLeader ? ALL_REQUESTS : MY_APPROVALS;
    const [queryTab] = useQueryParam('tab', defaultTab);

    const { hasChartInteraction } = useChartInteraction();

    return (
        <div className={classNames('row', styles.requisitionsListHeader)}>
            <div className="col-xs-12 col-sm-8">
                {!hasChartInteraction && (
                    <TabsNavigation
                        defaultTab={tabs.find((tab) => tab.route.includes(queryTab)) || tabs[0]}
                        pathname={location.pathname}
                        tabs={tabs}
                    />
                )}
            </div>
            <div className={classNames('col-xs-12 col-sm-4 text-right', styles.accountLink)}>
                <RequisitionAccountsLink />
            </div>
        </div>
    );
};
