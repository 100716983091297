import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup } from 'react-bootstrap';

import { RequisitionsListItem } from './RequisitionsListItem';
import { RequisitionDashboardEmptyState } from './EmptyState';

export const RequisitionsList = ({ requisitions }) => {
    const styles = require('./RequisitionsList.scss');

    if (requisitions.length === 0) {
        return <RequisitionDashboardEmptyState />;
    }

    return (
        <ListGroup className={styles.requisitionsList}>
            {requisitions.map((requisition) => {
                return <RequisitionsListItem key={requisition.id} requisition={requisition} />;
            })}
        </ListGroup>
    );
};

RequisitionsList.propTypes = {
    requisitions: PropTypes.array.isRequired,
};
