import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Pagination } from '../../../../../components';
import { useQueryParam } from '../../../../../hooks';
import { getRequisitionsSearchCount } from '../selectors';

const PaginationText = ({ page, limit, totalItemsCount, shownItemsCount }) => {
    const styles = require('./RequisitionsListPagination.scss');

    const firstItemIndex = (page - 1) * limit + 1;
    const lastItemIndex = Math.min(firstItemIndex + shownItemsCount - 1, totalItemsCount);

    if (totalItemsCount === 0) {
        return null;
    }

    const paginationText = `Showing ${firstItemIndex}-${lastItemIndex} of ${totalItemsCount} items`;

    return <div className={styles.paginationText}>{paginationText}</div>;
};

PaginationText.propTypes = {
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    totalItemsCount: PropTypes.number.isRequired,
    shownItemsCount: PropTypes.number.isRequired,
};

export const RequisitionsListPagination = ({ shownItemsCount }) => {
    const styles = require('./RequisitionsListPagination.scss');

    const requisitionsCount = useSelector(getRequisitionsSearchCount);

    const [page, setPage] = useQueryParam('page', 1);
    const [limit] = useQueryParam('limit');

    const hidePagination = requisitionsCount <= limit;

    return (
        <div className={styles.paginationContainer}>
            <Pagination
                activePage={Number(page)}
                className={styles.pagination}
                items={Math.ceil(requisitionsCount / limit) || 1}
                maxButtons={4}
                next
                nextDisabled={page >= Math.ceil(requisitionsCount / limit)}
                nextHidden={hidePagination}
                onSelect={setPage}
                prev
                prevDisabled={page <= 1}
                prevHidden={hidePagination}
                useOpenGovStyle
            />
            <PaginationText
                limit={Number(limit)}
                page={Number(page)}
                shownItemsCount={shownItemsCount}
                totalItemsCount={requisitionsCount}
            />
        </div>
    );
};

RequisitionsListPagination.propTypes = {
    shownItemsCount: PropTypes.number.isRequired,
};
