import { showSnackbar } from './notification';
import { emitProjectSocket } from './utils';
import { resourceManager, serializeCriteriaAndSectionDescriptions } from '../helpers';
import request from '../request';

export const COPY_TEMPLATE = 'templatesAdmin/COPY_TEMPLATE';
export const COPY_TEMPLATE_FAIL = 'templatesAdmin/COPY_TEMPLATE_FAIL';
export const COPY_TEMPLATE_SUCCESS = 'templatesAdmin/COPY_TEMPLATE_SUCCESS';

export const CREATE = 'templatesAdmin/CREATE';
export const CREATE_FAIL = 'templatesAdmin/CREATE_FAIL';
export const CREATE_SUCCESS = 'templatesAdmin/CREATE_SUCCESS';

export const CREATE_PROJECT_SECTION = 'templatesAdmin/CREATE_PROJECT_SECTION';
export const CREATE_PROJECT_SECTION_FAIL = 'templatesAdmin/CREATE_PROJECT_SECTION_FAIL';
export const CREATE_PROJECT_SECTION_SUCCESS = 'templatesAdmin/CREATE_PROJECT_SECTION_SUCCESS';

export const CREATE_TEMPLATE_SECTION_SUCCESS = 'templatesAdmin/CREATE_TEMPLATE_SECTION_SUCCESS';

export const CREATE_TEMPLATE_QUESTION_SUCCESS = 'templatesAdmin/CREATE_TEMPLATE_QUESTION_SUCCESS';

export const CREATE_UPFRONT_QUESTION = 'templatesAdmin/CREATE_UPFRONT_QUESTION';
export const CREATE_UPFRONT_QUESTION_FAIL = 'templatesAdmin/CREATE_UPFRONT_QUESTION_FAIL';
export const CREATE_UPFRONT_QUESTION_SUCCESS = 'templatesAdmin/CREATE_UPFRONT_QUESTION_SUCCESS';

export const CRITERIA_NEEDS_REVIEW_MODAL_HIDE = 'templatesAdmin/CRITERIA_NEEDS_REVIEW_MODAL_HIDE';
export const CRITERIA_NEEDS_REVIEW_MODAL_SHOW = 'templatesAdmin/CRITERIA_NEEDS_REVIEW_MODAL_SHOW';

export const DELETE = 'templatesAdmin/DELETE';
export const DELETE_FAIL = 'templatesAdmin/DELETE_FAIL';
export const DELETE_SUCCESS = 'templatesAdmin/DELETE_SUCCESS';

export const LOAD = 'templatesAdmin/LOAD';
export const LOAD_FAIL = 'templatesAdmin/LOAD_FAIL';
export const LOAD_SUCCESS = 'templatesAdmin/LOAD_SUCCESS';

export const LOAD_LIST = 'templatesAdmin/LOAD_LIST';
export const LOAD_LIST_FAIL = 'templatesAdmin/LOAD_LIST_FAIL';
export const LOAD_LIST_SUCCESS = 'templatesAdmin/LOAD_LIST_SUCCESS';

export const LOAD_TEMPLATE_SECTIONS_SUCCESS = 'templatesAdmin/LOAD_TEMPLATE_SECTIONS_SUCCESS';

export const QUESTION_LOGIC_MODAL_HIDE = 'templatesAdmin/QUESTION_LOGIC_MODAL_HIDE';
export const QUESTION_LOGIC_MODAL_SHOW = 'templatesAdmin/QUESTION_LOGIC_MODAL_SHOW';

export const RESET_TEMPLATE_EDIT = 'templatesAdmin/RESET_TEMPLATE_EDIT';
export const RESET_TEMPLATE_LIST = 'templatesAdmin/RESET_TEMPLATE_LIST';

export const SET_DOCX_TEMPLATES = 'templatesAdmin/SET_DOCX_TEMPLATES';

export const UPDATE = 'templatesAdmin/UPDATE';
export const UPDATE_FAIL = 'templatesAdmin/UPDATE_FAIL';
export const UPDATE_SUCCESS = 'templatesAdmin/UPDATE_SUCCESS';

const serializeTemplateData = (data) => {
    const serializedCriteriaAndSectionDescriptions = serializeCriteriaAndSectionDescriptions(data);

    if (data.template && !data.template.docx_attachment_id) {
        data.template.docx_attachment_id = null;
    }

    return {
        ...data,
        ...serializedCriteriaAndSectionDescriptions,
    };
};

const generateCreateOptions = ({ context }) => {
    return { data: context.templateData };
};

const generateCreateProjectSectionOptions = ({ context }) => {
    const { projectSectionType, ...rest } = context;

    return { data: { projectSectionType, ...rest } };
};

const generateUpdateOptions = ({ context }) => {
    const data = serializeTemplateData(context.templateData);

    return { data };
};

const onCopyTemplateFailure = ({ error, dispatch }) => {
    dispatch({ type: COPY_TEMPLATE_FAIL, error });
    return error;
};

const onCopyTemplateStart = ({ dispatch }) => {
    dispatch({ type: COPY_TEMPLATE });
};

const onCopyTemplateSuccess = ({ context, result, dispatch }) => {
    dispatch({ type: COPY_TEMPLATE_SUCCESS });
    dispatch(
        showSnackbar('Template copied', {
            dismissAfter: 3500,
        })
    );

    if (context.onSuccess) {
        context.onSuccess(result);
    }
};

const onCreateFailure = ({ error, dispatch }) => {
    dispatch({ type: CREATE_FAIL, error });
};

const onCreateProjectSectionFailure = ({ error, dispatch }) => {
    dispatch({ type: CREATE_PROJECT_SECTION_FAIL, error });
    return error;
};

const onCreateProjectSectionStart = ({ dispatch }) => {
    dispatch({ type: CREATE_PROJECT_SECTION });
};

const onCreateProjectSectionSuccess = ({ result, dispatch }) => {
    dispatch({ type: CREATE_PROJECT_SECTION_SUCCESS, result });
    return result;
};

const onCreateTemplateSectionSuccess = ({ context, dispatch }) => {
    const onSuccess = (result) => {
        // Need to dispatch `onUpdateSuccess` to update form for the user AND to broadcast changes
        // to connected clients. The form must be overridden with the current template values since
        // more than just the template section was added (various template section content too and
        // possibly template questions).
        const updateContext = { successMessage: 'Template Section Added' };
        onUpdateSuccess({ result, dispatch, context: updateContext }); // eslint-disable-line no-use-before-define
        return dispatch({ type: CREATE_TEMPLATE_SECTION_SUCCESS });
    };
    return dispatch(loadTemplate(context.templateId, { onSuccess, skipLoading: true })); // eslint-disable-line no-use-before-define
};

const onCreateStart = ({ dispatch }) => {
    dispatch({ type: CREATE });
};

const onCreateSuccess = ({ context, result, dispatch }) => {
    dispatch({ type: CREATE_SUCCESS, result });
    if (context.onSuccess) {
        context.onSuccess(result);
    }
};

const onCreateUpfrontQuestionFailure = ({ error, dispatch }) => {
    dispatch({ type: CREATE_UPFRONT_QUESTION_FAIL, error });
    return error;
};

const onCreateUpfrontQuestionStart = ({ dispatch }) => {
    dispatch({ type: CREATE_UPFRONT_QUESTION });
};

const onCreateUpfrontQuestionSuccess = ({ result, dispatch }) => {
    dispatch({ type: CREATE_UPFRONT_QUESTION_SUCCESS, result });
    return result;
};

const onCreateTemplateQuestionSuccess = ({ result, dispatch, context }) => {
    const { silent } = context.options;
    if (!silent) {
        const updateAction = { type: CREATE_TEMPLATE_QUESTION_SUCCESS, result, silent };
        dispatch(emitProjectSocket(result.id, updateAction));
    }
    onCreateUpfrontQuestionSuccess({ result, dispatch });
    return result;
};

const onDeleteFailure = ({ error, dispatch }) => {
    dispatch({ type: DELETE_FAIL, error });
};

const onDeleteStart = ({ dispatch }) => {
    dispatch({ type: DELETE });
};

const onDeleteSuccess = ({ context, result, dispatch }) => {
    dispatch({ type: DELETE_SUCCESS, result });
    if (context.onSuccess) {
        context.onSuccess(result);
    }
};

const onLoadFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_FAIL, error });
};

const onLoadStart = ({ context, dispatch }) => {
    if (context.skipLoading) {
        return;
    }
    dispatch({ type: LOAD });
};

const onLoadSuccess = ({ context, result, dispatch }) => {
    dispatch({ type: LOAD_SUCCESS, result });
    if (context.onSuccess) {
        context.onSuccess(result);
    } else {
        return result;
    }
};

const onLoadTemplateSectionsSuccess = ({ result, dispatch }) => {
    dispatch({ type: LOAD_TEMPLATE_SECTIONS_SUCCESS, result });
};

const onLoadListFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_LIST_FAIL, error });
};

const onLoadListStart = ({ dispatch }) => {
    dispatch({ type: LOAD_LIST });
};

const onLoadListSuccess = ({ result, dispatch }) => {
    dispatch({ type: LOAD_LIST_SUCCESS, result });
};

const onReloadSuccess = ({ result, dispatch, getState }) => {
    const lastUpdated = getState().templatesAdmin.getIn(['template', 'updated_at']);

    // Dates are returned as strings, so can be compared
    if (result.updated_at !== lastUpdated) {
        dispatch(
            showSnackbar('Received New Updates', {
                dismissAfter: 3500,
            })
        );
        // Load the template again if new data has come in
        return dispatch(loadTemplate(result.id)); // eslint-disable-line no-use-before-define
    }
};

const onUpdateFailure = ({ error, dispatch }) => {
    dispatch({ type: UPDATE_FAIL, error });
    return error;
};

const onUpdateStart = ({ dispatch }) => {
    dispatch({ type: UPDATE });
};

const onUpdateSuccess = ({ context, result, dispatch }) => {
    const updateAction = { type: UPDATE_SUCCESS, result };
    dispatch(emitProjectSocket(result.id, updateAction));
    dispatch(showSnackbar(context.successMessage || 'Template saved'));
    return result;
};

const putToS3 = ({ context, result }) => {
    const { file, onProgress, title, headingFormatters } = context;

    const signedData = result;

    onProgress(3);
    const postData = {
        data: file,
        headers: { 'Content-Type': file.type },
        onProgress: (e) => onProgress(e.percent),
    };

    // Write to the s3 API
    return request.put(result.signedPutUrl, postData).then(() => {
        // Set progress to 100% on the upload
        onProgress(100);
        const data = {
            bucket: signedData.bucket,
            path: signedData.key,
            filename: signedData.filename,
            title,
            headingFormatters,
        };

        return data;
    });
};

const uploadDocxTemplateHandler = ({ client, context, dispatch, result }) => {
    return putToS3({ context, result })
        .then((data) => {
            // Create the attachment using the s3 file info
            return client.post('/templates/docx-templates', { data });
        })
        .then((docxTemplates) => {
            dispatch({ type: SET_DOCX_TEMPLATES, result: docxTemplates });
            dispatch(showSnackbar('Template saved'));
        });
};

export function copyTemplate(templateId, onSuccess) {
    return resourceManager({
        method: 'post',
        url: `/templates/${templateId}/copy`,
        onStart: onCopyTemplateStart,
        onSuccess: onCopyTemplateSuccess,
        onFailure: onCopyTemplateFailure,
        context: { onSuccess },
    });
}

export function createProjectSection(templateId, projectSectionType, templateData = {}) {
    return resourceManager({
        method: 'post',
        url: `/templates/${templateId}/project-sections`,
        requestOptions: generateCreateProjectSectionOptions,
        onStart: onCreateProjectSectionStart,
        onSuccess: onCreateProjectSectionSuccess,
        onFailure: onCreateProjectSectionFailure,
        context: { projectSectionType, ...templateData },
    });
}

export function createTemplateQuestion(templateId, templateQuestionId, options = {}) {
    return resourceManager({
        method: 'post',
        url: `/templates/${templateId}/template-questions`,
        requestOptions: { data: { templateQuestionId } },
        onStart: onCreateUpfrontQuestionStart,
        onSuccess: onCreateTemplateQuestionSuccess,
        onFailure: onCreateUpfrontQuestionFailure,
        context: { options },
    });
}

const onCreateTemplateSectionSuccessSilent = async ({ dispatch, result }) => {
    dispatch({ type: CREATE_TEMPLATE_SECTION_SUCCESS });

    return result;
};

export function createTemplateSection(
    templateId,
    templateSectionId,
    templateData = {},
    silent = false
) {
    return resourceManager({
        method: 'post',
        url: `/templates/${templateId}/template-sections`,
        requestOptions: { data: { templateSectionId, ...templateData } },
        onStart: onCreateProjectSectionStart,
        // TODO: sdv2, get back to this when this might be the default option (ie, no other than sdv2 exists)
        onSuccess: silent ? onCreateTemplateSectionSuccessSilent : onCreateTemplateSectionSuccess,
        onFailure: onCreateProjectSectionFailure,
        context: { templateId },
    });
}

export function createTemplate(templateData, onSuccess) {
    return resourceManager({
        method: 'post',
        url: '/templates',
        requestOptions: generateCreateOptions,
        onStart: onCreateStart,
        onSuccess: onCreateSuccess,
        onFailure: onCreateFailure,
        context: { templateData, onSuccess },
    });
}

export function createUpfrontQuestion(templateId, upfrontQuestionData) {
    return resourceManager({
        method: 'post',
        url: `/templates/${templateId}/upfront-questions`,
        requestOptions: { data: upfrontQuestionData },
        onStart: onCreateUpfrontQuestionStart,
        onSuccess: onCreateUpfrontQuestionSuccess,
        onFailure: onCreateUpfrontQuestionFailure,
    });
}

export function createQuestionnaire(templateId, data) {
    return resourceManager({
        method: 'post',
        url: `/templates/${templateId}/questionnaire`,
        requestOptions: { data },
        onSuccess: ({ result }) => {
            return result;
        },
        onFailure: ({ error, dispatch }) => {
            dispatch(
                showSnackbar('Failed to create the question', {
                    isError: true,
                })
            );
            return error;
        },
    });
}

export function criteriaNeedsReviewShowModal(data) {
    return { type: CRITERIA_NEEDS_REVIEW_MODAL_SHOW, data };
}

export function criteriaNeedsReviewHideModal() {
    return { type: CRITERIA_NEEDS_REVIEW_MODAL_HIDE };
}

export function deleteDocxTemplate(docxTemplateId) {
    return resourceManager({
        method: 'del',
        url: `/templates/docx-templates/${docxTemplateId}`,
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: SET_DOCX_TEMPLATES, result });
            dispatch(showSnackbar('Template deleted'));
        },
        onFailure: ({ dispatch, error }) => {
            dispatch(showSnackbar(`Error: ${error.message}`, { isError: true }));
        },
    });
}

export function deleteTemplate(templateId, onSuccess) {
    return resourceManager({
        method: 'del',
        url: `/templates/${templateId}`,
        onStart: onDeleteStart,
        onSuccess: onDeleteSuccess,
        onFailure: onDeleteFailure,
        context: { onSuccess },
    });
}

export function hideQuestionLogicModal() {
    return { type: QUESTION_LOGIC_MODAL_HIDE };
}

export function loadTemplate(templateId, context) {
    return resourceManager({
        method: 'get',
        url: `/templates/${templateId}`,
        onStart: onLoadStart,
        onSuccess: onLoadSuccess,
        onFailure: onLoadFailure,
        context,
    });
}

export function loadTemplateSections(templateId) {
    return resourceManager({
        method: 'get',
        url: `/templates/${templateId}/template-sections`,
        onSuccess: onLoadTemplateSectionsSuccess,
    });
}

export function loadTemplatesList() {
    return resourceManager({
        method: 'get',
        url: '/templates',
        onStart: onLoadListStart,
        onSuccess: onLoadListSuccess,
        onFailure: onLoadListFailure,
    });
}

export function reloadTemplate(templateId) {
    return resourceManager({
        method: 'get',
        url: `/templates/${templateId}`,
        onStart: () => {},
        onSuccess: onReloadSuccess,
        onFailure: () => {},
    });
}

export function resetTemplateEdit() {
    return { type: RESET_TEMPLATE_EDIT };
}

export function resetTemplateList() {
    return { type: RESET_TEMPLATE_LIST };
}

/**
 * Shows the 'add question logic' modal
 * @param {object} data The data needed to add the question logic
 * @param {string} data.linkable Linkable object from the allowed types
 * @param {object} data.linkableItem The object that will be linked (attachment, questionnaire, etc)
 * @param {number} data.linkable_id The id of the linkableItem
 * @param {object} data.projectSection the project section to which the linkableItem might belong
 * @param {boolean} data.usingFakeSection extra prop introduced along with Attachment Management functionality. When set to true,
 * the options displayed in the select for project sections will not be the project's own sections, but rather only the `projectSection`
 * sent along this props. This is needed because the Attachments can be used as linkable elements but they might not belong to any section.
 * In that case we fake projectSection to be something like this:
 * { id: 0.5, section_type: ATTACHMENTS, title: 'Attachments' }
 * That way, the modal will work correctly having the select populate with this data and the second select where you select the linkable will
 * also work just fine. For more info on how this is used check app/containers/GovApp/TemplateAdmin/components/QuestionLogicModal/selectors.js
 * @param {number} editIndex Used when editing a question logic
 * @param {boolean} disableLinkableItem Used to disable the ability to select which the linkable is. Used when triggering this modal from a particular linkable
 * @returns {object}
 */
export function showQuestionLogicModal(data, editIndex, disableLinkableItem = false) {
    return { type: QUESTION_LOGIC_MODAL_SHOW, data, editIndex, disableLinkableItem };
}

export function updateDefaultDocxTemplate(docxTemplateId) {
    return resourceManager({
        method: 'put',
        url: `/templates/docx-templates/${docxTemplateId}/default`,
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: SET_DOCX_TEMPLATES, result });
            dispatch(showSnackbar('Default template updated'));
        },
        onFailure: ({ dispatch, error }) => {
            dispatch(showSnackbar(`Error: ${error.message}`, { isError: true }));
        },
    });
}

export function updateDocxTemplate(docxTemplateId, data) {
    return resourceManager({
        method: 'put',
        url: `/templates/docx-templates/${docxTemplateId}`,
        requestOptions: { data },
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: SET_DOCX_TEMPLATES, result });
            dispatch(showSnackbar('Template updated'));
        },
        onFailure: ({ dispatch, error }) => {
            dispatch(showSnackbar(`Error: ${error.message}`, { isError: true }));
        },
    });
}

export function updateTemplate(templateId, templateData) {
    return resourceManager({
        method: 'put',
        url: `/templates/${templateId}`,
        requestOptions: generateUpdateOptions,
        onStart: onUpdateStart,
        onSuccess: onUpdateSuccess,
        onFailure: onUpdateFailure,
        context: { templateData },
    });
}

export function uploadDocxTemplate(file, { title, headingFormatters }, onProgress) {
    return resourceManager({
        method: 'get',
        url: '/templates/docx-templates/s3',
        requestOptions: ({ context }) => {
            return {
                params: {
                    filename: context.file.name,
                    contentType: context.file.type,
                },
            };
        },
        onSuccess: uploadDocxTemplateHandler,
        context: { file, title, headingFormatters, onProgress },
    });
}

const updateDocxTemplateFileHandler = ({ client, context, dispatch, result }) => {
    const { docxTemplateId } = context;

    return putToS3({ context, result })
        .then((data) => {
            return client.put(`/templates/docx-templates/${docxTemplateId}/replace-attachment`, {
                data,
            });
        })
        .then((docxTemplates) => {
            dispatch({ type: SET_DOCX_TEMPLATES, result: docxTemplates });
            dispatch(showSnackbar('Template file has been replaced'));
        });
};

export function updateDocxTemplateFile(docxTemplateId, file, { title }, onProgress) {
    return resourceManager({
        method: 'get',
        url: '/templates/docx-templates/s3',
        requestOptions: ({ context }) => {
            return {
                params: {
                    filename: context.file.name,
                    contentType: context.file.type,
                },
            };
        },
        onSuccess: updateDocxTemplateFileHandler,
        context: { file, title, docxTemplateId, onProgress },
    });
}
