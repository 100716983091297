import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initialize, reduxForm } from 'redux-form';
import { groupBy } from 'lodash';

import { useQueryParam } from '../../../../../../hooks';
import { RequisitionListDetailedFilters } from './DetailedFilters';
import { RequisitionListQuickFilters } from './QuickFilters';
import { RequisitionsListSearch } from './Search';
import { RequisitionListSorting } from './Sorting';
import { ChartSelection } from './ChartSelection';
import { formConfig } from '../../form';
import { buildSortingOptionValue } from './constants';
import { fieldNames } from '../../constants';
import { dateFilterTypesDict, dateTypes } from '../../../../../../components/DateFilter/constants';
import { RequisitionsListExport } from '../RequisitionsListExport';
import { useChartInteraction } from '../../Visualizations/hooks/useChartInteraction';

const { SORTING, ONE_DATE_TYPE, ON, DATE_TYPE, START, END, BEFORE, AFTER, CREATORS, LAST_ACTIONS } =
    fieldNames;
const { ONE_DATE, DATE_RANGE } = dateFilterTypesDict;

const parseFilters = (paramFilters) => {
    const filters = groupBy(paramFilters, 'filter');

    // Set the value directly to date filters
    Object.keys(filters).forEach((key) => {
        if (dateTypes.includes(key)) {
            const value = filters[key][0].value;
            filters[key] = new Date(value);
        }
    });

    return filters;
};

const ConnectedRequisitionListFilters = () => {
    const styles = require('./index.scss');

    const [params] = useQueryParam();

    const dispatch = useDispatch();

    const getOneDateType = (filters) => {
        let defaultOneDateType = ON;

        if (filters[BEFORE]) {
            defaultOneDateType = BEFORE;
        }

        if (filters[AFTER]) {
            defaultOneDateType = AFTER;
        }

        return defaultOneDateType;
    };

    useEffect(() => {
        const filters = parseFilters(params.filters);

        const initialValues = {
            [ONE_DATE_TYPE]: getOneDateType(filters),
            [DATE_TYPE]: filters[START] || filters[END] ? DATE_RANGE : ONE_DATE,
            [CREATORS]: [],
            [LAST_ACTIONS]: [],
        };

        const sorting = buildSortingOptionValue(params.sort, params.sortDirection);

        dispatch(
            initialize(formConfig.form, {
                ...initialValues,
                ...params,
                [SORTING]: sorting,
                ...filters,
            })
        );
    }, [JSON.stringify(params)]);

    const { hasChartInteraction } = useChartInteraction();

    return (
        <div className={classNames('row', styles.container)}>
            <div className="col-sm-12 col-md-5">
                {hasChartInteraction ? <ChartSelection /> : <RequisitionListQuickFilters />}
            </div>
            <div className={classNames('col-sm-12 col-md-7', styles.filtersContainer)}>
                <div className={styles.sortingAndFilters}>
                    <RequisitionListSorting />
                    <RequisitionListDetailedFilters />
                </div>
                <div className={styles.search}>
                    <RequisitionsListSearch />
                    <RequisitionsListExport />
                </div>
            </div>
        </div>
    );
};

export const RequisitionListFilters = reduxForm(formConfig)(ConnectedRequisitionListFilters);
