import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { RequisitionGroupsListItem } from './RequisitionGroupsListItem';
import { TemplateListTimestampHeaders, ZeroState } from '../../../../components';

export const RequisitionGroupsList = ({
    hasNoCreatedGroups,
    RequisitionGroupCreateButton,
    requisitionGroups,
    routingHandler,
}) => {
    const styles = require('./index.scss');

    if (requisitionGroups.length === 0) {
        const props = {
            button: hasNoCreatedGroups ? <RequisitionGroupCreateButton bsSize="lg" /> : undefined,
            title: hasNoCreatedGroups
                ? 'No requisition groups created yet'
                : 'No requisition groups here!',
        };
        return <ZeroState {...props} />;
    }

    return (
        <>
            <div className={`row ${styles.listHeader} hidden-xs hidden-sm`}>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-5">Name</div>
                        <div className="col-md-3">Owner</div>
                        <div className="col-md-4">Sequence In Use</div>
                    </div>
                </div>
                <div className="col-md-3">
                    <TemplateListTimestampHeaders />
                </div>
            </div>
            <ListGroup>
                {requisitionGroups.map((requisitionGroup) => {
                    return (
                        <ListGroupItem
                            key={requisitionGroup.id}
                            onClick={() => routingHandler(requisitionGroup.id)}
                        >
                            <RequisitionGroupsListItem requisitionGroup={requisitionGroup} />
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
        </>
    );
};

RequisitionGroupsList.propTypes = {
    hasNoCreatedGroups: PropTypes.bool,
    RequisitionGroupCreateButton: PropTypes.func.isRequired,
    requisitionGroups: PropTypes.array.isRequired,
    routingHandler: PropTypes.func.isRequired,
};
