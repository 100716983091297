import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
    getHeadingFormattersKey,
    parseHeadingFormatter,
} from '../../helpers/docxHeadingFormatters';
import {
    usesDocxHeadingFormatters,
    getDocxHeadingFormatters,
} from '../../containers/GovApp/selectors';
import { DocxHeadingFormattersContext } from '../contexts';

export const DocxHeading = ({
    domElement,
    legacy,
    numbering,
    title,
    useSectionDividers,
    ...props
}) => {
    // this context is an extra way of accessing the required data
    // used when rendering the docx html via the frontend's SSR endpoints.
    const ctx = useContext(DocxHeadingFormattersContext);
    const useHeadingFormatters =
        useSelector(usesDocxHeadingFormatters) || ctx.usesDocxHeadingFormatters;
    let headingFormatters = useSelector(getDocxHeadingFormatters);

    if (isEmpty(headingFormatters)) {
        headingFormatters = ctx.docxHeadingFormatters;
    }

    if (!useHeadingFormatters || !headingFormatters) {
        return legacy;
    }

    const headingSeparatorsKey = getHeadingFormattersKey({ useSectionDividers, domElement });

    const parsedHeading = parseHeadingFormatter(
        headingFormatters[headingSeparatorsKey],
        numbering,
        title
    );

    return React.createElement(
        domElement,
        props,
        // eslint-disable-next-line react/no-danger
        <span dangerouslySetInnerHTML={{ __html: parsedHeading }} />
    );
};

DocxHeading.propTypes = {
    legacy: PropTypes.node.isRequired,
    useSectionDividers: PropTypes.bool,
    domElement: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']).isRequired,
    title: PropTypes.string.isRequired,
    numbering: PropTypes.string,
};
