import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { startCase } from 'lodash';

import { useSelector } from 'react-redux';

import { budgetCheckVisualization } from '../constants';
import { currencyFormatter } from '../../../../helpers';
import { visualizationColors, visualizationItemsDict } from './constants';
import { getRequisitionJS } from '../../../../selectors/govApp';
import { formatChartData, getAnnotationPositions, getPlotLines } from '../helpers';
import { isRequisitionClosedCancelledOrRejected as isRequisitionClosedCancelledOrRejectedFn } from '../../../../../../shared_config/requisitions';

const { SPENT, COMMITTED, IN_PROCESS, AVAILABLE, TOTAL } = visualizationItemsDict;

if (!process.env.SERVER) {
    const patternFill = require('highcharts/modules/pattern-fill');
    patternFill(Highcharts);
    const annotations = require('highcharts/modules/annotations');
    annotations(Highcharts);
}

export const BudgetCheckVisualization = ({ data, fullScreen = false }) => {
    const requisition = useSelector(getRequisitionJS);
    const requisitionStatus = requisition?.status;
    const isRequisitionClosedCancelledOrRejected = isRequisitionClosedCancelledOrRejectedFn(
        requisition?.status
    );

    const { budgetCheckPass, usePreEncumbrance } = data;

    const {
        data: chartData,
        overTotalPlotLine,
        sumOfValues,
    } = useMemo(
        () => formatChartData(data, budgetCheckPass, requisitionStatus, usePreEncumbrance),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(data)]
    );

    const renderChart = () => {
        const { annotationStart, annotationEnd } = getAnnotationPositions(
            data,
            requisitionStatus,
            usePreEncumbrance
        );

        const tickPositions = [0, annotationStart, annotationEnd, sumOfValues];

        const annotationColor = budgetCheckPass
            ? visualizationColors.budgetCheckPass
            : visualizationColors.budgetCheckFail;

        Highcharts.chart(budgetCheckVisualization, {
            chart: {
                type: 'bar',
                height: 185,
                width: fullScreen ? null : 592,
                events: {
                    load() {
                        const chart = this;
                        const yAxis = chart.yAxis[0];

                        const endOfChart = chart.plotWidth;

                        const annotationStartPx = yAxis.toPixels(yAxis.ticks[annotationStart].pos);
                        const annotationEndPx = yAxis.toPixels(yAxis.ticks[annotationEnd].pos);

                        const finalXValue = chart.plotWidth / 2; // x = 0 is in the middle of the chart
                        const initialXValue = -finalXValue;

                        const rectangleWidth = Math.max(annotationEndPx - annotationStartPx, 1);
                        const strokeWidth = 2;

                        // "Total: $nnn" label
                        const renderTotalLabel = () => {
                            const text = currencyFormatter({
                                value: data[TOTAL],
                            });

                            const totalBudgetPosition = yAxis.toPixels(data[TOTAL]);

                            let totalTitlePosition = initialXValue + totalBudgetPosition;

                            // If the title is outside of the chart (left), put it in the start
                            totalTitlePosition = Math.max(totalTitlePosition, initialXValue + 50);
                            // If the title is outside of the chart (right), put it in the end
                            totalTitlePosition = Math.min(totalTitlePosition, finalXValue - 50);

                            yAxis.setTitle({
                                x: totalTitlePosition,
                                text,
                                textAlign: 'center',
                            });
                        };

                        // Draw the requested amount rectangle
                        const renderRequestedAmountRectangle = () => {
                            const rectangleHeight = 50;
                            chart.renderer
                                .rect(
                                    annotationStartPx,
                                    chart.plotTop + 16,
                                    rectangleWidth,
                                    rectangleHeight,
                                    0,
                                    strokeWidth
                                )
                                .attr({
                                    stroke: annotationColor,
                                    zIndex: 5,
                                })
                                .add();
                        };

                        // "This Request" label and line
                        const renderThisRequestLabel = () => {
                            const middleOfRectangle =
                                annotationStartPx + (rectangleWidth - strokeWidth) / 2;

                            let lineXPosition = middleOfRectangle;
                            let textPosition = middleOfRectangle;

                            // If text position is outside of the chart (right), put it in the end
                            textPosition = Math.min(textPosition, endOfChart - 55);
                            // If text position is outside of the chart (left), put it in the start
                            textPosition = Math.max(textPosition, 75);

                            const lineYPosition = chart.plotTop + 7;
                            const lineWidth = 1;
                            const lineHeight = 8;

                            // If rectangle is too small, put the line in the start of the rectangle
                            if (rectangleWidth <= 1) {
                                lineXPosition = annotationStartPx;
                            }

                            // Draw the line pointing to the "This Request" label
                            chart.renderer
                                .rect(
                                    lineXPosition,
                                    lineYPosition,
                                    lineWidth,
                                    lineHeight,
                                    0,
                                    strokeWidth
                                )
                                .attr({
                                    stroke: annotationColor,
                                    zIndex: 3,
                                })
                                .add();

                            const thisRequestLabelYPosition = -4;

                            // "This Request" label
                            chart.renderer
                                .label(
                                    `<span style="font-size: 14px; font-family: Barlow; font-weight: 600;">This Request:</span> <span style="font-size: 14px; font-family: Barlow; font-weight: 400;">${currencyFormatter(
                                        {
                                            value: data.requestedAmount,
                                        }
                                    )}</span>`,
                                    textPosition,
                                    thisRequestLabelYPosition
                                )
                                .css({
                                    fontSize: 12,
                                })
                                .attr({
                                    zIndex: 5,
                                    align: 'center',
                                })
                                .add();
                        };

                        if (data.requestedAmount) {
                            renderTotalLabel();

                            if (!isRequisitionClosedCancelledOrRejected) {
                                renderRequestedAmountRectangle();
                                renderThisRequestLabel();
                            }
                        }

                        const series = chart.series;
                        series.forEach((ser) => {
                            // Add a stroke to the legend symbol of the "Available" series
                            if (ser.legendSymbol && ser.name === AVAILABLE) {
                                ser.legendSymbol.element.setAttribute('stroke-width', '1');
                                ser.legendSymbol.element.setAttribute(
                                    'stroke',
                                    visualizationColors.gray
                                );
                            }
                        });
                    },
                },
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: ['Budget'],
                labels: {
                    enabled: false,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
                gridLineWidth: 0,
                labels: {
                    enabled: false,
                },
                max: sumOfValues,
                plotLines: [...getPlotLines(sumOfValues, budgetCheckPass, data), overTotalPlotLine],
                tickPositions,
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    pointWidth: 32,
                },
            },
            series: chartData.map((item) => ({
                name: item.name,
                data: [
                    {
                        y: item.value,
                        id: item.name,
                    },
                ],
                color: item.color,
                showInLegend: item.showInLegend,
                enableMouseTracking: false,
            })),
            legend: {
                reversed: true,
                labelFormatter() {
                    const { name } = this;

                    const legendValue = data[name];

                    return (
                        `<span style="font-weight: bold;">${startCase(name)}</span><br/>` +
                        `<span style="font-weight: normal;">${currencyFormatter({
                            value: legendValue,
                        })}</span>`
                    );
                },
                margin: 20,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
        });
    };

    useEffect(() => {
        renderChart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData]);

    return <div id={budgetCheckVisualization} />;
};

BudgetCheckVisualization.propTypes = {
    data: PropTypes.shape({
        availableAmount: PropTypes.number.isRequired,
        requestedAmount: PropTypes.number.isRequired,
        budgetCheckPass: PropTypes.bool.isRequired,
        usePreEncumbrance: PropTypes.bool.isRequired,
        [SPENT]: PropTypes.number.isRequired,
        [COMMITTED]: PropTypes.number.isRequired,
        [IN_PROCESS]: PropTypes.number.isRequired,
        [AVAILABLE]: PropTypes.number.isRequired,
    }).isRequired,
    fullScreen: PropTypes.bool,
};
