import React from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';
import PropTypes from 'prop-types';

import { Button } from '../../../../components';
import { getRequisitionsPath } from './selectors';

// @withRouter
const ConnectedRequisitionAccountsLink = ({ params }) => {
    const styles = require('./index.scss');

    const requisitionsPath = getRequisitionsPath({ params });

    return (
        <Button
            bsStyle="link"
            className={styles.searchByAccount}
            to={`${requisitionsPath}/accounts/details`}
        >
            Search by Account
        </Button>
    );
};

ConnectedRequisitionAccountsLink.propTypes = {
    params: PropTypes.object.isRequired,
};

export const RequisitionAccountsLink = withRouter(ConnectedRequisitionAccountsLink);
