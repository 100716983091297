import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { getRequisitionsPath } from '../selectors';
import { statusTypes } from '../../../../../../../shared_config/requisitions';
import { qaTagPageName } from '../constants';
import { getLastActionDisplayData } from '../../../../../../../shared_config/requisitions/lastActions';
import { currencyFormatter } from '../../../../../helpers';
import { getGovernmentSalesTax } from '../../../../../selectors/govApp';
import { getPriceItemsSummary } from '../../../../../../../shared_config/priceTables/requisitionUtils';
import { getPurchaseOrderUrls } from '../../../RequisitionsApproval/helpers';

const PurchaseOrders = ({ purchaseOrders }) => {
    const styles = require('./RequisitionsListItem.scss');

    if (purchaseOrders.length === 0) {
        return null;
    }

    if (purchaseOrders.length === 1) {
        const { href, number } = purchaseOrders[0];

        return (
            <div>
                <a
                    className={styles.poLink}
                    // Prevent the RequisitionListItem from being clicked and redirected to the requisition page
                    href={href}
                    onClick={(e) => e.stopPropagation()}
                    rel="noreferrer"
                    target="_blank"
                >
                    PO #{number}&nbsp;&nbsp;
                    <i className="fa fa-external-link" />
                </a>
            </div>
        );
    }

    return <span className={styles.itemDetailTitle}>{purchaseOrders.length} Purchase Orders</span>;
};

PurchaseOrders.propTypes = {
    purchaseOrders: PropTypes.array,
};

const CurrentStep = ({ currentStep, currentStepIndex, staticStepIds, requisitionStatus }) => {
    const styles = require('./RequisitionsListItem.scss');

    if (requisitionStatus === statusTypes.DRAFT_REJECTED) {
        return (
            <>
                <span className={classNames(styles.itemDetailTitle, styles.requestReturned)}>
                    Request Returned
                </span>
                <span className={styles.itemDetailSubtitle}>
                    Step {currentStepIndex + 1} of {staticStepIds.length}
                </span>
            </>
        );
    }

    return (
        <>
            <span className={styles.itemDetailTitle}>{currentStep?.name}</span>
            <span className={styles.itemDetailSubtitle}>
                Step {currentStepIndex + 1} of {staticStepIds.length}
            </span>
        </>
    );
};

CurrentStep.propTypes = {
    currentStep: PropTypes.object,
    currentStepIndex: PropTypes.number,
    staticStepIds: PropTypes.array,
    requisitionStatus: PropTypes.number,
};

const NextApprover = ({ approvers, requisitionStatus }) => {
    const styles = require('./RequisitionsListItem.scss');

    if (requisitionStatus === statusTypes.DRAFT_REJECTED) {
        return (
            <>
                <span className={styles.itemDetailTitle}>Returned to Creator</span>
                <span className={styles.itemDetailSubtitle}>Draft</span>
            </>
        );
    }

    if (approvers.length === 0) {
        return null;
    }

    if (approvers.length === 1) {
        return (
            <>
                <span className={styles.itemDetailTitle}>{approvers[0].user.displayName}</span>
                <span className={styles.itemDetailSubtitle}>Next Approver</span>
            </>
        );
    }

    return (
        <>
            <span
                className={classNames(styles.itemDetailTitle, styles.approvers)}
                title={approvers
                    .slice(1)
                    .map((approver) => approver.user.displayName)
                    .join(', ')}
            >
                {`${approvers[0].user.displayName} & ${approvers.length - 1} others`}
            </span>
            <span className={classNames(styles.itemDetailTitle, styles.numberOfApprovers)}>
                {`${approvers.length} Approvers`}
            </span>
            <span className={styles.itemDetailSubtitle}>Next Approver</span>
        </>
    );
};

NextApprover.propTypes = {
    approvers: PropTypes.arrayOf(PropTypes.object).isRequired,
    requisitionStatus: PropTypes.number,
};

const getNextApprovers = (requisition) => {
    const nextApprovers =
        requisition?.currentStep?.stepApproval?.stepPositionApprovals?.flatMap(
            (stepPositionApproval) => stepPositionApproval.stepPositionApprovers
        ) ?? [];

    return nextApprovers;
};

const getLastActor = (requisition) => {
    if (requisition.status === statusTypes.REVIEW) {
        return requisition.submitter;
    }

    if (requisition.status === statusTypes.CLOSED_CANCELLED) {
        return requisition.canceler;
    }

    return requisition.lastStep?.stepApproval?.lastActor;
};

export const RequisitionsListItem = ({ requisition }) => {
    const params = useParams();
    const navigate = useNavigate();
    const requisitionsPath = getRequisitionsPath({ params });
    const styles = require('./RequisitionsListItem.scss');
    const isDrafted = requisition.status === statusTypes.DRAFT;
    const route = isDrafted
        ? `${requisitionsPath}/${requisition.id}/edit`
        : `${requisitionsPath}/${requisition.id}`;

    const nextApprovers = useMemo(() => getNextApprovers(requisition), [requisition]);
    const lastActor = useMemo(() => getLastActor(requisition), [requisition]);

    const {
        icon: lastActionIcon,
        text: lastActionText,
        backgroundColor: lastActionBackgroundColor,
        color: lastActionColor,
    } = getLastActionDisplayData(requisition);

    const { priceItems } = requisition.priceTable;

    const taxRate = useSelector(getGovernmentSalesTax);
    const { total } = getPriceItemsSummary(priceItems, taxRate);

    const currentStepIndex = requisition.staticStepIds?.findIndex(
        (step) => step === requisition.current_step_id
    );

    const purchaseOrders = useMemo(
        () => getPurchaseOrderUrls(requisition.purchaseOrderObject || []),
        [requisition.purchaseOrderObject]
    );

    const lastActionDateTime = requisition.lastStep?.stepApproval?.lastActionAt;

    return (
        <ListGroupItem
            className={styles.item}
            data-qa={`${qaTagPageName}-listItem-${requisition.id}`}
            onClick={() => navigate(route)}
        >
            <div
                className={styles.itemHeading}
                style={{
                    backgroundColor: lastActionBackgroundColor,
                }}
            >
                <span
                    className={styles.itemLastAction}
                    style={{
                        color: lastActionColor,
                    }}
                >
                    {!isDrafted && (
                        <>
                            <i className={`fa ${lastActionIcon}`} />
                            <span>{lastActionText}</span>
                            <span className={styles.itemLastActionBy}>
                                {lastActor && <> by {lastActor.displayName}</>}
                            </span>
                            {lastActionDateTime && (
                                <span className={styles.actionDateTime}>
                                    on&nbsp;
                                    {moment(lastActionDateTime).format('MM/DD/YYYY [at] hh:mm a')}
                                </span>
                            )}
                        </>
                    )}
                </span>
                <span className={styles.itemReviewGroup}>{requisition.reviewGroup.name}</span>
            </div>
            <div className={classNames('row', styles.itemContent)}>
                <div className={styles.identifierContainer}>
                    <span
                        className={styles.itemLink}
                        data-qa={`${qaTagPageName}-span-requisitionItem${requisition.id}`}
                    >
                        #{requisition.identifier}
                    </span>
                    {/* Copied requisitions set their submittedAt to null */}
                    {requisition.submittedAt && (
                        <span className={styles.actionDateTime}>
                            Submitted on {moment(requisition.submittedAt).format('MM/DD/YYYY')}
                        </span>
                    )}
                </div>
                <div className={styles.itemDescription}>
                    {requisition.descriptionOfRequest}
                    {/* Use &nbsp; to ensure the div has content and maintains its styling,
                    even when `requisition.descriptionOfRequest` is falsy. */}
                    &nbsp;
                </div>
                <div className={classNames('col-xs-12 col-sm-4 col-md-2', styles.itemDetail)}>
                    {currentStepIndex >= 0 && (
                        <CurrentStep
                            currentStep={requisition.currentStep}
                            currentStepIndex={currentStepIndex}
                            requisitionStatus={requisition.status}
                            staticStepIds={requisition.staticStepIds}
                        />
                    )}
                    {purchaseOrders.length ? (
                        <PurchaseOrders purchaseOrders={purchaseOrders} />
                    ) : null}
                </div>
                <div className={classNames('col-xs-6 col-sm-4 col-md-2', styles.itemDetail)}>
                    <span className={styles.itemDetailTitle}>
                        {requisition.creator.displayName}
                    </span>
                    <span className={styles.actionDateTime}>
                        Created on {moment(requisition.created_at).format('MM/DD/YYYY')}
                    </span>
                </div>
                <div
                    className={classNames('col-xs-6 col-sm-4 col-md-1 col-lg-2', styles.itemDetail)}
                >
                    {isDrafted && <span className={styles.itemDraft}>Draft</span>}
                    {requisition.closed_at && (
                        <>
                            <span
                                className={classNames(styles.itemDetailTitle, styles.closedTitle)}
                            >
                                Closed
                            </span>
                            <span className={styles.itemDetailSubtitle}>
                                {moment(requisition.closed_at).format('MM/DD/YYYY')}
                            </span>
                        </>
                    )}
                    <NextApprover
                        approvers={nextApprovers}
                        requisitionStatus={requisition.status}
                    />
                </div>
                <div
                    className={classNames(
                        'col-xs-12 col-md-2 col-lg-1 text-right',
                        styles.itemDetail,
                        styles.lineItems
                    )}
                >
                    <p className={styles.itemAmount}>{currencyFormatter({ value: total })}</p>
                    <span className={classNames(styles.totalLineItems, styles.itemDetailSubtitle)}>
                        {priceItems.length} Line Items
                    </span>
                </div>
            </div>
        </ListGroupItem>
    );
};

RequisitionsListItem.propTypes = {
    requisition: PropTypes.shape({
        closed_at: PropTypes.string,
        created_at: PropTypes.string.isRequired,
        creator: PropTypes.object,
        current_step_id: PropTypes.number,
        currentStep: PropTypes.object,
        descriptionOfRequest: PropTypes.string,
        identifier: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        requestor: PropTypes.object,
        lastStep: PropTypes.object,
        priceTable: PropTypes.object.isRequired,
        purchaseOrderObject: PropTypes.array,
        reviewGroup: PropTypes.shape({
            icon: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        staticStepIds: PropTypes.arrayOf(PropTypes.number),
        status: PropTypes.number.isRequired,
        submittedAt: PropTypes.string,
    }).isRequired,
};
