import { useNavigate } from 'react-router-dom';

import { useProjectNavItems } from './useProjectNavItems';
import { useDashboardNavItems } from './useDashboardNavItems';
import { useAnalyticsNavItems } from './useAnalyticsNavItems';
import { useUtilityTrayOptions } from './useUtilityTrayOptions';
import { useContextSwitcherOptions } from './useContextSwitcherOptions';
import { USE_OG_PLATFORM } from '../../../../constants';

export const useGlobalNavbarConfig = () => {
    const navigate = useNavigate();

    const projectNavItems = useProjectNavItems();
    const dashboardNavItems = useDashboardNavItems();
    const analyticsNavItems = useAnalyticsNavItems();
    const utilityTrayOptions = useUtilityTrayOptions();
    const contextSwitcherOptions = useContextSwitcherOptions();

    const menuOptions = [...projectNavItems, ...dashboardNavItems, ...analyticsNavItems];

    return {
        menuOptions,
        openGovLogoOnClick: () => navigate('/'),
        utilityTrayOptions,
        ...(USE_OG_PLATFORM && { contextSwitcherOptions }),
    };
};
