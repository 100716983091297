import PropTypes from 'prop-types';
import React from 'react';
import { DropdownMenu, ListItem, ListItemButton, ListItemText } from '@og-pro/ui';
import { useSelector } from 'react-redux';

export const TakeActionsMenu = ({
    addVendorsToList,
    blockVendors,
    emailVendors,
    inviteVendorsToProject,
    selectedUserIds,
}) => {
    const loadedVendorCount = useSelector((state) => state.vendorList.get('vendorCount'));

    const vendorCount = selectedUserIds.length > 0 ? selectedUserIds.length : loadedVendorCount;

    const actionsList = [
        {
            id: 1,
            title: `Add ${vendorCount} Vendor${vendorCount > 1 ? 's' : ''} to a List`,
            onClick: () => addVendorsToList(selectedUserIds),
        },
        {
            id: 2,
            title: `Invite ${vendorCount} Vendor${vendorCount > 1 ? 's' : ''} to a Project`,
            onClick: () => inviteVendorsToProject(selectedUserIds),
        },
        {
            id: 3,
            title: `Email ${vendorCount} Vendor${vendorCount > 1 ? 's' : ''}`,
            onClick: () => emailVendors(selectedUserIds),
        },
        {
            id: 4,
            title: `Block ${vendorCount} Vendor${vendorCount > 1 ? 's' : ''}`,
            onClick: () => blockVendors(selectedUserIds, true),
        },
        {
            id: 5,
            title: `Unblock ${vendorCount} Vendor${vendorCount > 1 ? 's' : ''}`,
            onClick: () => blockVendors(selectedUserIds, false),
        },
    ];

    return (
        <DropdownMenu
            color="primary"
            disabled={vendorCount === 0}
            label="Actions on Search"
            variant="text"
        >
            {actionsList.map(({ id, onClick, title }) => (
                <ListItem disablePadding key={id}>
                    <ListItemButton onClick={onClick}>
                        <ListItemText id={`certification-list-label-${id}`} primary={title} />
                    </ListItemButton>
                </ListItem>
            ))}
        </DropdownMenu>
    );
};

TakeActionsMenu.propTypes = {
    addVendorsToList: PropTypes.func.isRequired,
    blockVendors: PropTypes.func.isRequired,
    emailVendors: PropTypes.func.isRequired,
    inviteVendorsToProject: PropTypes.func.isRequired,
    selectedUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};
