import PropTypes from 'prop-types';
import React from 'react';

import { Box, Button } from '@og-pro/ui';
import { Add as AddIcon } from '@mui/icons-material';

import { CategoryCodeChip } from './CategoryCodeChip';

export const CategoryCodesChipList = ({ addHandler, codes, deleteHandler }) => {
    if (codes.length === 0) {
        return null;
    }

    const Categories = codes.map(({ code, id, setId, title }) => (
        <CategoryCodeChip
            categoryId={id}
            code={code}
            deleteHandler={deleteHandler}
            key={id}
            setId={setId}
            title={title}
        />
    ));

    return (
        <Box alignItems="center" display="flex" gap={1}>
            {Categories}
            <Button
                color="primary"
                onClick={addHandler}
                size="small"
                startIcon={<AddIcon />}
                variant="text"
            >
                Add Categories
            </Button>
        </Box>
    );
};

CategoryCodesChipList.propTypes = {
    codes: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            setId: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
        })
    ),
    addHandler: PropTypes.func.isRequired,
    deleteHandler: PropTypes.func.isRequired,
};

CategoryCodesChipList.defaultProps = {
    codes: [],
};
