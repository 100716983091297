import PropTypes from 'prop-types';
import React from 'react';

import { HtmlContent } from '../../..';
import { Timeline } from './Timeline';
import { BudgetAndPostInformation } from './BudgetAndPostInformation';

export const ProjectOverview = (props) => {
    const { government, project } = props;

    return (
        <div className="col-xs-12 col-md-10 col-md-offset-1 introduction-section">
            <BudgetAndPostInformation government={government} isDocx={false} project={project} />
            <h3 className="section-title">Overview</h3>
            <div className="introduction-subsection">
                <div className="row">
                    <div className="col-xs-12">
                        <h4 className="introduction-subsection-title">Summary</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <HtmlContent
                            className="introduction-description article"
                            content={project.summary}
                        />
                    </div>
                </div>
            </div>
            {project.background && (
                <div className="introduction-subsection">
                    <div className="row">
                        <div className="col-xs-12">
                            <h4 className="introduction-subsection-title">Background</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <HtmlContent
                                className="introduction-description article"
                                content={project.background}
                            />
                        </div>
                    </div>
                </div>
            )}
            {!project.isIntake && (
                <div className="introduction-subsection">
                    <div className="row">
                        <div className="col-xs-12">
                            <h4 className="introduction-subsection-title">Timeline</h4>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <Timeline
                                    isDocx={false}
                                    project={project}
                                    timezone={government.timezone}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

ProjectOverview.propTypes = {
    government: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
};
