import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { Box } from '@og-pro/ui';

import { projectFormProps, criteriaFieldNames, fieldNames } from '../constants';
import { AddCriteriaButton, GuidedSectionButton, SDv2Toolbar } from '../components';
import { getCurrentCriteriaValues } from '../selectors';
import { showSnackbar } from '../../../../actions/notification';
import { showModal } from '../../../../actions/projectLibrary';
import {
    CDSButton,
    CDSButtonGroup,
    CriteriaForm,
    FormError,
    HelpToolTip,
    Main,
} from '../../../../components';
import { ListError } from '../../../../components/GovApp';
import { getMaxNumberFromList } from '../../../../utils';
import { subsectionTypeNames } from '../../../../../../shared_config/subsections';
import { withListItemRefs } from '../../../../hocs';

const { BODY } = subsectionTypeNames;
const { CRITERIA } = fieldNames;
const { ORDER_BY_ID } = criteriaFieldNames;

export const TermsToolbar = ({ showLibraryModal }) => {
    return (
        <SDv2Toolbar
            buttons={[
                {
                    children: 'Add Scope of Work from Project',
                    qaTag: 'projectCreate-addScopeOfWorkFromProject',
                    onClick: showLibraryModal,
                },
            ]}
        />
    );
};
TermsToolbar.propTypes = {
    showLibraryModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
    return {
        criteriaValues: getCurrentCriteriaValues(state, props),
    };
};

const mapDispatchToProps = {
    showLibraryModal: showModal,
    showSnackbar,
};

// @connect
class ConnectedTerms extends Component {
    static propTypes = {
        ...projectFormProps,
        array: PropTypes.shape({
            push: PropTypes.func.isRequired,
            splice: PropTypes.func.isRequired,
        }).isRequired, // From redux-form
        change: PropTypes.func.isRequired,
        criteriaValues: PropTypes.array.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        isTextArea: PropTypes.bool,
        // comes from the withListItemRefs HOC to handle focusing new list items
        focusListItem: PropTypes.func.isRequired,
        listItemRefs: PropTypes.array.isRequired,
        showLibraryModal: PropTypes.func.isRequired,
        updateListItemRefs: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {
            criteriaValues,
            isOGThemeEnabledForComponents,
            markBuilderSectionVisited,
            projectSection,
        } = this.props;

        if (
            isOGThemeEnabledForComponents &&
            !criteriaValues.length &&
            projectSection.isWritingForm
        ) {
            this.addCriteria();
        }

        if (criteriaValues.length > 0) {
            markBuilderSectionVisited({
                hasIncludedItems: true,
                onClose: this.hideHelpModal,
            });
        }
    }

    get formKey() {
        const { projectSection, projectSubsectionsMap } = this.props;

        return `${CRITERIA}.${projectSection.id}_${projectSubsectionsMap[BODY].id}`;
    }

    get useManualNumbering() {
        return this.props.project.useManualNumbering;
    }

    get formInstructions() {
        const {
            isTextArea,
            projectSection: { isWritingForm },
        } = this.props;

        if (isTextArea) {
            return isWritingForm
                ? 'Please provide the content for this section'
                : 'Please complete the requested item below by clicking on the item and following the instructions';
        }
        return isWritingForm
            ? 'Please review this section and add any additional items'
            : 'Please complete the requested items below by clicking on the item and following the instructions';
    }

    get guidedSectionButtonInstructions() {
        return this.props.isTextArea
            ? 'Please click the button to start the section'
            : 'Please click the button to begin adding items to the section';
    }

    get styles() {
        return require('./index.scss');
    }

    addCriteria = (additionalData) => {
        const {
            array,
            criteriaValues,
            focusListItem,
            projectSection,
            projectSubsectionsMap,
            updateListItemRefs,
        } = this.props;

        updateListItemRefs(null, () => {
            focusListItem(0);
        });

        array.push(this.formKey, {
            project_section_id: projectSection.id,
            project_subsection_id: projectSubsectionsMap[BODY].id,
            section_type: projectSection.section_type,
            subsection_type: projectSection.subsection_type,
            orderById: getMaxNumberFromList(criteriaValues, ORDER_BY_ID) + 1,
            ...additionalData,
        });
    };

    hideHelpModal = () => {
        const { criteriaValues, markBuilderSectionVisited, projectSection } = this.props;

        if (criteriaValues.length === 0 && projectSection.isWritingForm) {
            this.addCriteria();
            markBuilderSectionVisited();
        }
    };

    insertTermsCriteria = (insertAfterOrderById) => {
        const {
            array,
            change,
            criteriaValues,
            focusListItem,
            projectSection,
            projectSubsectionsMap,
            updateListItemRefs,
        } = this.props;

        let insertAfterIndex;
        criteriaValues.forEach((criteria, index) => {
            const oldOrderById = criteria.orderById;
            if (oldOrderById === insertAfterOrderById) {
                insertAfterIndex = index + 1;
            }
            if (oldOrderById > insertAfterOrderById) {
                change(`${this.formKey}[${index}].${ORDER_BY_ID}`, oldOrderById + 1);
            }
        });

        updateListItemRefs(null, () => {
            focusListItem(insertAfterIndex);
        });

        return array.splice(this.formKey, insertAfterIndex, 0, {
            orderById: insertAfterOrderById + 1,
            project_section_id: projectSection.id,
            project_subsection_id: projectSubsectionsMap[BODY].id,
            section_type: projectSection.section_type,
            subsection_type: projectSection.subsection_type,
        });
    };

    showHelpModal = () => {
        const { showHelpModal } = this.props;

        showHelpModal({
            onClose: this.hideHelpModal,
        });
    };

    copyHandler = (selectedItems) => {
        const { criteriaValues } = this.props;

        const maxCriteriaOrderId = getMaxNumberFromList(criteriaValues, ORDER_BY_ID) + 1;
        selectedItems.forEach((item, idx) => {
            this.addCriteria({
                orderById: maxCriteriaOrderId + idx,
                title: item.title,
                rawDescription: item.description,
            });
        });

        const itemsText = selectedItems.length === 1 ? 'item' : 'items';
        this.props.showSnackbar(`${selectedItems.length} ${itemsText} added to section`);
    };

    renderInitial() {
        const {
            disabled,
            isOGThemeEnabledForComponents,
            projectSection: { title },
            showFormErrors,
        } = this.props;

        if (isOGThemeEnabledForComponents) {
            return (
                <Box>
                    <CDSButtonGroup>
                        <CDSButton
                            onClick={this.showHelpModal}
                            qaTag="projectCreate-addListItem"
                            variant="secondary"
                        >
                            <i className="fa fa-plus" /> Add a List Item
                        </CDSButton>
                    </CDSButtonGroup>
                </Box>
            );
        }

        return (
            <div>
                <FieldArray
                    component={ListError}
                    name={this.formKey}
                    showError={!!showFormErrors}
                />
                <div className={this.styles.initContainer}>
                    <GuidedSectionButton
                        disabled={disabled}
                        info={this.guidedSectionButtonInstructions}
                        onClick={this.showHelpModal}
                        text={`Start ${title}`}
                        type="required"
                    />
                </div>
            </div>
        );
    }

    renderCriteria() {
        const {
            change,
            disabled,
            form,
            isOGThemeEnabledForComponents,
            isTextArea,
            listItemRefs,
            projectSection,
            projectSection: { isWritingForm, title },
            showComments,
            showFormErrors,
            tagOptions,
            templateVariableOptions,
            updateListItemRefs,
        } = this.props;

        const itemRefsProps = {
            listItemRefs,
            updateListItemRefs,
        };

        return (
            <div>
                {!isOGThemeEnabledForComponents && (
                    <>
                        <div>
                            {title}
                            <HelpToolTip
                                onClick={this.showHelpModal}
                                text="Click to see instructions"
                            />
                        </div>
                        <div className="row">
                            <div className="col-xs-11">
                                <p>{this.formInstructions}</p>
                            </div>
                        </div>
                    </>
                )}

                <FieldArray
                    {...itemRefsProps}
                    change={change}
                    component={CriteriaForm}
                    disabled={disabled}
                    form={form}
                    insertItem={this.insertTermsCriteria}
                    isTextArea={isTextArea}
                    name={this.formKey}
                    needsReviewOnly={!isWritingForm}
                    projectSection={projectSection}
                    showComments={showComments}
                    showValidation={showFormErrors}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                    useManualNumbering={this.useManualNumbering}
                    useRawDescription
                />
                {isWritingForm && !isTextArea && !isOGThemeEnabledForComponents && (
                    <div className="text-center">
                        <AddCriteriaButton
                            disabled={disabled}
                            onClick={() => this.addCriteria()}
                            text="Add Another Item"
                        />
                    </div>
                )}
            </div>
        );
    }

    renderSection() {
        const { criteriaValues } = this.props;

        if (criteriaValues.length === 0) {
            return this.renderInitial();
        }

        return this.renderCriteria();
    }

    render() {
        const { isOGThemeEnabledForComponents, project, updateError } = this.props;

        if (isOGThemeEnabledForComponents) {
            const contractRecordWithProject = project.contractRecords?.find(
                (contract) => contract.project_id
            );
            if (contractRecordWithProject) {
                const projectId = contractRecordWithProject.project_id;
                const showLibraryModal = () =>
                    this.props.showLibraryModal(this.copyHandler, { projectId });
                return (
                    <>
                        <TermsToolbar showLibraryModal={showLibraryModal} />
                        <Box mt={4}>{this.renderSection()}</Box>
                    </>
                );
            }
            return this.renderSection();
        }

        return (
            <Main className={`row ${this.styles.container}`}>
                <div className="col-xs-12">
                    <FormError error={updateError} />
                    {this.renderSection()}
                </div>
            </Main>
        );
    }
}

export const Terms = connect(
    mapStateToProps,
    mapDispatchToProps
)(withListItemRefs(ConnectedTerms, { propMapKey: 'criteriaValues' }));
