import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldArray, getFormSyncErrors } from 'redux-form';

import { Header } from './Header';
import { PurchaseDetailsSummary } from './Summary';
import { fieldNames } from '../../constants';
import { getEmptyPriceItem } from '../../helpers';
import { VendorAssignmentModal } from '../../VendorAssignmentModal';
import { qaTagPageName } from '../../../constants';
import { priceItemFieldNames } from '../../../../constants';
import { Button } from '../../../../../../components';
import { getGovernmentSalesTax, getRequisitionJS } from '../../../../../../selectors/govApp';
import { getMaxNumberFromList } from '../../../../../../utils';
import { isRequisitionDraft } from '../../../../../../../../shared_config/requisitions';
import { PriceItem } from './PriceItem';
import { formConfig } from '../../form';

const { PRICE_ITEMS, PRICE_TABLE, PRICE_ITEMS_SUMMARY } = fieldNames;
const { ORDER_BY_ID } = priceItemFieldNames;

const PriceItemFields = ({
    change,
    disabled,
    fields,
    isDraft,
    showFormValidation,
    showVendorSelection,
    tax,
}) => {
    const styles = require('./index.scss');

    const isApprovalView = !isDraft;

    const showDiscountInHeader = useMemo(() => {
        return fields.getAll().some((field) => field.discount);
    }, [fields]);

    const [expanded, setExpanded] = useState(0);

    const handleExpand = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };

    const setFieldValues = (index, updates) => {
        const field = fields.get(index);
        const updatedField = {
            ...field,
        };

        updates.forEach((update) => {
            updatedField[update.key] = update.value;
        });

        fields.remove(index);
        fields.insert(index, updatedField);
    };

    return (
        <div className={styles.priceItemsContainer}>
            <Header showDiscount={showDiscountInHeader} tax={tax} />
            <div className={styles.priceItem}>
                {fields.map((fieldName, index) => {
                    return (
                        <div key={fieldName}>
                            <PriceItem
                                change={change}
                                disabled={disabled}
                                expanded={expanded === index}
                                fieldName={fieldName}
                                fields={fields}
                                handleExpand={handleExpand}
                                index={index}
                                isApprovalView={isApprovalView}
                                isDraft={isDraft}
                                setFieldValues={setFieldValues}
                                showFormValidation={showFormValidation}
                                showVendorSelection={showVendorSelection}
                                tax={tax}
                            />
                        </div>
                    );
                })}
            </div>
            {!isApprovalView && (
                <div className={styles.addAnotherLineItem}>
                    <Button
                        onClick={() => {
                            const orderById =
                                getMaxNumberFromList(fields.getAll(), ORDER_BY_ID) + 1;
                            const newPriceItem = getEmptyPriceItem(orderById);
                            fields.push(newPriceItem);
                            setExpanded(fields.length);
                        }}
                        qaTag={`${qaTagPageName}-addPriceItem`}
                    >
                        <i className="fa fa-plus" />
                        Add Another Line Item
                    </Button>
                </div>
            )}
        </div>
    );
};

PriceItemFields.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    isDraft: PropTypes.bool,
    showFormValidation: PropTypes.bool,
    showVendorSelection: PropTypes.bool,
    tax: PropTypes.number,
};

export const PurchaseDetails = ({ change, disabled, showFormValidation, showVendorSelection }) => {
    const styles = require('./index.scss');
    const requisition = useSelector(getRequisitionJS);
    const isDraft = isRequisitionDraft(requisition.status);
    const tax = useSelector(getGovernmentSalesTax);
    const isApprovalView = !isDraft;
    const errors = useSelector((state) => getFormSyncErrors(formConfig.form)(state));
    const currentAccountErrors = errors?.[PRICE_ITEMS_SUMMARY];

    return (
        <div className={styles.purchaseDetails}>
            <FieldArray
                change={change}
                component={PriceItemFields}
                disabled={disabled}
                isDraft={isDraft}
                name={`${PRICE_TABLE}.${PRICE_ITEMS}`}
                showFormValidation={showFormValidation}
                showVendorSelection={showVendorSelection}
                tax={tax}
            />
            {currentAccountErrors && (
                <div className={styles.error}>
                    <i className="fa fa-warning" /> {currentAccountErrors}
                </div>
            )}
            {isApprovalView ? (
                <div className={styles.summaryCard}>
                    <PurchaseDetailsSummary />
                </div>
            ) : (
                <PurchaseDetailsSummary />
            )}

            <VendorAssignmentModal change={change} />
        </div>
    );
};

PurchaseDetails.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
    showVendorSelection: PropTypes.bool,
};
