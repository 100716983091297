import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Tab } from './Tab';
import TabAccordionTrigger from './TabAccordionTrigger';
import CloseTabsButton from './CloseTabsButton';

export const TabsNavigation = ({ pathname, tabs, defaultTab, onClick }) => {
    const { tabGroup, tabGroupOpen } = require('./TabsNavigation.scss');

    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen((prevIsOpen) => {
            return !prevIsOpen;
        });
    };

    const tabsClasses = classnames(tabGroup, {
        [tabGroupOpen]: isOpen,
    });

    const currentTabIndex = pathname.lastIndexOf('/');
    const activeTabFromRoute = pathname.substring(currentTabIndex + 1);
    const activeTab = defaultTab || tabs.find((tab) => tab.route.includes(activeTabFromRoute));

    if (!activeTab) {
        // if we are here it means that the route is not in the tabs list
        // this can happen because we made a mistake while using the component
        // or more commonly because there's a feature flag somewhere that does not resolve in SSR
        // either way we will just return null and avoid a crash.
        // it's not a matter of unknown route, in that cause the router would have redirected to 404
        return null;
    }

    return (
        <>
            <TabAccordionTrigger
                isOpen={isOpen}
                label={activeTab.label}
                onClick={toggleAccordion}
            />
            <nav className={tabsClasses} role="navigation">
                {tabs.map((tab, index) => {
                    const { label, qaTag, route } = tab;

                    return (
                        <Tab
                            activeRoute={activeTab.route}
                            key={`${label}-${index}`}
                            label={label}
                            onClick={onClick}
                            qaTag={qaTag}
                            route={route}
                        />
                    );
                })}
                <CloseTabsButton onClick={() => setIsOpen(false)} />
            </nav>
        </>
    );
};

const tabShape = PropTypes.shape({
    label: PropTypes.string.isRequired,
    qaTag: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
});

TabsNavigation.propTypes = {
    onClick: PropTypes.func,
    pathname: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(tabShape).isRequired,
    defaultTab: tabShape,
};
