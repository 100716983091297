import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showProjectCreateModal } from '../../../actions/project/create/projectCreate';
import { trackEvent } from '../../../helpers';
import { Button } from '../../Button/Button';

const mapDispatchToProps = {
    showModal: showProjectCreateModal,
};

// @connect
class BasicProjectCreateButton extends Component {
    static propTypes = {
        bsStyle: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        hideIcon: PropTypes.bool,
        isIntake: PropTypes.bool,
        isRequisition: PropTypes.bool,
        showModal: PropTypes.func.isRequired,
        text: PropTypes.string,
        qaTag: PropTypes.string,
    };

    static defaultProps = {
        className: '',
    };

    clickHandler = () => {
        const { isIntake, isRequisition } = this.props;

        const createData = isIntake || isRequisition ? { isIntake, isRequisition } : undefined;

        this.props.showModal(createData);
        trackEvent('Show Project Create Modal');
    };

    render() {
        const { bsStyle, className, disabled, hideIcon, isIntake, text, qaTag } = this.props;

        const defaultButtonText = isIntake ? 'New Project Request' : 'New Project';

        return (
            <Button
                bsStyle={bsStyle}
                className={`btn ${className}`}
                disabled={disabled}
                onClick={this.clickHandler}
                qaTag={qaTag}
            >
                {!hideIcon && <i className="fa fa-plus" />} {text || defaultButtonText}
            </Button>
        );
    }
}

export const ProjectCreateButton = connect(null, mapDispatchToProps)(BasicProjectCreateButton);
