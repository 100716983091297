import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getIsRequisitionUsingFMS } from '../../../../../../../selectors/govApp';

export const Header = ({ showDiscount, tax }) => {
    const styles = require('./index.scss');
    const hasFMS = useSelector(getIsRequisitionUsingFMS);

    return (
        <div className={styles.priceItemHeader}>
            <div className={styles.line}>Line</div>
            {hasFMS && <div className={styles.budget}>Budget</div>}
            <div className={styles.description}>Description</div>
            <div className={styles.quantity}>Qty</div>
            <div className={styles.unitOfMeasure}>UoM</div>
            <div className={styles.unitPrice}>Unit Price</div>
            {showDiscount && <div className={styles.discount}>Discount</div>}
            {!!tax && <div className={styles.tax}>Tax</div>}
            <div className={styles.total}>Total</div>
        </div>
    );
};

Header.propTypes = {
    showDiscount: PropTypes.bool,
    tax: PropTypes.number,
};
